var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.type === `card`)?_c('sc-card',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoading),expression:"isLoading"}],attrs:{"title":_vm.$t('layer-controller:' + 'layers')}},[_c('div',{staticClass:"sc-layer-controller card"},[(_vm.allMapLayersList.length)?_c('ul',{staticClass:"sc-layer-controller__list"},[_vm._l((_vm.allMapLayersList),function(layer){return _c('li',{key:layer,class:[
          `sc-layer-controller__list-item`,
          { active: _vm.isLayerActive(layer) }
        ],on:{"click":function($event){return _vm.toggleLayer(layer)}}},[_c('r-text',[_vm._v(_vm._s(_vm.$t('layer-controller:' + layer)))])],1)}),_vm._l((_vm.mapPlugItems),function(item,i){return _c('li',{key:item + i,class:[`sc-layer-controller__list-item disabled`],on:{"click":function($event){return _vm.toggleLayer(_vm.layer)}}},[_c('r-text',[_vm._v(_vm._s(_vm.$t('layer-controller:' + _vm.layer)))])],1)}),(_vm.hasMatrixLayer)?_c('li',{class:[
          'sc-layer-controller__list-item',
          { active: _vm.isMatrixActive }
        ],on:{"click":function($event){return _vm.toggleLayer('matrix')}}},[_c('r-text',{attrs:{"size":16}},[_vm._v(" "+_vm._s(_vm.$t('layer-controller:' + _vm.layer))+" ")])],1):_vm._e()],2):_vm._e()])]):_c('div',{staticClass:"sc-layer-controller default"},[(_vm.mapLayersList.length)?_c('ul',{staticClass:"sc-layer-controller__list"},[_vm._l((_vm.mapLayersList),function(layer){return _c('el-tooltip',{key:layer,attrs:{"open-delay":500,"content":_vm.$t('layer-controller:' + layer),"placement":"bottom"}},[_c('li',{class:[
          `sc-layer-controller__list-item`,
          { active: _vm.isLayerActive(layer) }
        ],on:{"click":function($event){return _vm.toggleLayer(layer)}}},[_c('r-icon',{attrs:{"name":_vm.getIconName(layer),"size":24}})],1)])}),_vm._l((_vm.mapPlugItems),function(layer){return _c('el-tooltip',{key:layer,attrs:{"open-delay":500,"content":_vm.$t('layer-controller:' + layer),"placement":"bottom"}},[_c('li',{class:[`sc-layer-controller__list-item disabled`],on:{"click":function($event){return _vm.toggleLayer(layer)}}},[_c('r-icon',{attrs:{"name":_vm.getIconName(layer),"size":24}})],1)])}),(_vm.activeSC && _vm.hasMatrixLayer)?_c('el-tooltip',{attrs:{"open-delay":500,"content":_vm.$t('layer-controller:' + 'matrix'),"placement":"bottom"}},[_c('li',{class:[
          `sc-layer-controller__list-item`,
          { active: _vm.isMatrixActive }
        ],on:{"click":function($event){return _vm.toggleLayer('matrix')}}},[_c('r-icon',{attrs:{"name":"heatmap","size":18}})],1)]):_vm._e()],2):_vm._e(),_c('el-dropdown',{staticClass:"r-dropdown sc-layer-controller__dropdown",attrs:{"size":"mini","hide-on-click":false,"show-timeout":100,"placement":"bottom-start","trigger":"click"},on:{"visible-change":e => _vm.toggleHandler(e)}},[_c('div',{class:['r-dropdown__item', { active: _vm.isDropdownShow }]},[_c('r-icon',{staticStyle:{"cursor":"pointer"},attrs:{"name":"folder-layer","size":24,"arrow-down":""}})],1),_c('el-dropdown-menu',{staticClass:"r-dropdown-menu",attrs:{"slot":"dropdown"},slot:"dropdown"},[_vm._l((_vm.mapTileLayersList),function(layer){return _c('el-dropdown-item',{key:layer},[_c('div',{staticClass:"r-dropdown-menu__item-wrapper",on:{"click":function($event){return _vm.toggleLayer(layer)}}},[_c('el-checkbox',{staticClass:"r-checkbox",attrs:{"size":"mini","value":_vm.isLayerActive(layer)},on:{"change":function($event){return _vm.toggleLayer(layer)}}}),_c('r-text',{staticStyle:{"margin-left":"12px"}},[_vm._v(" "+_vm._s(_vm.$t('layer-controller:' + layer))+" ")])],1)])}),_c('el-dropdown-item',[_c('div',{staticClass:"r-dropdown-menu__item-wrapper",on:{"click":function($event){return _vm.toggleLayer('satellite')}}},[_c('el-checkbox',{staticClass:"r-checkbox",attrs:{"size":"mini","value":_vm.isLayerActive('satellite')},on:{"change":function($event){return _vm.toggleLayer('satellite')}}}),_c('r-text',{staticStyle:{"margin-left":"12px"}},[_vm._v(" Спутник ")])],1)])],2)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }