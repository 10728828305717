<template>
  <div class="railway-control-card-status">
    <r-text color-type="subhead">
      {{ $t('railway-control-card-status:' + 'current-status') }}
    </r-text>
    <el-select
      :value="info.status_id"
      class="r-select"
      :placeholder="$t('railway-control-card-status:' + 'select-status')"
      filterable
      clearable
      size="mini"
      :disabled="disableControl"
      @change="changeHandler"
      @clear="onClear(info.id)"
    >
      <el-option
        v-for="item in statusTypes"
        :key="item.id"
        :label="$t('railway-control-card-status:' + item.name)"
        :value="item.id"
        :disabled="item.disabled"
      />
    </el-select>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      required: true
    },
    disableControl: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    statusTypes() {
      const statusList = this.$store.state.railwayControl.statusList

      if (!statusList) return []

      return Object.values(statusList).map(e => {
        if (e.name === 'На сортировке') {
          e.disabled = true
        }
        return e
      })
    }
  },
  methods: {
    changeHandler(e) {
      this.$store.commit('RAILWAY_CONTROL_UPDATE_CAR_BY_ID', {
        id: this.info.id,
        field: 'status_id',
        value: e
      })
      this.$store.commit('RAILWAY_CONTROL_OBSERV_CHANGES_BY_ID', {
        id: this.info.id
      })
    },
    onClear(id) {
      this.$store.commit('RAILWAY_CONTROL_DELETE_CAR', id)
      this.$store.commit('RAILWAY_CONTROL_OBSERV_CHANGES_BY_ID', { id })
    }
  }
}
</script>

<style lang="scss">
.railway-control-card-status {
  position: relative;
  .r-select {
    margin-top: 8px;
    width: 100%;
  }
}
</style>
