<template>
  <r-simple-card
    :title="$t('rp-users-modules-item:' + module.name)"
    :icon-name="module.icon || 'list'"
    :subtitle="subtitle"
  >
    <div
      v-if="module.components && module.components.length > 1"
      class="rp-users-modules__toggle-all"
    >
      <el-checkbox
        class="r-checkbox"
        size="mini"
        :value="isActive()"
        @change="beforeToggle({ module: module.name })"
      >
        <r-text>Включить/отключить все</r-text>
      </el-checkbox>
    </div>
    <div
      v-for="component in module.components"
      :key="component.id"
      class="rp-users-modules__item"
    >
      <div
        v-if="!component.instruments || !component.instruments.length"
        class="rp-users-modules__item-wrapper"
      >
        <el-checkbox
          class="r-checkbox"
          size="mini"
          :value="isActive(component.id)"
          :disabled="
            toggleDisabled({ module: module.name, component: component.id })
          "
          @change="
            beforeToggle({ module: module.name, component: component.id })
          "
        >
          <r-text>{{ $t('rp-users-modules-item:' + `${module.name}:${component.value}`) }}</r-text>
        </el-checkbox>
      </div>
      <rp-users-modules-item-card
        v-else
        :module="module"
        :component="component"
        @before-toggle="beforeToggle"
      />
    </div>
  </r-simple-card>
</template>

<script>
import rpUsersModulesItemCard from './rp-users-modules-item-card'
import { isComponentActive, isModuleActive } from './helpers'

export default {
  components: {
    rpUsersModulesItemCard
  },
  props: {
    module: {
      type: Object,
      required: true
    },
    subtitle: {
      type: String,
      default: null
    }
  },
  computed: {
    userModules() {
      return this.$store.state.rolePermission.userPerms?.modules || []
    },
    roleModules() {
      return this.$store.state.rolePermission.rolePerms?.modules || []
    }
  },
  methods: {
    isActive(component) {
      if (component) {
        return isComponentActive(this.userModules, component)
      } else {
        return isModuleActive(this.module, this.userModules)
      }
    },
    beforeToggle({ module, component, instrument }) {
      this.$emit('before-toggle', {
        module,
        component,
        instrument
      })
      this.$forceUpdate()
    },
    toggleDisabled({ component }) {
      if (!component) return false
      return !!this.roleModules.find(e => e.id === component)
    }
  }
}
</script>

<style lang="scss">
.rp-users-modules {
  &__toggle-all {
    padding: 0 8px 8px;
    border-bottom: 1px solid var(--dividers_low_contrast);
  }

  &__item {
    margin-top: 4px;

    &-wrapper {
      padding: 4px 8px;
      overflow: hidden;

      .el-checkbox__label {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &--card {
        border-radius: var(--border-radius);
        background-color: var(--bg_containers) !important;
        padding: 8px;

        &__toggler {
          cursor: pointer;
          width: 40px;
          display: flex;
          justify-content: flex-end;
        }

        &__title {
          display: flex;
          justify-content: space-between;
        }

        &-wrapper {
          &__title {
            margin: 8px 0 4px;
          }
        }
      }
    }
  }
}
</style>
