<template>
  <div class="journal__header">
    <r-title>{{ title }}</r-title>
    <el-select
      v-model="active"
      class="r-select"
      @change="changeHandler"
    >
      <el-option
        v-for="type in filteredTypes"
        :key="type.id"
        :value="type.id"
        :label="type.name"
      />
    </el-select>
    <div class="journal__header-controls">
      <mail-notification
        v-if="hasMailNotify"
        :module="activeType"
        :object-ids="objectIds"
      />
      <export-button
        v-if="exportUrl"
        :name="title"
        :uri="exportUrl"
      />
      <map-toggler
        :map-visible="mapVisible"
        @toggle="$emit('toggle')"
      />
    </div>
  </div>
</template>

<script>
import MapToggler from './map-toggler'
import ExportButton from '@/components/export-button'
import MailNotification from '@/components/mail-notification/mail-notification'
import { types } from '../config/types'

export default {
  components: {
    MapToggler,
    ExportButton,
    MailNotification
  },
  props: {
    title: {
      type: String,
      required: true
    },
    activeType: {
      type: String,
      required: true
    },
    mapVisible: {
      type: Boolean,
      required: true
    },
    objectIds: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      active: this.activeType,
      types
    }
  },
  computed: {
    filteredTypes() {
      return this.types.filter((type) => {
        return this.$store.state.auth.module_permission_ids.includes(type.permission_object_id)
      })
    },
    exportUrl() {
      return this.$store.state.journal.exportUrl || null
    },
    hasMailNotify() {
      const availableTypes = ['car_event', 'videophoto']
      return availableTypes.includes(this.activeType)
    }
  },
  watch: {
    activeType(value) {
      this.active = value
    }
  },
  methods: {
    changeHandler(value) {
      this.$emit('change-type', value)
    }
  }
}
</script>

<style lang="scss">
.journal__header {
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  background-color: var(--bg_panel_primary);
  border-bottom: 1px solid var(--dividers_low_contrast);

  .r-select {
    margin-left: 16px;
    width: 300px;

    .el-input__inner {
      color: var(--field_primary_text) !important;
      border: none;
      background-color: var(--accent_selected) !important;
    }
  }

  &-controls {
    margin-left: auto;
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
  }
}
</style>
