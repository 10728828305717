export const instanceConfigs = {
  default: {
    city: 'svobodnyy',
    timeZone: '+9',
    map: {
      center: { lat: 51.46, lng: 128.11 },
      maxBounds: null,
      zoom: 10
    },
    layout: {
      config: {
        bottom_block: {
          position: 'center'
        }
      },
      layer_controller: true,
      left_block: [{ name: 'title-bar-widget', props: { type: 'default' } }],
      bottom_block: [
        {
          permission_object_id: 'fb9721af-edef-4ddb-bc8b-bfe3ee5e4493',
          name: 'category-widget',
          props: {
            layer_id: 'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=categories',
            request: {
              type: 'statistic',
              url: 'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=categories'
            },
            type: 'list',
            size: 'small'
          }
        },
        {
          permission_object_id: '58d25ac9-0966-4cf4-a6e1-017665967cdf',
          name: 'category-widget',
          props: {
            layer_id: 'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=status',
            request: {
              type: 'statistic',
              url: 'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=status'
            },
            type: 'list',
            size: 'small'
          }
        },
        {
          permission_object_id: '1a892e2b-a284-47c6-a503-cd7ef78e114b',
          name: 'category-widget',
          props: {
            layer_id: 'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=project',
            request: {
              type: 'statistic',
              url: 'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=project'
            },
            type: 'list',
            size: 'small'
          }
        },
        {
          permission_object_id: 'a8942b79-7c96-46d5-95df-b5faeb0e47f5',
          name: 'category-widget',
          props: {
            layer_id: 'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=cargo_types',
            request: {
              type: 'statistic',
              url: 'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=cargo_types'
            },
            type: 'list',
            size: 'small'
          }
        },
        {
          permission_object_id: '39877fd1-7974-4260-8feb-92ccf2dcd8d9',
          name: 'category-widget',
          props: {
            layer_id: 'ZdCarriage/d771ab92-6787-4aa6-ae65-e64d687f5234?format=widget&type=carriages',
            request: {
              type: 'statistic',
              url: 'ZdCarriage/d771ab92-6787-4aa6-ae65-e64d687f5234?format=widget&type=carriages'
            },
            type: 'list',
            size: 'small'
          }
        },
        {
          permission_object_id: '77bfa4aa-7cfc-4a7a-bd7a-cb96bb46e4f9',
          name: 'category-widget',
          props: {
            layer_id: 'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=pot_status',
            request: {
              type: 'statistic',
              url: 'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=pot_status'
            },
            type: 'list',
            size: 'mid',
            noIcons: true
          }
        },
        {
          permission_object_id: '03d824f9-2c33-4ab3-810d-9d595d576799',
          name: 'category-widget',
          props: {
            layer_id: 'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=app_status',
            request: {
              type: 'statistic',
              url: 'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=app_status'
            },
            type: 'list',
            size: 'small'
          }
        },
        {
          permission_object_id: 'dec107dc-1abf-4bbd-bab5-64a6cf55001d',
          name: 'category-widget',
          props: {
            layer_id: 'event_stat_class',
            request: {
              event_stat: true,
              type: 'statistic',
              url: 'event_stat',
              propType: 'class'
            },
            type: 'list',
            size: 'small'
          }
        },
        {
          permission_object_id: '74cb1eee-a89f-4338-8cc5-3d4011e21ca2',
          name: 'category-widget',
          props: {
            layer_id: 'event_stat_speed',
            request: {
              event_stat: true,
              type: 'statistic',
              url: 'event_stat',
              propType: 'speed'
            },
            type: 'list',
            size: 'small'
          }
        },
        {
          permission_object_id: 'f2aab1f7-1830-41f8-9420-cd17259aca3b',
          name: 'category-widget',
          props: {
            layer_id: 'event_stat_time',
            request: {
              event_stat: true,
              type: 'statistic',
              url: 'event_stat',
              propType: 'time'
            },
            type: 'list',
            size: 'small'
          }
        },
        {
          permission_object_id: '256f60cd-8055-4456-8402-5a3b4ee05b04',
          name: 'category-widget',
          props: {
            layer_id: 'bdd_violation/1b013139-c2d9-4641-a549-b162fce4eb57?format=widget&type=violations',
            request: {
              type: 'statistic',
              url: 'bdd_violation/1b013139-c2d9-4641-a549-b162fce4eb57?format=widget&type=violations'
            },
            type: 'list',
            size: 'mid',
            noIcons: true
          }
        }
      ]
    },
    mapLayers: [
      {
        permission_object_id: 'f537d8ca-987f-4bae-81ce-387efa7b987b',
        layer_id: 'video_camera_kpp',
        type: 'default',
        events: true,
        request: {
          type: 'statistic',
          url: 'video_camera_kpp'
        },
        optionalRequest: {
          url: 'api/py/iss/cameras',
          filter: '/'
        },
        rtsp: {
          uri: 'hls_kpp/cam_#ID/index.m3u8'
        }
      },
      {
        permission_object_id: '431e14ab-fea0-422b-a41e-6efa0eb68aca',
        layer_id: 'video_camera_road',
        type: 'default',
        events: true,
        request: {
          type: 'statistic',
          url: 'video_camera_road'
        },
        optionalRequest: {
          url: 'api/py/iss/cameras',
          filter: '/'
        },
        rtsp: {
          uri: 'ff_hls/cam_#ID/index.m3u8'
        },
        videoArchive: {
          url: 'api/py/iss/video_export'
        }
      }
    ],
    mapTileLayers: []
  }
}
