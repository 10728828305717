<template>
  <div class="files__upload">
    <el-upload
      ref="upload"
      multiple
      drag
      :on-success="onSuccess"
      :before-upload="beforeUpload"
      :action="`${url}/upload_resource`"
      :headers="headers"
    >
      <div class="el-upload__wrapper">
        <r-icon
          :size="30"
          name="upload"
          color="var(--accent_primary)"
        />
        <r-text
          color-type="accent-primary"
          class="el-upload__link"
        >
          {{ $t('file-uploader:' + 'files:to-attach') }}
        </r-text>
        <r-text
          type="caption"
          class="el-upload__caption"
        >
          {{ $t('file-uploader:' + 'files:upload-desc') }}
        </r-text>
      </div>
    </el-upload>
  </div>
</template>

<script>
import constants from '@/constants/url'
import { getGroupId, getHeaders } from '@/utils'

export default {
  data() {
    return {
      url: constants.URL
    }
  },
  computed: {
    headers() {
      return getHeaders()
    }
  },
  methods: {
    getGroupId,
    onSuccess(_, __, fileList) {
      this.$emit('fileList', fileList)
    },
    beforeUpload({ size }) {
      const allowedFileSize = size / 1024 / 1024 < 5

      if (!allowedFileSize) {
        this.$message.error('Файл не должен превышать 5 Мб!')
      }
      return allowedFileSize
    }
  }
}
</script>

<style lang="scss">
.files__upload {
  width: 100%;
  display: flex;

  & > div {
    flex: 1;
  }

  .el-upload {
    width: 100%;

    &-list__item {
      padding: 0.5rem;

      .el-progress {
        &.el-progress--line {
          width: 98%;
        }
      }

      .el-progress &-status-label {
        top: 50% !important;
        transform: translate(0, -50%) !important;
        right: 0.5rem;
      }

      &-name {
        color: var(--text_primary);
      }

      .el-upload-list__item-status-label {
        top: 50% !important;
        right: 0.5rem;
        transform: translate(0, -50%) !important;
      }

      .el-icon-close {
        top: 50% !important;
        right: 0.5rem;
        transform: translate(0, -50%) !important;
        color: var(--accent_primary) !important;

        &-tip {
          display: none !important;
        }
      }

      &:hover {
        background-color: var(--accent_hover);
      }
    }

    &-dragger {
      background-color: transparent !important;
      border: 1px dashed var(--dividers_low_contrast) !important;
      border-radius: 6px !important;
      height: auto !important;
      width: 100% !important;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      transition: border 0.15s ease;

      &:hover {
        border: 1px dashed var(--accent_primary) !important;
      }
    }

    &__caption {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
      grid-area: caption;
      padding-right: 0.25rem;
    }

    &__link {
      text-align: left;
      grid-area: default;
    }

    &__wrapper {
      display: grid;
      grid-template-areas: 'icon link' 'icon caption';
      grid-template-columns: 30px auto;
      grid-column-gap: 0.5rem;
      padding: 0.5rem;
      justify-content: flex-start;
      align-items: center;

      .r-icon {
        grid-area: icon;
        width: 30px;
      }
    }
  }
}
</style>
