<template>
  <div class="tasks-modal-list-item__info">
    <div class="tasks-modal-list-item__info-row">
      <div class="tasks-modal-list-item__info-title">
        <r-text type="caption">
          {{ $t('tasks-modal-list-item-content:' + 'task:id') }}
        </r-text>
      </div>
      <div class="tasks-modal-list-item__info-description">
        <r-text>{{ task.task_id }}</r-text>
      </div>
    </div>

    <div class="tasks-modal-list-item__info-row">
      <div class="tasks-modal-list-item__info-title">
        <r-text type="caption">
          {{ $t('tasks-modal-list-item-content:' + 'task:status') }}
        </r-text>
      </div>
      <div class="tasks-modal-list-item__info-description">
        <r-text>{{ $t('tasks-modal-list-item-content:' + `task:status:${task.success}`) }}</r-text>
      </div>
    </div>

    <div class="tasks-modal-list-item__info-row">
      <div class="tasks-modal-list-item__info-title">
        <r-text type="caption">
          {{ $t('tasks-modal-list-item-content:' + 'task:start_time') }}
        </r-text>
      </div>
      <div class="tasks-modal-list-item__info-description">
        <r-text>{{ getDate(task.start_time) }}</r-text>
      </div>
    </div>

    <div class="tasks-modal-list-item__info-row">
      <div class="tasks-modal-list-item__info-title">
        <r-text type="caption">
          {{ $t('tasks-modal-list-item-content:' + 'task:end_time') }}
        </r-text>
      </div>
      <div class="tasks-modal-list-item__info-description">
        <r-text>{{ getDate(task.end_time) }}</r-text>
      </div>
    </div>

    <div
      v-if="task.params.type"
      class="tasks-modal-list-item__info-row"
    >
      <div class="tasks-modal-list-item__info-title">
        <r-text type="caption">
          {{ $t('tasks-modal-list-item-content:' + 'task:file_type') }}
        </r-text>
      </div>
      <div class="tasks-modal-list-item__info-description">
        <r-text>{{ task.params.type }}</r-text>
      </div>
    </div>

    <div
      v-if="task.params.data_source_name"
      class="tasks-modal-list-item__info-row"
    >
      <div class="tasks-modal-list-item__info-title">
        <r-text type="caption">
          {{ $t('tasks-modal-list-item-content:' + 'task:ds_name') }}
        </r-text>
      </div>
      <div class="tasks-modal-list-item__info-description">
        <r-text>{{ task.params.data_source_name }}</r-text>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    task: {
      type: Object,
      default: () => null,
      required: true
    }
  },
  methods: {
    getDate(date) {
      return this.$ritmDate.toFormat(date, 'HH:mm • DD.MM.YYYY')
    }
  }
}
</script>

<style lang="scss">
.tasks-modal-list-item__info {
  &-row {
    display: flex;
    align-items: center;
  }
  &-description,
  &-title {
    padding: 0 4px;
    overflow: hidden;
  }
  &-title {
    width: 40%;
  }
  &-description {
    width: 60%;
  }
}
</style>
