<template>
  <div class="rp-users-header">
    <div class="rp-users-header-col">
      <r-text color-type="subhead">
        Пользователь
      </r-text>
      <el-input
        v-model="userFullName"
        class="r-input"
        type="text"
        placeholder="Диспетчер ЖД"
        clearable
        disabled
      />
    </div>
    <div class="rp-users-header-col">
      <r-text color-type="subhead">
        Название роли
      </r-text>
      <el-select
        :value="activeRoleId"
        class="r-select"
        placeholder="Выбрать"
        size="mini"
        filterable
        @change="changeRole"
      >
        <el-option-group
          v-for="roles in gropedRoles"
          :key="roles.id"
        >
          <el-option
            v-for="role in roles.roles"
            :key="role.id"
            :label="role.name"
            :value="role.id"
            filterable
          />
        </el-option-group>
      </el-select>
      <div
        :class="['rp-users-header__create-role', { disabled: isCRDisabled }]"
        @click="createRole"
      >
        <r-text color-type="accent-primary">
          Создать новую роль
        </r-text>
        <!--        <r-text>c текущими параметрами</r-text>-->
      </div>
    </div>
    <div class="rp-users-header-col">
      <r-text color-type="subhead">
        Проект
      </r-text>
      <el-select
        v-model="user_projects"
        class="r-select"
        filterable
        multiple
        placeholder="Выберите проект"
        @change="changeProject"
      >
        <el-option
          v-for="item in projects"
          :key="item.id"
          :value="item.id"
          :label="item.name"
        />
      </el-select>
    </div>
    <div class="rp-users-header-col">
      <r-button
        type="success"
        :disabled="isDisabled"
        @click="saveHandler"
      >
        Сохранить изменения
      </r-button>
    </div>
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import isEqual from 'lodash.isequal'
import { roleEquality } from './helpers/'
import { notifyFactory } from '@/utils'

export default {
  data() {
    return {
      activeRoleId: null,
      user_projects: [],
      projects: []
    }
  },
  computed: {
    activeUserId() {
      return this.$store.state.rolePermission.activeUserId || null
    },
    activeUser() {
      const users = this.$store.state.rolePermission.allUsers || []

      return users?.find(u => u.id === this.activeUserId)
    },
    userRoles() {
      return this.activeUser?.user_roles || []
    },
    userFullName() {
      return this.getName(this.activeUser) || this.user.activeUser
    },
    allRoles() {
      return this.$store.state.rolePermission.allRoles || []
    },
    gropedRoles() {
      const roles = [
        { id: 1, roles: [] },
        { id: 2, roles: [] }
      ]

      this.allRoles.forEach(r => {
        if (r.id === 'ib_admin' || r.id === 'admin') {
          roles[1].roles.push(r)
        } else {
          roles[0].roles.push(r)
        }
      })

      return roles
    },
    isDisabled() {
      const roleChanged =
        (this.activeUser.ib_admin && this.activeRoleId === 'ib_admin') ||
        (this.activeUser.admin && this.activeRoleId === 'admin') ||
        this.activeRoleId === (this.userRoles[0]?.role_id || null)
      const modulesChanged = !isEqual(this.userModules, this.initialUserModules)
      const datasourcesChanged = !isEqual(
        this.userDatasources,
        this.initialUserDatasources
      )

      const projectChanged = isEqual(this.user_projects, this.activeUser.projects)

      return projectChanged && roleChanged && !modulesChanged && !datasourcesChanged
    },
    rolePerms() {
      return this.$store.state.rolePermission.rolePerms || {}
    },
    userPerms() {
      return this.$store.state.rolePermission.userPerms || {}
    },
    userModules() {
      return this.userPerms?.modules || []
    },
    initialUserModules() {
      return this.$store.state.rolePermission.initialUserPerms?.modules || []
    },
    userDatasources() {
      return this.userPerms?.datasources || []
    },
    initialUserDatasources() {
      return (
        this.$store.state.rolePermission.initialUserPerms?.datasources || []
      )
    },
    isCRDisabled() {
      if (this.activeUser.ib_admin || this.activeUser.admin) return true

      return roleEquality(this.rolePerms, this.userPerms)
    }
  },
  watch: {
    activeUserId() {
      this.setActiveRoleId()
    }
  },
  created() {
    this.setActiveRoleId()
    this.user_projects = this.activeUser.projects || []
    this.loadData()
  },
  methods: {
    async loadData() {
      this.isLoading = true

      try {
        const config = { only: ['id', 'name'] }
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `objectInfo/public.projects?config=${JSON.stringify(config)}`
        })
        this.projects = Object.values(data)
      } catch (e) {
        throw new Error(e)
      } finally {
        this.isLoading = false
      }
    },
    changeProject(value) {
      console.log(value)
    },
    setActiveRoleId() {
      if (this.activeUser.ib_admin) {
        this.activeRoleId = 'ib_admin'
      } else if (this.activeUser.admin) {
        this.activeRoleId = 'admin'
      } else {
        this.activeRoleId = cloneDeep(this.userRoles[0]?.role_id) || null
      }
    },
    getName(user) {
      if (!user.last_name) return user.name

      let name = user.last_name

      if (user.first_name) {
        name = `${name} ${user.first_name[0]}.`
      }

      if (user.first_name && user.middle_name) {
        name = `${name} ${user.middle_name[0]}.`
      }

      return name
    },
    changeRole(role_id) {
      this.activeRoleId = role_id
    },
    async saveHandler() {
      if (this.activeRoleId === 'ib_admin') {
        await this.giveIbAdminPerms()
        return
      }
      if (this.activeRoleId === 'admin') {
        await this.giveAdminPerms()
        return
      }
      const data = this.userRoles.map(e => {
        if (e.role_id !== this.activeRoleId) {
          e.disabled = true
        }

        return e
      })
      const newRole = !this.userRoles.find(e => e.role_id === this.activeRoleId)

      if (newRole) {
        data.push({
          user_id: this.activeUserId,
          role_id: this.activeRoleId
        })
      }

      const userData = {
        ...this.activeUser,
        projects: this.user_projects,
        permissions: {
          modules: this.userModules.filter(e => e._action),
          datasources: this.userDatasources.filter(e => e._action)
        }
      }
      if (userData.admin) {
        userData.admin = false
      }
      if (userData.ib_admin) {
        userData.ib_admin = false
      }

      try {
        if (this.activeRoleId) {
          await this.$store.dispatch('POST_REQUEST', {
            url: 'objectInfo/auth.user_roles',
            data
          })
        }
        delete userData.user_roles
        await this.$store.dispatch('POST_REQUEST', {
          url: `user?user_id=${this.activeUserId}`,
          data: userData
        })
        const title = 'Редактирование пользователя'
        const message = 'Редактирование пользователя выполнено успешно'

        this.$notify(notifyFactory('success', title, message))
        this.$store.commit('ROLE_PERM_SET_FIELD', {
          field: 'updateUsers',
          value: true
        })
      } catch (e) {
        throw new Error(e)
      }
    },
    async giveAdminPerms() {
      const userData = {
        ...this.activeUser
      }
      userData.admin = true
      if (userData.ib_admin) {
        userData.ib_admin = false
      }
      const user_roles = this.userRoles.map(e => {
        e.disabled = true

        return e
      })

      try {
        delete userData.user_roles
        await this.$store.dispatch('POST_REQUEST', {
          url: `user?user_id=${this.activeUserId}`,
          data: userData
        })
        await this.$store.dispatch('POST_REQUEST', {
          url: 'objectInfo/auth.user_roles',
          data: user_roles
        })
        const title = 'Редактирование пользователя'
        const message = 'Редактирование пользователя выполнено успешно'

        this.$notify(notifyFactory('success', title, message))
        this.$store.commit('ROLE_PERM_SET_FIELD', {
          field: 'updateUsers',
          value: true
        })
      } catch (e) {
        throw new Error(e)
      }
    },
    async giveIbAdminPerms() {
      const userData = {
        ...this.activeUser
      }
      userData.ib_admin = true
      if (userData.admin) {
        userData.admin = false
      }
      const user_roles = this.userRoles.map(e => {
        e.disabled = true

        return e
      })

      try {
        delete userData.user_roles
        await this.$store.dispatch('POST_REQUEST', {
          url: `user?user_id=${this.activeUserId}`,
          data: userData
        })
        await this.$store.dispatch('POST_REQUEST', {
          url: 'objectInfo/auth.user_roles',
          data: user_roles
        })
        const title = 'Редактирование пользователя'
        const message = 'Редактирование пользователя выполнено успешно'

        this.$notify(notifyFactory('success', title, message))
        this.$store.commit('ROLE_PERM_SET_FIELD', {
          field: 'updateUsers',
          value: true
        })
      } catch (e) {
        throw new Error(e)
      }
    },
    createRole() {
      if (this.isCRDisabled) return
      this.$store.commit('OPEN_MODAL_WINDOW', 'rp-user-create-role')
    }
  }
}
</script>

<style lang="scss">
.rp-users-header {
  position: relative;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--dividers_low_contrast);
  display: flex;
  align-items: flex-start;

  &-col {
    margin-right: 16px;

    .r-text {
      margin-bottom: 8px;
    }

    .el-input__inner {
      width: 220px !important;
      min-height: 36px;
    }

    &:last-child {
      margin-top: 29px;
      margin-right: 0;
    }
  }

  &__create-role {
    display: flex;
    align-items: center;
    margin-top: 9px;
    cursor: pointer;

    > * {
      margin-right: 4px;
    }

    &.disabled {
      opacity: 0.4;
      cursor: initial;
    }
  }
}
</style>
