<template>
  <div class="railway-control-card-info">
    <el-collapse
      v-model="activeTabs"
      class="r-collapse style-collapse"
    >
      <el-collapse-item name="main">
        <template slot="title">
          <r-title type="subtitle-2">
            {{ $t('railway-control-card-info:' + `info`) }}
          </r-title>
        </template>
        <ul class="railway-control-card-info-list">
          <li
            v-for="item in listItems"
            :key="item.field"
            class="railway-control-card-info-list__item"
          >
            <r-text type="caption">
              {{ $t('railway-control-card-info:' + item.field) }}
            </r-text>
            <r-text>
              {{ $t('railway-control-card-info:' + `${item.value}`) }}
            </r-text>
          </li>
        </ul>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
export default {
  props: {
    info: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      activeTabs: ['main']
    }
  },
  computed: {
    listItems() {
      return [
        {
          field: 'number',
          value: this.info.number || 'нет данных'
        },
        {
          field: 'project',
          value: this.info.project || 'нет данных'
        },
        {
          field: 'subproject',
          value: this.info.subproject || 'нет данных'
        },
        {
          field: 'cargo_name',
          value: this.info.cargo_name || 'нет данных'
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.railway-control-card-info {
  position: relative;
  .el-collapse {
    &.r-collapse {
      height: 100%;
      overflow: auto;
      .el-collapse-item__header {
        padding: 8px;
      }
      .el-collapse-item__content {
        padding-bottom: 12px;
      }
    }
    &-item {
      border-radius: var(--border-radius);
      background-color: var(--bg_containers) !important;
      margin-bottom: 6px;
      &:last-child {
        margin-bottom: 0;
      }
      &__header {
        line-height: 1.5 !important;
      }
    }
  }
  &-list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    overflow-y: auto;
    &__item {
      width: 100%;
      margin-bottom: 12px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
