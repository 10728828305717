<template>
  <sc-card
    v-if="badRequest"
    :title="options.layer_id"
  >
    <r-text>{{ $t('status-widget:' + "request-error") }}</r-text>
  </sc-card>
  <sc-card
    v-else-if="activeTab !== 'chart'"
    v-loading="options.request.plug ? false : !initialRequest"
    :title="options.layer_id"
    :footer="true"
    controls
  >
    <component
      :is="options.type"
      :status-data="widgetData"
      :colors="colors"
      :icons="icons"
    />
    <widget-footer
      slot="footer"
      :widget-data="widgetData"
    />
    <card-toggler
      v-if="options.hasChart"
      slot="controls"
      :active="activeTab"
      :toggle-chart="toggleChart"
    />
  </sc-card>
  <sc-card
    v-else
    :title="options.layer_id"
    controls
  >
    <sc-chart
      :chart-data="chartData"
      :colors="chartColors"
      :title="$t('status-widget:' + options.layer_id)"
      :type="chartType"
      :change-chart-type="changeChartType"
    />
    <card-toggler
      v-if="options.hasChart"
      slot="controls"
      :active="activeTab"
      :toggle-chart="toggleChart"
    />
  </sc-card>
</template>

<script>
import scCard from '../../layout/card'
import type1 from './renderer/type-1'
import type2 from './renderer/type-2'
import type3 from './renderer/type-3'
import type4 from './renderer/type-4'
import widgetFooter from './renderer/footer'
import cardToggler from '../card-toggler/card-toggler'
import scChart from '../../modules/charts/charts'

import { dataMapping, getWidgetStyleConfig } from '../../../configs'

export default {
  components: {
    scCard,
    type1,
    type2,
    type3,
    type4,
    scChart,
    widgetFooter,
    cardToggler
  },
  props: {
    options: {
      type: Object,
      required: true,
      default: () => ({
        layer_id: '',
        type: 'type-1',
        hasChart: false
      })
    }
  },
  data() {
    return {
      chartType: 'type-1',
      activeTab: 'info'
    }
  },
  computed: {
    layerId() {
      return this.options.event_layer ? this.options.request.url : this.options.layer_id
    },
    widgetData() {
      if (this.options.request.plug) {
        return dataMapping({
          count: 0,
          error: 0,
          work: 0
        },
        'default_status',
        'basic_widget'
        )
      }
      const data = this.$store.state.situationCenter.layersData.widget[
        this.layerId
      ]

      return data
        ? dataMapping(data, this.options.layer_id, 'basic_widget')
        : {}
    },
    colors() {
      const id = !this.options.request.plug
        ? this.options.layer_id
        : 'default_status'
      const layerStyle = getWidgetStyleConfig(id)

      return layerStyle ? layerStyle.colors : {}
    },
    icons() {
      const id = !this.options.request.plug
        ? this.options.layer_id
        : 'default_status'
      const layerStyle = getWidgetStyleConfig(id)

      return layerStyle ? layerStyle.icons : {}
    },
    chartData() {
      return this.widgetData.items
        ? this.widgetData.items.map(e => ({
          value: e.value,
          name: this.$t('status-widget:' + e.type)
        }))
        : []
    },
    chartColors() {
      return Object.keys(this.colors).map(e => this.colors[e])
    },
    initialRequest() {
      return (
        this.$store.state.situationCenter.completedRequests.indexOf(
          this.layerId
        ) !== -1
      )
    },
    badRequest() {
      return (
        this.$store.state.situationCenter.badRequests.indexOf(
          this.layerId
        ) !== -1
      )
    }
  },
  methods: {
    changeChartType(e) {
      this.chartType = e
    },
    toggleChart(e) {
      this.activeTab = e
    }
  }
}
</script>

<style lang="scss">
.sc-status-widget {
  width: 264px;
  height: 100%;
  display: flex;
  overflow: hidden;
  position: relative;
}
</style>
