<template>
  <div class="ds-manager__component empty-ds">
    <div class="ds-manager__component-title">
      <r-title type="title-2">
        {{ $t('ds-manager-empty-ds:' + 'ds-manager:creation:empty-ds:title') }}
      </r-title>
    </div>
    <div class="ds-manager__component-content">
      <el-row class="ds-manager__component-row">
        <el-col
          class="r-col"
          :span="12"
        >
          <div class="ds-manager__component-subtitle">
            <r-text type="caption">
              {{ $t('ds-manager-empty-ds:' + 'ds-manager:creation:empty-ds:ds-name') }}
            </r-text>
          </div>
          <el-input
            v-model="dsName"
            class="r-input"
            size="mini"
            :placeholder="`${$t('ds-manager-empty-ds:' +
              'ds-manager:creation:empty-ds:ds-name-placeholder'
            )}`"
          />
          <el-row
            class="ds-manager__component-row"
            style="margin-top: 8px"
          >
            <div class="ds-manager__component-subtitle">
              <r-text type="caption">
                {{ $t('ds-manager-empty-ds:' + 'ds-manager:creation:enter-profiles') }}
              </r-text>
            </div>
            <el-select
              v-model="selectedProfiles"
              class="r-select"
              size="mini"
              multiple
              collapse-tags
              :placeholder="`${$t('ds-manager-empty-ds:' +
                'ds-manager:creation:select-profiles-placeholder'
              )}`"
            >
              <el-option
                v-for="profile in profiles"
                :key="profile.id"
                :value="profile.id"
                :label="profile.name"
              />
            </el-select>
          </el-row>
        </el-col>
        <el-col
          class="r-col"
          :span="12"
        >
          <div class="ds-manager__component-subtitle">
            <r-text type="caption">
              {{ $t('ds-manager-empty-ds:' + 'ds-manager:creation:empty-ds:geom-type') }}
            </r-text>
          </div>
          <el-select
            v-model="geomType"
            size="mini"
            class="r-select"
            :placeholder="`${$t('ds-manager-empty-ds:' +
              'ds-manager:creation:empty-ds:geom-type-placeholder'
            )}`"
            :no-match-text="`${$t('ds-manager-empty-ds:' + 'ds-manager:creation:no-match')}`"
            clearable
            filterable
          >
            <el-option
              v-for="subitem in geomTypes"
              :key="subitem"
              :label="subitem"
              :value="subitem"
            />
          </el-select>
        </el-col>
      </el-row>
      <el-row class="ds-manager__component-row">
        <ds-manager-table
          :attributes="attributes"
          :initial-attributes="initialAttributes"
          :changed-rows="changedRows"
          :fields="fields"
          :types="types"
          :datasources-list="datasourcesList"
          @nameValidation="nameValidation"
          @addAttribute="addAttribute"
          @deleteAttribute="deleteAttribute"
          @deleteAllAttribute="deleteAllAttribute"
        />
      </el-row>
      <el-row class="ds-manager__component-row text-right">
        <r-button
          type="success"
          :disabled="!isCreateReady"
          @click="createEmptyDs"
        >
          {{ $t('ds-manager-empty-ds:' + 'ds-manager:creation:create-ds') }}
        </r-button>
      </el-row>
    </div>
  </div>
</template>

<script>
import dsManagerTable from '../ds-manager-table/ds-manager-table'

import { notifyFactory, errorParser } from '@/utils'

const fields = [
  { label: 'name', prop: 'title', type: 'name' },
  { label: 'type', prop: 'type', type: 'belongs_to' },
  { label: 'default', prop: 'default', type: 'string' },
  { label: 'datasource', prop: 'source_id', type: 'datasource' },
  { label: 'time field', prop: 'time_field', type: 'bool' }
]

export default {
  components: { dsManagerTable },
  data() {
    return {
      dsName: '',
      types: [],
      geomTypes: [],
      geomType: 'point',

      attributes: [],
      initialAttributes: [],
      changedRows: [],
      newRows: [],
      selectedProfiles: [],
      allNamesCorrect: true,

      fields
    }
  },
  computed: {
    isCreateReady() {
      if (
        !this.attributes.length ||
        !this.dsName.trim() ||
        !this.geomType ||
        !this.geomType.trim() ||
        !this.allNamesCorrect
      ) {
        return false
      }

      const isTitleEmpty = this.attributes.find(
        e => !e.title || !e.title.trim().length
      )
      const isTypeEmpty = this.attributes.find(
        e => !e.type || !e.type.trim().length
      )

      return !(isTitleEmpty || isTypeEmpty)
    },
    hasChanges() {
      if (this.attributes.length || this.dsName.trim()) return true

      return false
    },
    datasourcesList() {
      return (
        Object.keys(this.$store.state.datasources).map(
          ds => this.$store.state.datasources[ds]
        ) || []
      )
    },
    profiles() {
      return this.$store.state.profiles.profilesTree
    },
    currentProfile() {
      return this.$store.state.profiles.currentProfile
    }
  },
  watch: {
    hasChanges(val) {
      this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', val)
    }
  },
  created() {
    this.loadTypes()
    this.selectedProfiles = this.profiles
      .filter(e => e.id === this.currentProfile.id)
      .map(e => e.id)
  },
  beforeDestroy() {
    this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', false)
  },
  methods: {
    async createEmptyDs() {
      try {
        const data = new FormData()
        data.append('data_source_name', this.dsName)
        data.append('type', 'custom')

        const attributes = [...this.attributes]
        attributes.push({ title: 'geom', type: this.geomType })
        data.append('fields', JSON.stringify(attributes))
        data.append('user_profile_id', this.selectedProfiles.join())

        const response = await this.$store.dispatch('SEND_FORM_DATA', {
          url: 'import_data',
          data
        })
        const title = this.$t('ds-manager-empty-ds:' + 'ds-manager:creation:create-success-title')
        const message = `${this.$t('ds-manager-empty-ds:' +
          'ds-manager:creation:create-success-text'
        )} ${response.data.task_id}`
        this.$notify(notifyFactory('success', title, message))
        this.$store.commit('ADD_ACTIVE_TASK', {
          task_id: response.data.task_id,
          type: 'import',
          description: `Импорт ИД - ${this.layerName}`
        })
        this.clearAll()
      } catch (e) {
        errorParser.call(this, e)
      }
    },
    clearAll() {
      this.deleteAllAttribute()
      this.dsName = ''
      this.geomType = ''
    },
    loadTypes() {
      this.$store
        .dispatch('GET_REQUEST', { url: 'datatypes' })
        .then(({ data }) => {
          this.types = data.base || []
          this.geomTypes = data.geom || []
        })
    },
    nameValidation(val) {
      this.allNamesCorrect = val
    },
    addAttribute() {
      const attribute = {
        title: null,
        type: 'string',
        default: null,
        source_id: null,
        time_field: null
      }
      this.attributes.push(attribute)
    },
    deleteAttribute(row) {
      const index = this.attributes.findIndex(item => item.title === row.title)
      const newIndex = this.newRows.findIndex(item => item === row.title)
      this.attributes.splice(index, 1)
      if (newIndex > -1) this.newRows.splice(newIndex, 1)
    },
    deleteAllAttribute() {
      this.attributes.splice(0, this.attributes.length)
    }
  }
}
</script>

<style lang="scss">
.empty-ds {
  .ds-manager__component-content {
    width: 800px;
    height: 468px;
    .r-select {
      width: 100%;
    }
  }
}
</style>
