<template>
  <div class="object-info__actions">
    <r-button
      v-for="action in filteredActions"
      :key="action.title"
      :type="action.type"
      :icon="action.icon"
      @click="callFunction(action.title)"
    >
      {{ $t('object-info-actions:' + action.title) }}
    </r-button>
  </div>
</template>

<script>
const actions = [
  { title: 'flyTo', icon: 'focus-zone', type: 'primary' },
  { title: 'delete', icon: 'trash', type: 'danger' }
]

export default {
  props: {
    id: {
      type: String,
      default: ''
    },
    sourcename: {
      type: String,
      default: ''
    },
    hasDeletePermission: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      actions
    }
  },
  computed: {
    defaultPath() {
      return this.$store.state.defaultPath.split('/').pop()
    },
    filteredActions() {
      return this.actions.filter(e => {
        if (e.title === 'delete') return this.hasDeletePermission
        else return true
      })
    }
  },
  methods: {
    callFunction(title) {
      switch (title) {
        case 'delete':
          this.beforeDeleteObject()
          break
        case 'flyTo':
          this.beforeFlyTo()
          break
      }
    },
    beforeDeleteObject() {
      const warningText = `${this.$t('object-info-actions:' + 'modal.warning_text::delete')}`
      const warningTitle = `${this.$t('object-info-actions:' + 'modal.warning_title')}`
      const confirmButtonText = `${this.$t('object-info-actions:' + 'modal.confirm_button')}`
      const cancelButtonText = `${this.$t('object-info-actions:' + 'modal.cancel_button')}`

      this.$confirm(warningText, warningTitle, {
        customClass: 'r-message-box',
        type: 'warning',
        closeOnClickModal: false,
        confirmButtonText,
        cancelButtonText
      })
        .then(() => {
          this.$emit('delete')
        })
        .catch(() => {})
    },
    beforeFlyTo() {
      this.flyTo()
    },
    setDefaultPath(value) {
      this.$store.commit('SET_DEFAULT_PATH', value)
    },
    async flyTo() {
      const config = JSON.stringify({
        only: ['id', 'geom'],
        where: { field: 'id', value: this.id, op: '=' }
      })
      const { data } = await this.$store.dispatch('GET_REQUEST', {
        url: `objectInfo/${this.sourcename}?config=${config}`
      })
      const { geom, f1 } = data[this.id]
      this.$store.commit('SET_FLY_TO_GEOM', geom || f1) // TODO: WTF F1 IS???
    },
    observe() {
      // observer mode (on map)
      if (!this.isObserveModeEnabled) {
        this.$store.commit('SET_FUNCTIONS_STATE_PROP', {
          fn: 'observe',
          value: true,
          id: this.id,
          layer: this.sourcename
        })
      } else {
        this.$store.commit('SET_FUNCTIONS_STATE_PROP', {
          fn: 'observe',
          value: false,
          id: '',
          layer: ''
        })
      }
    },
    enableGeomEditor() {
      // enable geom editor (on map)
      const layer = this.$store.state.map.activeLayers.find(
        item => item.id === this.sourcename
      )
      if (!layer.isEditorActive) {
        if (!layer.isActive) return
        // this.moveTo();
        const layers = this.$store.state.map.activeLayers
        layers.forEach(item => {
          if (item.isEditorActive) item.isEditorActive = false
        })
        layer.isEditorActive = true
        this.$store.commit('SET_EDITOR_ONLYONE_ID', this.id)
        this.$store.commit('SET_EDITING_LAYER', layer.id)
        this.$store.commit(
          'SET_GEOM_TYPE',
          layer.datatype ? layer.datatype : layer.geom_type
        )
        this.$store.commit('TOGGLE_EDITOR', true)
        this.$store.commit('SET_EDITOR_MODEL_MODE', !!layer.datatype)
        this.$store.commit('SET_EDITOR_MODE', 'editing')
      } else {
        layer.isEditorActive = false
        this.$store.commit('TOGGLE_EDITOR', false)
        this.$store.commit('SET_EDITOR_ONLYONE_ID', '')
      }
    },
    openDiagram() {
      const options = {
        // x: 500,
        // y: 500,
        // height: 550,
        title: 'Chart',
        sourcename: this.sourcename,
        id: this.id
      }
      this.$store.commit('ADD_NEW_OVERHEAD_WINDOW', options)
    }
  }
}
</script>

<style lang="scss"></style>
