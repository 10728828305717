<template>
  <div
    :class="['pot-1-terminal-list-item', { active }]"
  >
    <div
      class="pot-1-terminal-list-item__wrapper"
      @click="clickHandler"
    >
      <r-title
        :size="24"
      >
        {{ info.reg_number }}
      </r-title>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      width="1000px"
    >
      <pot-1-terminal-modal
        :info="info"
        @cancel="closeModal()"
      />
    </el-dialog>
  </div>
</template>

<script>
import pot1TerminalModal from './pot-1-terminal-modal'

export default {
  components: {
    pot1TerminalModal
  },
  props: {
    info: {
      type: Object,
      required: true
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialogVisible: false
    }
  },
  methods: {
    clickHandler() {
      if (!this.active) return
      this.$store.commit('SET_POT_FIELD', {
        field: 'terminalActive',
        value: this.info
      })

      this.dialogVisible = true
    },
    closeModal() {
      this.dialogVisible = false
    }
  }
}
</script>

<style lang="scss">
.pot-1-terminal-list-item {
  position: relative;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.08), 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  height: 160px;
  &__wrapper {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  &.active {
    background-color: var(--accent_hover);
  }
}
</style>
