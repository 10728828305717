<template>
  <div class="files__file-item">
    <a
      class="files__file-body"
      target="_blank"
      @click="downloadFile(file)"
    >
      <r-icon
        class="files__file-icon"
        name="acts-document"
        :size="32"
      />
      <r-text class="files__file-name">{{ file.filename }}</r-text>
    </a>
  </div>
</template>

<script>
import constants from '@/constants/url'
import { getGroupId } from '@/utils'

export default {
  props: {
    file: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      url: constants.URL
    }
  },
  methods: {
    async downloadFile(file) {
      window.open(`${this.url}/download_resource/${file.id}?groupid=${getGroupId()}`)
    }
  }
}
</script>

<style lang="scss">
.files__file {
  &-body {
    display: grid;
    width: 100%;
    grid-auto-flow: column;
    grid-template-areas: 'icon name';
    height: 56px;
    border-radius: var(--border-radius);
    grid-template-columns: 50px 1fr;
    justify-content: start;
    align-items: center;
    background-color: var(--bg_containers);
    transition: all ease 0.15s;
    cursor: pointer;
    &:hover {
      background-color: var(--button_secondary_bg_hover);
    }
  }

  &-icon {
    grid-area: icon;
    width: 50px;
  }

  &-name {
    text-align: left;
    grid-area: name;
  }
}
</style>
