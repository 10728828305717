<template>
  <div class="checkbox-filter">
    <r-search-input
      v-if="checkboxes.length >= 14"
      :filter-text="filterText"
      @change="searchChange"
    />
    <div class="popper-filter__content checkbox-filter__content">
      <el-checkbox
        v-for="(filter, i) in filtered"
        :key="i + filter.name"
        v-model="filtered[i].value"
        class="r-checkbox"
      >
        {{ filter.name }}
      </el-checkbox>
    </div>

    <div class="checkbox-filter__controls">
      <r-button
        type="primary"
        @click="prepareData"
      >
        {{ $t('checkbox-filter:' + `checkbox-filter:accept`) }}
      </r-button>
      <r-button
        simple
        :disabled="isResetDisabled"
        @click="resetFilter"
      >
        {{ $t('checkbox-filter:' + `checkbox-filter:reset`) }}
      </r-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filterValue: {
      type: Array,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      filterText: '',
      checkboxes: this.filterValue
    }
  },
  computed: {
    isResetDisabled() {
      return !this.checkboxes.filter(c => c.value).length
    },
    filtered() {
      const searchFields = ['name']
      const query = this.filterText?.trim()?.toLowerCase()
      if (!query) return this.checkboxes || []

      return this.checkboxes.filter(e => {
        const filtered = searchFields
          .map(f => e[f])
          .join('')
          ?.trim()
          .toLowerCase()
        return filtered.includes(query)
      })
    }
  },
  watch: {
    filterValue(f) {
      this.checkboxes = f
    }
  },
  methods: {
    searchChange(e) {
      this.filterText = e
    },
    resetFilter(resetAll) {
      this.searchChange('')
      this.checkboxes = this.checkboxes.map(c => ({
        ...c,
        value: false
      }))
      this.prepareData(resetAll)
    },
    prepareData(resetAll) {
      const { checkboxes, id } = this

      const data = {
        prop: checkboxes,
        id: id,
        active: !!checkboxes.filter(c => c.value).length
      }

      if (!resetAll) {
        this.$emit('load', data)
      } else {
        this.$emit('reset-filter', data)
      }
    }
  }
}
</script>

<style lang="scss">
.checkbox-filter {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 0.5rem;
  padding: 0.25rem;
  align-items: center;
  justify-content: stretch;

  &__content {
    display: grid;
  }

  &__controls {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
  }

  .r-checkbox {
    margin-bottom: 0.5rem;
  }
}
</style>
