<template>
  <el-row class="ds-manager__component from-file">
    <div class="ds-manager__component-title">
      <r-title type="title-2">
        {{ $t('ds-manager-rzd-aghk:' + 'title') }}
      </r-title>
    </div>
    <el-row class="ds-manager__component-content">
      <el-row class="ds-manager__component-row upload-block">
        <el-upload
          ref="dsManagerUpload"
          :class="[`r-upload single`, { hasFile }]"
          action=""
          drag
          :limit="1"
          :auto-upload="false"
          :multiple="false"
          :on-change="handleChange"
          :show-file-list="false"
          :http-request="manualUpload"
        >
          <div class="el-upload__text">
            <div class="r-upload__tip">
              {{ $t('ds-manager-rzd-aghk:' + 'ds-manager:creation:from-file:upload-tip1') }}
              <em>{{ $t('ds-manager-rzd-aghk:' + 'ds-manager:creation:from-file:upload-tip2') }}</em>
            </div>
          </div>
          <div
            v-if="hasFile"
            slot="tip"
            class="el-upload__tip"
          >
            <r-icon
              name="folder-layer"
              :size="16"
              class="r-upload__file-icon"
            />
            <div class="r-upload__file-name">
              {{ uploadFileName }}
            </div>
            <div class="r-upload__file-info">
              {{ fileInfo }}
            </div>
            <div
              class="r-upload__clear-button"
              @click="clearFileList"
            >
              <r-icon
                name="close-delete"
                :size="12"
              />
            </div>
          </div>
        </el-upload>
      </el-row>
      <el-row class="ds-manager__component-row text-center">
        <r-button
          :loading="fileLoading"
          type="success"
          :disabled="!isActive"
          @click="submitUpload"
        >
          {{ $t('ds-manager-rzd-aghk:' + 'upload') }}
        </r-button>
      </el-row>
    </el-row>
  </el-row>
</template>

<script>
import filesize from 'filesize'
import { notifyFactory, errorParser } from '@/utils'

export default {
  data() {
    return {
      fileLoading: false,
      fileList: [],
      uploadFileName: '',
      uploadFileSize: null,
      hasFile: false
    }
  },
  computed: {
    isActive() {
      return this.hasFile
    },
    fileInfo() {
      return `${this.uploadFileSize}`
    },
    hasChanges() {
      if (this.hasFile) return true

      return false
    }
  },
  watch: {
    hasChanges(val) {
      this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', val)
    }
  },
  beforeDestroy() {
    this.$refs.dsManagerUpload.clearFiles()
    this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', false)
  },
  methods: {
    handleChange(file) {
      if (file && !this.fileList.length) {
        const fileName = file.name.split('.')

        this.fileList.push(file)
        this.hasFile = true
        this.uploadFileName = fileName.join()
        this.uploadFileSize = filesize(file.size)
      }
    },
    clearFileList() {
      this.$refs.dsManagerUpload.clearFiles()
      this.hasFile = false
      this.fileList.length = 0
      this.uploadFileName = ''
      this.uploadFileSize = null
    },
    submitUpload() {
      this.$refs.dsManagerUpload.submit()
    },
    async manualUpload(e) {
      this.fileLoading = true
      const formData = new FormData()

      formData.append('file', e.file)
      formData.append('type', 'rzd_data')
      formData.append('aghk', true)

      try {
        const { data } = await this.$store.dispatch('SEND_FORM_DATA', {
          url: 'import_data',
          data: formData
        })
        this.uploaded(data)
        this.fileLoading = false
      } catch (e) {
        errorParser.call(this, e)
        this.fileLoading = false
      }
    },
    uploaded(response) {
      const message = `${this.$t('ds-manager-rzd-aghk:' +
        'ds-manager:creation:from-file:uploaded-text'
      )} ${response.task_id}`
      const title = this.$t('ds-manager-rzd-aghk:' + 'ds-manager:creation:from-file:uploaded-title')

      this.$notify(notifyFactory('success', title, message))
      this.$store.commit('ADD_ACTIVE_TASK', {
        task_id: response.task_id,
        type: 'import',
        description: 'Выгрузка РЖД АГХК'
      })
      this.clearFileList()
    }
  }
}
</script>

<style lang="scss" scoped>
.from-file {
  .ds-manager__component-content {
    width: 428px;
    height: 180px;
    transition: all 0.15s ease;
  }

  .r-select {
    width: 100%;
  }

  .ds-manager__component-row {
    &.upload-block {
      height: 100px;
      overflow: hidden;
      overflow-y: auto;
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .el-upload__text {
        display: flex;
        align-items: center;
        height: 72px;
      }
    }
  }
}
</style>
