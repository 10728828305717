<template>
  <ul class="sc-basic-modal-window__list">
    <li
      v-for="(item, i) in itemList"
      :key="item + i"
      class="sc-basic-modal-window__list-item"
    >
      <div class="sc-basic-modal-window__list-item__col">
        <r-cblock
          :size="44"
          :color="colors[item.type]"
          :border-color="colors[item.type]"
        >
          <r-icon
            :name="icons[item.type] || icons.default"
            :size="28"
            :color="colors[item.type]"
          />
        </r-cblock>
      </div>
      <div class="sc-basic-modal-window__list-item__col">
        <r-text
          :size="20"
          style="font-weight:600"
        >
          {{ item.value }}
        </r-text>
        <r-text type="caption">
          {{ $t('default-list:' + item.type) }}
        </r-text>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    itemList: {
      type: Array,
      required: true,
      default: () => []
    },
    colors: {
      type: Object,
      default: () => null
    },
    icons: {
      type: Object,
      default: () => null
    }
  }
}
</script>

<style lang="scss">
.sc-basic-modal-window__list {
  display: grid;
  grid-gap: 24px 8px;
  grid-template-columns: repeat(4, 150px);
  grid-template-rows: repeat(4, 60px);
  justify-content: space-between;
  width: 100%;
  height: 100%;
  &-item {
    min-width: 150px;
    min-height: 60px;
    display: flex;
    align-items: center;
    overflow: hidden;
    &__col {
      margin-right: 12px;
      overflow: hidden;
      width: 35%;
      .r-text,
      .r-text.caption-2 {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &:last-child {
        width: 65%;
        margin-right: 0;
      }
    }
  }
}
</style>
