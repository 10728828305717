<template>
  <r-block :gap="1">
    <r-block
      col
      no-padding
    >
      <r-block
        no-padding
      >
        <r-text type="caption">
          {{ $t('range-filter:' + 'from') }}
        </r-text>
        <el-input-number
          v-model="interval.from"
          :step="step"
          :min="min"
          :max="max"
          :controls="!noControls"
          controls-position="right"
          class="r-input"
          @change="handleChange($event, 'from')"
        />
      </r-block>
      <r-block
        no-padding
      >
        <r-text type="caption">
          {{ $t('range-filter:' + 'to') }}
        </r-text>
        <el-input-number
          v-model="interval.to"
          :step="step"
          :min="min"
          :max="max"
          :controls="!noControls"
          controls-position="right"
          class="r-input"
          @change="handleChange($event, 'to')"
        />
      </r-block>
    </r-block>
    <r-block
      col
      no-padding
      space-between
    >
      <r-button
        type="primary"
        @click="prepareData"
      >
        {{ $t('range-filter:' + 'accept') }}
      </r-button>
      <r-button
        simple
        :disabled="!hasChanges"
        @click="resetFilter"
      >
        {{ $t('range-filter:' + 'reset') }}
      </r-button>
    </r-block>
  </r-block>
</template>

<script>
export default {
  props: {
    filterValue: {
      type: Object,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      interval: this.filterValue.interval,
      step: this.filterValue.step || 1,
      min: this.filterValue.min || -Infinity,
      max: this.filterValue.max === 0 ? 0 : this.filterValue.max || Infinity,
      noControls: this.filterValue.noControls || false,
      hasChanges: false
    }
  },
  methods: {
    handleChange(val, type) {
      const { from, to } = this.interval

      if (type === 'from') {
        if (val > to) {
          this.interval.to = val
        }
      } else {
        if (val < from) {
          this.interval.from = val
        }
      }
      this.hasChanges = true
    },
    resetFilter(resetAll) {
      this.interval.to = null
      this.interval.from = null
      this.hasChanges = false
      this.prepareData(resetAll)
    },
    prepareData(resetAll) {
      const { interval, id } = this
      const { from, to } = interval
      const falseFrom = !from && from !== 0
      const falseTo = !to && to !== 0
      const data = {
        type: 'range-filter',
        prop: { interval },
        id: id,
        active: resetAll ? false : !!(!falseFrom || !falseTo)
      }
      if (!data.active && this.hasChanges) {
        this.hasChanges = false
      }

      if (!resetAll) {
        this.$emit('load', data)
      } else {
        this.$emit('reset-filter', data)
      }
    }
  }
}
</script>
