<template>
  <ul class="sc-basic-widget__list-default">
    <li
      v-for="(item, i) in itemList"
      :key="item + i"
      class="sc-basic-widget__list-default-item"
    >
      <div class="sc-basic-widget__list-default-item__col">
        <r-cblock
          :size="40"
          :color="colors[item.type] || colors.default"
          :border-color="colors[item.type] || colors.default"
        >
          <r-icon
            :name="icons[item.type] || icons.default"
            :size="24"
            :color="colors[item.type] || colors.default"
          />
        </r-cblock>
      </div>
      <div class="sc-basic-widget__list-default-item__col">
        <r-title>{{ item.value }}</r-title>
        <r-text type="caption">
          {{ $t('default-list:' + item.type) }}
        </r-text>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    itemList: {
      type: Array,
      required: true,
      default: () => []
    },
    colors: {
      type: Object,
      default: () => null
    },
    icons: {
      type: Object,
      default: () => null
    }
  }
}
</script>

<style lang="scss">
.sc-basic-widget__list-default {
  display: grid;
  grid-gap: 16px;
  grid-template-columns: repeat(auto-fit, minmax(120px, auto));
  grid-template-rows: repeat(2, 60px);
  grid-auto-flow: column;
  width: 100%;
  overflow: auto;
  height: 100%;
  &-item {
    min-width: 116px;
    display: flex;
    align-items: center;
    &__col {
      margin-right: 4px;
      overflow: hidden;
      width: 50px;
      .r-text,
      .r-text.caption-2 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      &:last-child {
        width: 60%;
        margin-right: 0;
      }
    }
  }
}
</style>
