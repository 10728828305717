<template>
  <div class="pot-1-terminal-list">
    <r-title
      v-if="title"
      :size="40"
      color-type="secondary"
    >
      {{ title }}
    </r-title>
    <div
      v-if="data && data.length"
      class="pot-1-terminal-list-content"
    >
      <pot-1-terminal-list-item
        v-for="item in data"
        :key="item.id"
        :info="item"
        :active="active"
      />
    </div>
    <div
      v-else
      class="pot-1-terminal-list-item-no-data"
    >
      <r-text
        :size="24"
        color-type="secondary"
      >
        {{ getNoDataText() }}
      </r-text>
    </div>
  </div>
</template>

<script>
import pot1TerminalListItem from './pot-1-terminal-list-item'

export default {
  components: { pot1TerminalListItem },
  props: {
    title: {
      type: String,
      default: null
    },
    data: {
      type: Array,
      required: true
    },
    noDataText: {
      type: String,
      default: null
    },
    active: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    getNoDataText() {
      return this.noDataText || this.$t('pot-1-terminal-list:' + 'no-data')
    }
  }
}
</script>

<style lang="scss" scoped>
.pot-1-terminal-list {
  position: relative;
  .r-title {
    margin-bottom: 32px;
  }
  &-content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    grid-gap: 32px;
    justify-content: space-between;
    > * {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
