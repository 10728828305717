<template>
  <div class="profiles-settings">
    <div class="profiles-settings__rename">
      <r-text color-type="secondary">
        {{ $t('profiles-settings:' + 'ps:profile-name') }}
      </r-text>
      <div class="profiles-settings__rename-inputs">
        <el-input
          v-model="profileName"
          class="r-input"
          :placeholder="`${$t('profiles-settings:' + 'ps:profile-name:placeholder')}`"
        />
        <r-button
          v-if="profileName !== initialProfileName"
          icon="check"
          type="primary"
          :disabled="!profileName || !profileName.trim()"
          @click="renameProfile"
        />
        <r-button
          v-if="profileName !== initialProfileName"
          icon="close-delete"
          type="primary"
          @click="cancelRename"
        />
      </div>
    </div>
    <profile-constructor />
  </div>
</template>

<script>
import profileConstructor from './profiles-constructor'
import { notifyFactory } from '@/utils'

export default {
  components: {
    profileConstructor
  },
  data() {
    return {
      renameInput: null,

      profile: '',
      profileName: '',
      initialProfileName: ''
    }
  },
  computed: {
    profileId() {
      return this.$store.state.profiles.profileConstructor.profileId
    },
    profilesList() {
      return this.$store.state.profiles.profilesTree
    }
  },
  mounted() {
    this.profile = this.profilesList.find(e => e.id === this.profileId)
    this.profileName = this.profile.name
    this.initialProfileName = this.profile.name
  },
  methods: {
    cancelRename() {
      this.profileName = this.initialProfileName
    },
    async renameProfile() {
      if (!this.profileId) return
      const options = {
        url: 'user/profile?',
        data: { id: this.profileId, name: this.profileName }
      }

      try {
        await this.$store.dispatch('POST_REQUEST', options)
        const user = await this.$store.dispatch('GET_REQUEST', { url: 'user' })
        this.$store.commit('SET_PROFILES_TREE', user.data.user_profiles)
        this.initialProfileName = this.profileName
        const title = this.$t('profiles-settings:' + 'profiles-settings:notify:success-title')
        const message = this.$t('profiles-settings:' + 'profiles-settings:notify:success')
        this.$notify(notifyFactory('info', title, message))
      } catch (e) {
        console.warn(e)
      }
    }
  }
}
</script>

<style lang="scss">
.profiles-settings {
  display: grid;
  grid-gap: 1rem;

  &__rename {
    display: grid;
    grid-gap: 0.5rem;

    &-inputs {
      display: grid;
      grid-gap: 0.5rem;
      align-items: center;
      grid-auto-flow: column;
      grid-template-columns: 1fr;
    }
  }
}
</style>
