<template>
  <div :class="['ds-manager-editor', { small: isSmall }]">
    <r-title type="title-2">
      {{ $t('ds-manager-editor:' + 'ds-manager:creation:empty-ds:title') }}
    </r-title>
    <div class="ds-manager-editor__header">
      <r-text color-type="secondary">
        {{ $t('ds-manager-editor:' + 'ds-manager:creation:empty-ds:ds-name') }}
      </r-text>
      <el-input
        v-model="dsName"
        class="r-input"
        size="mini"
        :placeholder="`${$t('ds-manager-editor:' + 'ds-manager:creation:empty-ds:ds-name')}`"
      />

      <r-text color-type="secondary">
        ID: {{ activeId }}
      </r-text>

      <div
        v-if="attributes.length"
        class="ds-manager-editor__content"
      >
        <ds-manager-table
          :attributes="attributes"
          :initial-attributes="initialAttributes"
          :changed-rows="changedRows"
          :fields="fields"
          :types="types"
          :datasources-list="datasourcesList"
          @nameValidation="nameValidation"
          @addAttribute="addAttribute"
          @deleteAttribute="deleteAttribute"
          @deleteAllAttribute="deleteAllAttribute"
          @addChangedRow="addChangedRow"
          @deleteChangedRow="deleteChangedRow"
        />
      </div>

      <div class="ds-manager-editor__controls">
        <r-delete-button
          mini
          @delete="beforeDeleteDatasourceAndUpdate"
        >
          {{ $t('ds-manager-editor:' + 'ds-manager:creation:delete-ds') }}
        </r-delete-button>
        <r-button
          type="success"
          :disabled="!isEditReady || !isEdited"
          @click="beforeSaveEditing"
        >
          {{ $t('ds-manager-editor:' + 'ds-manager:creation:edit-ds') }}
        </r-button>
        <r-button
          simple
          :disabled="!isEdited"
          @click="beforeCancelChanges"
        >
          {{ $t('ds-manager-editor:' + 'ds-manager:creation:cancel') }}
        </r-button>
      </div>
    </div>
  </div>
</template>

<script>
import dsManagerTable from './ds-manager-table/ds-manager-table'

import { notifyFactory, errorParser } from '@/utils'
import cloneDeep from 'lodash.clonedeep'

const fields = [
  { id: 1, label: 'Наименование', prop: 'title', type: 'name' },
  { id: 2, label: 'Название атрибута', prop: 'name', type: 'read_only' },
  { id: 3, label: 'Тип', prop: 'type', type: 'belongs_to' },
  { id: 4, label: 'Значение по умолчанию', prop: 'default', type: 'string' }
  // { id: 5, label: 'источник данных', prop: 'source_id', type: 'datasource' },
  // { id: 6, label: 'временное поле', prop: 'time_field', type: 'bool' }
]

export default {
  components: { dsManagerTable },
  props: {
    menuClickHandler: {
      type: Function,
      default: () => null
    }
  },
  data() {
    return {
      isSmall: true,

      initialName: '',
      dsName: '',
      isChangingTitle: false,

      types: [],
      geomTypes: [],
      geomType: '',

      attributes: [],
      initialAttributes: [],
      changedRows: [],
      newRows: [],
      deletedRows: [],
      allNamesCorrect: true,

      fields
    }
  },
  computed: {
    activeId() {
      return this.$store.state.dsManager.activeDs.id
    },
    isEdited() {
      return (
        !!this.changedRows.length ||
        !!this.newRows.length ||
        !!this.deletedRows.length ||
        !!(this.dsName !== this.initialName)
      )
    },
    isEditReady() {
      if (!this.attributes.length || !this.allNamesCorrect) return false

      const isTitleEmpty = this.attributes.find(
        e => !e.title || !e.title.trim().length
      )
      const isTypeEmpty = this.attributes.find(
        e => !e.type || !e.type.trim().length
      )

      return !(isTitleEmpty || isTypeEmpty)
    },
    datasourcesList() {
      return (
        Object.keys(this.$store.state.datasources).map(
          ds => this.$store.state.datasources[ds]
        ) || []
      )
    }
  },
  watch: {
    isEdited(val) {
      this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', val)
    }
  },
  created() {
    this.loadTypes()
    this.openDataSource(this.$store.state.dsManager.activeDs)
  },
  beforeDestroy() {
    this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', false)
  },
  methods: {
    async saveDsTitle() {
      const url = 'objectInfo/auth.permission_objects'
      const data = [{ id: this.activeId, name: this.dsName }]
      this.isChangingTitle = true

      try {
        await this.$store.dispatch('PUT_REQUEST', { url, data })
        this.initialName = this.dsName
        this.isChangingTitle = false
        this.$store.commit('SET_UPDATE_DS_TREE', true)
      } catch (e) {
        errorParser.call(this, e)
        this.isChangingTitle = false
      }
    },
    clearAll() {
      this.deleteAllAttribute()
      this.dsName = ''
      this.geomType = ''
    },
    loadTypes() {
      this.$store
        .dispatch('GET_REQUEST', { url: 'datatypes' })
        .then(({ data }) => {
          this.types = data.base || []
          this.geomTypes = data.geom || []
        })
    },
    nameValidation(val) {
      this.allNamesCorrect = val
    },
    addChangedRow(item) {
      this.changedRows.push(item)
    },
    deleteChangedRow(index) {
      this.changedRows.splice(index, 1)
    },
    addAttribute() {
      const attribute = {
        title: null,
        type: 'string',
        default: null,
        source_id: null,
        time_field: null,
        newAttr: true
      }
      this.attributes.unshift(attribute)
      this.newRows.push(attribute)
    },
    deleteAttribute(row) {
      const index = this.attributes.findIndex(item => item.title === row.title)
      const newIndex = this.newRows.findIndex(item => item.title === row.title)
      this.attributes.splice(index, 1)
      if (newIndex > -1) this.newRows.splice(newIndex, 1)
      else this.deletedRows.push(row)
    },
    deleteAllAttribute() {
      this.attributes.splice(0, this.attributes.length)
    },
    beforeDeleteDatasourceAndUpdate() {
      const warningText = `${this.$t('ds-manager-editor:' + 'modal.warning_text::delete')}`
      const warningTitle = `${this.$t('ds-manager-editor:' + 'modal.warning_title')}`
      const confirmButtonText = `${this.$t('ds-manager-editor:' + 'modal.confirm_button')}`
      const cancelButtonText = `${this.$t('ds-manager-editor:' + 'modal.cancel_button')}`
      this.$confirm(warningText, warningTitle, {
        customClass: 'r-message-box',
        type: 'warning',
        closeOnPressEscape: false,
        closeOnClickModal: false,
        confirmButtonText,
        cancelButtonText
      })
        .then(async() => {
          this.deleteDatasourceAndUpdate('id')
        })
        .catch(() => {})
    },
    async deleteDatasourceAndUpdate(id) {
      try {
        await this.deleteDatasource(this.activeId)
        const title = `${this.$t('ds-manager-editor:' + 'notify:delete-success-title')}`
        const message = `${this.$t('ds-manager-editor:' + 'notify:delete-success-text')}`
        this.$notify(notifyFactory('success', title, message))
        this.menuClickHandler('ds-manager-general')
      } catch (error) {
        const title = `${this.$t('ds-manager-editor:' + 'notify:delete-fail-title')}`
        const message = `${this.$t('ds-manager-editor:' + 'notify:delete-fail-text')}`
        errorParser.call(this, error, message, title)
      }
      return id
    },
    deleteDatasource(id) {
      this.$store.commit('SET_UPDATE_DS_TREE', true)
      return this.$store.dispatch('DELETE_REQUEST', {
        url: `objectInfo/auth.permission_objects?id=${id}`
      })
    },
    beforeSaveEditing() {
      const warningText = `${this.$t('ds-manager-editor:' + 'modal.warning_text::save')}`
      const warningTitle = `${this.$t('ds-manager-editor:' + 'modal.warning_title')}`
      const confirmButtonText = `${this.$t('ds-manager-editor:' + 'modal.confirm_button')}`
      const cancelButtonText = `${this.$t('ds-manager-editor:' + 'modal.cancel_button')}`
      this.$confirm(warningText, warningTitle, {
        customClass: 'r-message-box',
        type: 'warning',
        closeOnPressEscape: false,
        closeOnClickModal: false,
        confirmButtonText,
        cancelButtonText
      })
        .then(async() => {
          this.saveEditing()
        })
        .catch(() => {})
    },
    saveEditing() {
      if (this.dsName !== this.initialName) {
        this.saveDsTitle()
      }
      if (
        this.changedRows?.length ||
        this.newRows?.length ||
        this.deletedRows?.length
      ) {
        this.saveDsChanges()
      }
    },
    async saveDsChanges() {
      const data = []
      const aliasData = []

      this.changedRows.forEach(k => {
        const object = this.attributes.find(item => item.name === k.name)
        if (object) {
          aliasData.push({
            field_name: object.name,
            alias: object.title
          })
        }
      })
      this.deletedRows.forEach(item =>
        data.push({ _action: 'deleted', title: item.title })
      )
      this.newRows.forEach(e => {
        const initial = cloneDeep(e)
        delete initial.newAttr
        delete initial.name
        initial.title = e.title
        data.push({ ...initial, _action: 'created' })
      })
      data.forEach(item => !item.time_field && delete item.time_field)

      try {
        if (data?.length) {
          await this.$store.dispatch('PUT_REQUEST', {
            url: `objectFields/${this.activeId}`,
            data
          })
        }
        await Promise.all(
          aliasData.map(async e => {
            await this.$store.dispatch('POST_REQUEST', {
              url: `alias/${this.activeId}?field_name=${e.field_name}&alias=${e.alias}`
            })
          })
        )
        this.newRows = []
        this.changedRows = []
        this.deletedRows = []
        this.attributes.forEach(e => {
          if (e.title) e.source_name = e.title
        })
        this.initialAttributes = cloneDeep(this.attributes)
        const title = `${this.$t('ds-manager-editor:' + 'notify:save-success-title')}`
        const message = `${this.$t('ds-manager-editor:' + 'notify:save-success-text')}`
        this.$notify(notifyFactory('success', title, message))
        this.$store.commit('SET_UPDATE_DS_TREE', true)
      } catch (e) {
        console.warn(e)
      }
    },
    beforeCancelChanges() {
      const warningText = `${this.$t('ds-manager-editor:' + 'modal.warning_text::cancel')}`
      const warningTitle = `${this.$t('ds-manager-editor:' + 'modal.warning_title')}`
      const confirmButtonText = `${this.$t('ds-manager-editor:' + 'modal.confirm_button')}`
      const cancelButtonText = `${this.$t('ds-manager-editor:' + 'modal.cancel_button')}`
      this.$confirm(warningText, warningTitle, {
        customClass: 'r-message-box',
        type: 'warning',
        closeOnPressEscape: false,
        closeOnClickModal: false,
        confirmButtonText,
        cancelButtonText
      })
        .then(async() => {
          this.cancelChanges()
        })
        .catch(() => {})
    },
    cancelChanges() {
      this.changedRows.forEach(item => {
        const initial = this.initialAttributes.find(
          object => object.name === item.name
        )
        const current = this.attributes.find(
          object => object.name === item.name
        )
        for (const prop in current) {
          current[prop] = initial[prop]
        }
      })
      this.newRows.forEach(e => {
        const index = this.attributes.findIndex(item => item.title === e.title)
        this.attributes.splice(index, 1)
      })
      this.deletedRows.forEach(item => this.attributes.unshift(item))
      this.deletedRows = []
      this.changedRows = []
      this.newRows = []
      this.dsName = this.initialName
    },
    parseResponseData(data) {
      const arrayData = []

      if (!data || !Object.keys(data)?.length) return arrayData
      for (const prop in data) {
        if (
          prop !== 'updated_user' &&
          prop !== 'created_user' &&
          prop !== 'default_time_field_attribute' &&
          prop !== 'geom' &&
          !data[prop]?.system_field &&
          !data[prop]?.read_only &&
          !(data[prop].reflection !== null && data[prop].type === 'uuid')
        ) {
          if (data[prop].reflection) {
            data[prop].alias = data[prop].reflection.alias
            data[prop].type = 'reflection'
          }
          arrayData.push({
            ...data[prop],
            name: prop,
            title: data[prop]?.alias,
            source_title: data[prop]?.alias,
            alias: data[prop]?.alias
          })
        }
      }

      return arrayData
    },
    async openDataSource(object) {
      const { id } = object
      const data = this.$store.state.dsManager.datasources[id]
      const { datatype, name } = data

      this.initialName = name
      this.dsName = name

      if (datatype !== 'model') {
        this.isSmall = false
        try {
          const { data } = await this.$store.dispatch('GET_REQUEST', {
            url: `objectFields/${id}`
          })

          this.attributes = this.parseResponseData(data).sort((a, b) => {
            if (a.name > b.name) return 1
            if (a.name < b.name) return -1
            return 0
          })

          this.initialAttributes = cloneDeep(this.attributes)
        } catch (e) {
          errorParser.call(this, e)
        }
      }
    }
  }
}
</script>

<style lang="scss">
.ds-manager-editor {
  width: 800px;
  display: grid;
  grid-gap: 0.5rem;

  &.small {
    width: 500px;
  }

  &__header {
    display: grid;
    grid-gap: 0.5rem;
  }

  &__content {
    display: grid;
    grid-gap: 0.5rem;
  }

  &__controls {
    display: grid;
    grid-template-areas: 'delete save' 'cancel cancel';
    justify-content: space-between;
    grid-gap: 0.5rem;

    .r-button.success {
      grid-area: save;
    }

    .r-delete-button {
      grid-area: delete;
    }

    .r-button.simple {
      grid-area: cancel;
      justify-self: end;
    }
  }
}
</style>
