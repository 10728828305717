<template>
  <div
    v-loading="loading"
    class="profiles-constructor"
  >
    <div class="profiles-constructor__block">
      <r-title type="subtitle-2">
        {{ $t('profiles-constructor:' + 'tree:title-profile') }}
      </r-title>
      <profile-tree
        :edit-mode="true"
        module="constructorTree"
      />
    </div>

    <div class="profiles-constructor__controls">
      <el-tooltip
        :open-delay="500"
        :content="$t('profiles-constructor:' + 'tree:ds-add')"
        placement="top"
      >
        <r-button
          icon="add-plus"
          :disabled="!checkedData.add.length"
          :type="checkedData.add.length ? 'primary' : 'default'"
          @click="addObject"
        />
      </el-tooltip>
      <el-tooltip
        :open-delay="500"
        :content="$t('profiles-constructor:' + 'tree:ds-remove')"
        placement="bottom"
      >
        <r-delete-button
          icon="chevron-right"
          :disabled="!checkedData.profile.length"
          simple
          mini
          @delete="deleteObject"
        />
      </el-tooltip>
    </div>
    <div class="profiles-constructor__block">
      <r-title type="subtitle-2">
        {{ $t('profiles-constructor:' + 'tree:title-dsTree') }}
      </r-title>
      <ds-tree
        :node-click="dsNodeClick"
        :active-nodes="dsActiveNodes"
        :tool-kit="toolKit"
        :tool-click="toolClick"
        module="constructorDsTree"
        :edit-mode="true"
      />
    </div>
  </div>
</template>

<script>
import profileTree from '@/components/profile-tree/profile-tree.vue'
import dsTree from '@/components/ds-tree/ds-tree.vue'

import { notifyFactory } from '@/utils'
import cloneDeep from 'lodash.clonedeep'

export default {
  components: {
    profileTree,
    dsTree
  },
  data() {
    return {
      loading: false,
      checkedData: {
        add: [],
        profile: []
      }
    }
  },
  computed: {
    currentProfileTree() {
      return this.$store.state.profiles.currentProfileTree || []
    },
    currentProfileId() {
      return this.$store.state.profiles.profileConstructor.profileId
    },
    dsActiveNodes() {
      return this.checkedData.add.map(({ id }) => id)
    },
    dsTree() {
      return this.$store.state.dsManager?.dsTree || []
    }
  },
  watch: {
    '$store.state.profiles.profileConstructor.checkedData': {
      handler: function(val) {
        this.checkedData.add = val
      },
      deep: true,
      immediate: true
    },
    '$store.state.profiles.profileConstructor.checkedDataProfile': {
      handler: function(val) {
        this.checkedData.profile = val
      },
      deep: true,
      immediate: true
    }
  },
  beforeDestroy() {
    this.$store.commit('CLEAR_CHECKED_DATA')
    this.$store.commit('CLEAR_CHECKED_DATA', true)
  },
  methods: {
    async toolClick(type, node) {
      switch (type) {
        case 'check': {
          this.dsFolderClick(node)
          break
        }
      }
    },
    toolKit({ children }) {
      return children?.length
        ? [
          {
            icon: 'check',
            type: 'check',
            name: 'Выбрать папку'
          }
        ]
        : []
    },
    dsFolderClick(node) {
      const { children } = node
      const { checkedData } = this.$store.state.profiles.profileConstructor
      const checkedIdList = checkedData.map(({ id }) => id)

      let checked

      if (checkedIdList.includes(node?.id)) {
        const newIdList = children.map(({ id }) => id)
        checked = checkedData.filter(({ id }) => !(id === node.id || !newIdList.includes(node?.id)))
      } else {
        checked = [...checkedData, node, ...children]
      }
      this.$store.commit('UPDATE_CHECKED_DATA', {
        checkedData: checked
      })
      this.activeNodes = checked
    },
    dsNodeClick(node) {
      const { profiles } = this.$store.state
      const { checkedData } = profiles.profileConstructor
      let checked = [...checkedData]

      if (node.parent_id && !checked.find(cd => cd.id === node.parent_id)) {
        const parent = this.dsTree?.find(ds => ds.id === node.parent_id)
        checked = [...checked, parent]
      }

      checked = checked.find(d => d.id === node.id)
        ? checked.filter(p => p.id !== node.id)
        : [...checked, node]

      const emptyParents = []

      checked.forEach(ch => {
        if (ch.children?.length) {
          const isEmpty = !checked.find(p => p.parent_id === ch.id)

          if (isEmpty) {
            emptyParents.push(ch.id)
          }
        }
      })

      if (emptyParents?.length) {
        checked = checked.filter(c => !emptyParents?.includes(c.id))
      }

      this.$store.commit('UPDATE_CHECKED_DATA', {
        checkedData: checked
      })
      this.activeNodes = checked
    },
    async addObject() {
      if (!this.currentProfileId) return
      if (!this.checkedData.add.length) return
      const profileTree = []

      this.checkedData.add.forEach(e => {
        let isCopy = false
        let name = cloneDeep(e.name)
        isCopy = this.currentProfileTree.find(n => {
          if (n.name === name) return true
        })
        const lastIndex =
          Math.max(
            ...this.currentProfileTree
              .map(v => v.name)
              .map(x => x.match(/копия_\d+/))
              .map(x => x && parseInt(x[0].replace('копия_', '')))
          ) + 1

        name = isCopy ? `${name} (копия_${lastIndex})` : name

        if (!e.parent_id) {
          profileTree.push({
            name: name,
            source_id: e.id,
            children: []
          })
        } else {
          profileTree.forEach(n => {
            if (n.source_id !== e.parent_id) {
              return n
            } else {
              return n.children.push({
                name: name,
                source_id: e.id,
                children: []
              })
            }
          })
        }
      })

      const options = {
        url: 'user/profile?',
        data: { id: this.currentProfileId, profile_trees: profileTree }
      }
      const currentProfileId = this.$store.state.profiles.currentProfile.id

      try {
        this.loading = true
        await this.$store.dispatch('POST_REQUEST', options)
        const user = await this.$store.dispatch('GET_REQUEST', { url: 'user' })
        if (this.currentProfileId === currentProfileId) {
          this.$store.commit('SET_CURRENT_PROFILE', user.data.current_profile)
        }
        this.$store.commit('SET_PROFILES_TREE', user.data.user_profiles)
        this.$store.commit('CLEAR_CHECKED_DATA')
        this.checkedData.add = []
        const title = this.$t('profiles-constructor:' + 'profiles-constructor:notify:success-title')
        const message = this.$t('profiles-constructor:' + 'profiles-constructor:notify:success')
        this.$notify(notifyFactory('info', title, message))
        await this.updateProfileTree()
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    async deleteObject() {
      if (!this.checkedData.profile.length) return
      if (!this.currentProfileId) return
      const deleteFromTree = []

      this.checkedData.profile.forEach(e => {
        deleteFromTree.push({
          id: e,
          disabled: true
        })
      })

      const options = {
        url: 'user/profile?',
        data: { id: this.currentProfileId, profile_trees: deleteFromTree }
      }
      const currentProfileId = this.$store.state.profiles.currentProfile.id
      try {
        this.loading = true
        await this.$store.dispatch('POST_REQUEST', options)
        const user = await this.$store.dispatch('GET_REQUEST', { url: 'user' })
        if (this.currentProfileId === currentProfileId) {
          this.$store.commit('SET_CURRENT_PROFILE', user.data.current_profile)
        }
        this.$store.commit('SET_PROFILES_TREE', user.data.user_profiles)
        this.$store.commit('CLEAR_CHECKED_DATA', true)
        this.checkedData.profile = []
        const title = this.$t('profiles-constructor:' + 'profiles-constructor:notify:success-title')
        const message = this.$t('profiles-constructor:' + 'profiles-constructor:notify:success')
        this.$notify(notifyFactory('info', title, message))
        await this.updateProfileTree()
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    async updateProfileTree() {
      const url = `profile_tree?profile_id=${this.currentProfileId}`

      try {
        this.loading = true

        const { data } = await this.$store.dispatch('GET_REQUEST', { url })

        this.$store.commit('SET_CURRENT_PROFILE_TREE', data.profile_trees)
        this.$store.commit('PROFILES_SET_FIELD', ['updatedTreeTS', this.$ritmDate.toUnix(this.$ritmDate.date())])
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.profiles-constructor {
  align-items: start;
  justify-items: stretch;
  display: grid;
  grid-gap: 0.5rem 1rem;
  overflow: hidden;
  grid-template-columns: 1fr 2.5rem 1fr;
  width: 800px;

  &__block {
    height: 500px;
    overflow: auto;
    display: grid;
    grid-gap: 0.5rem;
    background-color: var(--bg_containers);
    padding: 0.5rem;
    align-content: start;
    border-radius: var(--border-radius);
  }

  &__controls {
    display: grid;
    grid-gap: 1rem;
    height: 100%;
    align-content: center;
    justify-content: center;
  }
}
</style>
