<template>
  <div class="files-comments__files">
    <file-upload
      :id="id"
      :source_id="source_id"
      @load="loadResources"
      @loading="$emit('loading', ['files', true])"
    />
    <r-text
      v-if="!resources.length"
      type="caption"
    >
      {{ $t('files:' + 'files:no-files') }}
    </r-text>
    <ul
      v-else
      class="files-comments__files-download-list"
    >
      <file-item
        v-for="item in resources"
        :key="item.id"
        :source_id="source_id"
        :file="item"
        @load="loadResources"
      />
    </ul>
  </div>
</template>

<script>
import fileItem from './file-item'
import fileUpload from './file-upload'

export default {
  components: { fileItem, fileUpload },
  props: {
    source_id: {
      type: String,
      default: null
    },
    id: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      resources: []
    }
  },
  watch: {
    id() {
      this.loadResources()
    }
  },
  async created() {
    await this.loadResources()
  },
  methods: {
    async loadResources() {
      try {
        this.$emit('loading', ['files', true])
        const url = `objectResources/${this.source_id}/${this.id}`
        const { data } = await this.$store.dispatch('GET_REQUEST', { url })
        this.resources = data.reverse()
      } catch (e) {
        throw new Error(e)
      } finally {
        this.$emit('loading', ['files', false])
      }
    }
  }
}
</script>

<style lang="scss">
.files-comments {
  &__files {
    &-download-list {
      display: grid;
      grid-auto-flow: row;
      grid-gap: 0.5rem;
      align-items: start;
      align-content: flex-start;
      overflow: auto;
      height: 100%;
    }
  }
}
</style>
