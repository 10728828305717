<template>
  <div class="ds-export">
    <r-title
      type="title-2"
      class="ds-export-title"
    >
      {{ `${$t('ds-export:' + 'modal.export:title')}` }}
    </r-title>
    <div class="ds-export-content">
      <r-title>{{ dsExportItem.name }}</r-title>
      <r-text>{{ `${$t('ds-export:' + 'modal.export:text')}` }}</r-text>
      <ul class="ds-export-list">
        <li
          v-for="(e, i) in formatsList"
          :key="i"
          :class="[`ds-export-item ${e.name}`, { active: isActive(e.name) }]"
          @click="chooseFormats(e)"
        >
          <r-icon
            :name="e.name"
            :size="70"
          />
        </li>
      </ul>
    </div>
    <div class="ds-export-controls">
      <r-button
        simple
        type="primary"
        @click="cancel"
      >
        {{ $t('ds-export:' + 'cancel') }}
      </r-button>
      <r-button
        type="primary"
        :disabled="!activeFormat"
        @click="download"
      >
        {{ $t('ds-export:' + 'download') }}
      </r-button>
    </div>
  </div>
</template>

<script>
import { downloadFile } from '@/utils/helpers'
import { getFormatsList } from './utils/'

export default {
  data() {
    return {
      token: this.$store.state.auth.token,
      formatsList: [],
      activeFormat: null
    }
  },
  computed: {
    theme() {
      return this.$store.state.theme
    },
    dsExportItem() {
      return this.$store.state.dsManager.dsExportItem
    }
  },
  mounted() {
    this.formatsList = getFormatsList(this, this.dsExportItem)
  },
  methods: {
    chooseFormats(e) {
      if (this.activeFormat && this.activeFormat.name === e.name) {
        this.activeFormat = null
      } else {
        this.activeFormat = e
      }
    },
    cancel() {
      this.formatsList = []
      this.activeFormat = null
      this.$store.commit('CLOSE_MODAL_WINDOW', '')
    },
    download() {
      downloadFile(this.activeFormat)
    },
    isActive(name) {
      return !!(this.activeFormat && this.activeFormat.name === name)
    }
  }
}
</script>

<style lang="scss">
.ds-export {
  position: relative;
  width: 458px;
  overflow: hidden;
  padding: 0 24px 24px;
  &-content {
    margin-top: 32px;
    overflow: hidden;
    max-height: calc(80vh - 250px);
    overflow-y: auto;
    .r-text {
      margin-top: 12px;
    }
  }
  &-list {
    height: 70px;
    position: relative;
    display: flex;
    margin-top: 70px;
    justify-content: space-around;
  }
  &-item {
    cursor: pointer;
    position: relative;
    transition: all 0.15s ease;
    height: 70px;
    .r-text {
      position: absolute;
      display: block;
      padding: 5px 6px 5.5px;
      top: 12px;
      left: -4px;
      background-color: var(--dividers_low_contrast) !important;
    }
    .r-icon {
      svg {
        fill: var(--text_secondary) !important;
      }
    }
    &.active {
      margin-top: -16px;
      div.r-text {
        color: var(--white_white) !important;
      }
      &.json {
        .r-text {
          background-color: var(--export-json) !important;
        }
        svg {
          fill: var(--export-json) !important;
        }
      }
      &.geojson {
        .r-text {
          background-color: var(--export-geojson) !important;
        }
        svg {
          fill: var(--export-geojson) !important;
        }
      }
      &.csv {
        .r-text {
          background-color: var(--export-csv) !important;
        }
        svg {
          fill: var(--export-csv) !important;
        }
      }
      &.shp {
        .r-text {
          background-color: var(--export-shp) !important;
        }
        svg {
          fill: var(--export-shp) !important;
        }
      }
      &.net {
        .r-text {
          background-color: var(--export-net) !important;
        }
        svg {
          fill: var(--export-net) !important;
        }
      }
    }
  }

  &-controls {
    display: grid;
    grid-gap: 0.5rem;
    grid-auto-flow: column;
    margin-top: 48px;
    justify-content: end;
  }
}
</style>
