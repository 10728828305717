<template>
  <div class="sc-status-widget type-1">
    <div class="sc-status-widget__list">
      <div
        v-for="(item, i) in filteredData"
        :key="item + i"
        class="sc-status-widget__list-item"
      >
        <div class="sc-status-widget__list-item__col">
          <r-cblock
            :size="40"
            :color="colors[item.type] || colors.default"
            :border-color="colors[item.type] || colors.default"
          >
            <r-icon
              :name="icons[item.type] || icons.default"
              :size="24"
              :color="colors[item.type] || colors.default"
            />
          </r-cblock>
        </div>
        <div class="sc-status-widget__list-item__col">
          <r-title type="title-1">
            {{ item.value }}
          </r-title>
          <r-text type="caption">
            {{ $t('type-1:' + item.type) }}
          </r-text>
        </div>
      </div>
    </div>
    <div class="sc-status-widget__status">
      <el-progress
        :stroke-width="24"
        :color="getProgressColor"
        :percentage="getPercentage()"
        :style="getProgressStyles"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    statusData: {
      type: Object,
      default: () => null,
      required: true
    },
    colors: {
      type: Object,
      default: () => null
    },
    icons: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    filteredData() {
      return this.statusData.items
        ? this.statusData.items.filter(
          e => e.type === 'work' || e.type === 'error'
        )
        : []
    },
    getProgressColor() {
      if (this.getPercentage() > 80) return '#59D64E'
      if (this.getPercentage() > 60) return '#2e93fe'
      if (this.getPercentage() > 40) return '#6f7ad3'
      if (this.getPercentage() > 20) return '#ffac00'

      return '#FF3E3E'
    },
    getProgressStyles() {
      return {
        'background-color': `${this.getProgressColor}10 !important`,
        'border-color': `${this.getProgressColor} !important`
      }
    }
  },
  methods: {
    getPercentage() {
      const workCount = this.filteredData
        ? this.filteredData.filter(e => e.type === 'work')[0]
          ? this.filteredData.filter(e => e.type === 'work')[0].value
          : 0
        : 0
      return (
        Number(((workCount * 100) / this.statusData.total).toFixed(0)) || 0
      )
    }
  }
}
</script>

<style lang="scss">
.sc-status-widget {
  &.type-1 {
    flex-direction: column;
    .sc-status-widget__list {
      width: calc(100% - 24px);
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 12px;
      height: 100px;
      &-item {
        min-width: 116px;
        display: flex;
        align-items: center;
        &__col {
          margin-right: 8px;
          overflow: hidden;
          width: 44%;
          .r-text,
          .r-text.caption-2 {
            overflow: hidden;
            text-overflow: ellipsis;
          }
          &:last-child {
            width: 56%;
            margin-right: 0;
          }
        }
      }
    }
    .sc-status-widget__status {
      margin-top: 24px;
      .el-progress {
        border: 1px solid !important;
        border-radius: 24px !important;
        &-bar {
          padding: 0;
        }
        &-bar__outer {
          background-color: transparent !important;
        }
        &__text {
          color: var(--black);
          font-size: 14px !important;
          filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.6));
          position: absolute;
          top: 45%;
          left: 50%;
          margin: 0;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
}
</style>
