<template>
  <ul class="sc-basic-widget__list-simple">
    <el-tooltip
      v-for="(item, i) in itemList"
      :key="item + i"
      :open-delay="500"
      :content="$t('simple-list:' + item.type)"
      placement="top"
    >
      <li class="sc-basic-widget__list-simple-item">
        <div
          class="sc-basic-widget__list-simple-item__bg"
          :style="getBgColor(colors[item.type] || colors.default)"
        />
        <div
          class="sc-basic-widget__list-simple-item__border"
          :style="getBorderColor(colors[item.type] || colors.default)"
        />
        <r-title>{{ item.value }}</r-title>
      </li>
    </el-tooltip>
  </ul>
</template>

<script>
export default {
  props: {
    itemList: {
      type: Array,
      required: true,
      default: () => []
    },
    colors: {
      type: Object,
      default: () => null
    }
  },
  methods: {
    getStyle(color) {
      const styles = {}

      styles['border-color'] = `${color} !important`
      styles.color = `${color} !important`

      return styles
    },
    getBorderColor(color) {
      return {
        'background-color': `${color} !important`
      }
    },
    getBgColor(color) {
      return {
        'background-color': `${color} !important`,
        opacity: '0.1'
      }
    }
  }
}
</script>

<style lang="scss">
.sc-basic-widget__list-simple {
  display: grid;
  grid-gap: 18px 20px;
  grid-template-columns: repeat(4, 48px);
  grid-template-rows: repeat(4, 30px);
  grid-auto-flow: row;
  max-height: 180px;
  width: 100%;
  overflow: auto;
  height: 100%;
  text-align: center;
  &-item {
    position: relative;
    min-width: 48px;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    &__border {
      width: 3px;
      height: 100%;
      border-radius: var(--border-radius);
      z-index: 10;
      position: absolute;
      top: 0;
      left: 0;
    }
    &__bg {
      position: absolute;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
