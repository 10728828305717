<template>
  <div class="profile-nav">
    <r-text
      type="caption"
      class="profile-nav__title"
    >
      {{ $t('profiles-menu:' + 'current-profile') }}
    </r-text>
    <el-dropdown
      class="r-dropdown"
      trigger="click"
      @command="handleClick"
    >
      <r-button
        v-popover:popover
        simple
        dropdown
        mini
        class="profile-nav__button"
      >
        {{ currentProfile.name || $t('profiles-menu:' + 'profiles:menu-title') }}
      </r-button>
      <el-dropdown-menu
        slot="dropdown"
        class="r-dropdown-menu profiles-menu"
      >
        <el-dropdown-item
          v-for="item in profilesList"
          :key="item.id"
          :command="item.id"
          :class="currentProfile.id === item.id ? 'active' : ''"
        >
          <div class="r-dropdown-menu__item-wrapper">
            <r-text>{{ item.name }}</r-text>
          </div>
        </el-dropdown-item>
        <div class="devider" />
        <el-dropdown-item command="open-settings">
          <div class="r-dropdown-menu__item-wrapper">
            <r-title type="subtitle-1">
              {{ $t('profiles-menu:' + 'profiles:menu-settings') }}
            </r-title>
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
export default {
  computed: {
    userId() {
      return this.$store.state.userId
    },
    profilesList() {
      return this.$store.state.profiles.profilesTree || []
    },
    currentProfile() {
      return this.$store.state.profiles.currentProfile || {}
    }
  },
  methods: {
    handleClick(val) {
      switch (val) {
        case 'open-settings':
          this.openProfilesSettings()
          break
        default:
          this.changeProfile(val)
          break
      }
    },
    openProfilesSettings() {
      this.$store.commit('OPEN_MODAL_WINDOW', 'profiles')
    },
    async changeProfile(id) {
      if (!id) return
      if (id === this.currentProfile.id) return
      const dataUserObj = {
        id: this.userId,
        current_profile_id: id
      }
      try {
        this.$store.commit('CHANGE_PROFILE', true)
        await this.$store.dispatch('PUT_REQUEST', {
          url: 'user',
          data: dataUserObj
        })
        document.location.reload(true)
      } catch (e) {
        this.$store.commit('CHANGE_PROFILE', false)
      }
    }
  }
}
</script>

<style lang="scss">
.profile-nav {
  &__button {
    padding: 0 !important;
    height: auto !important;

    .r-button__text {
      font-size: 12px;
      line-height: 1;
    }
  }
  .profiles-menu {
    max-height: 80vh;
    overflow: auto;
    max-width: 250px;
    left: 52px !important;
    top: 45px !important;
  }
}
</style>
