var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ds-manager__component empty-ds"},[_c('div',{staticClass:"ds-manager__component-title"},[_c('r-title',{attrs:{"type":"title-2"}},[_vm._v(" "+_vm._s(_vm.$t('ds-manager-empty-ds:' + 'ds-manager:creation:empty-ds:title'))+" ")])],1),_c('div',{staticClass:"ds-manager__component-content"},[_c('el-row',{staticClass:"ds-manager__component-row"},[_c('el-col',{staticClass:"r-col",attrs:{"span":12}},[_c('div',{staticClass:"ds-manager__component-subtitle"},[_c('r-text',{attrs:{"type":"caption"}},[_vm._v(" "+_vm._s(_vm.$t('ds-manager-empty-ds:' + 'ds-manager:creation:empty-ds:ds-name'))+" ")])],1),_c('el-input',{staticClass:"r-input",attrs:{"size":"mini","placeholder":`${_vm.$t('ds-manager-empty-ds:' +
            'ds-manager:creation:empty-ds:ds-name-placeholder'
          )}`},model:{value:(_vm.dsName),callback:function ($$v) {_vm.dsName=$$v},expression:"dsName"}}),_c('el-row',{staticClass:"ds-manager__component-row",staticStyle:{"margin-top":"8px"}},[_c('div',{staticClass:"ds-manager__component-subtitle"},[_c('r-text',{attrs:{"type":"caption"}},[_vm._v(" "+_vm._s(_vm.$t('ds-manager-empty-ds:' + 'ds-manager:creation:enter-profiles'))+" ")])],1),_c('el-select',{staticClass:"r-select",attrs:{"size":"mini","multiple":"","collapse-tags":"","placeholder":`${_vm.$t('ds-manager-empty-ds:' +
              'ds-manager:creation:select-profiles-placeholder'
            )}`},model:{value:(_vm.selectedProfiles),callback:function ($$v) {_vm.selectedProfiles=$$v},expression:"selectedProfiles"}},_vm._l((_vm.profiles),function(profile){return _c('el-option',{key:profile.id,attrs:{"value":profile.id,"label":profile.name}})}),1)],1)],1),_c('el-col',{staticClass:"r-col",attrs:{"span":12}},[_c('div',{staticClass:"ds-manager__component-subtitle"},[_c('r-text',{attrs:{"type":"caption"}},[_vm._v(" "+_vm._s(_vm.$t('ds-manager-empty-ds:' + 'ds-manager:creation:empty-ds:geom-type'))+" ")])],1),_c('el-select',{staticClass:"r-select",attrs:{"size":"mini","placeholder":`${_vm.$t('ds-manager-empty-ds:' +
            'ds-manager:creation:empty-ds:geom-type-placeholder'
          )}`,"no-match-text":`${_vm.$t('ds-manager-empty-ds:' + 'ds-manager:creation:no-match')}`,"clearable":"","filterable":""},model:{value:(_vm.geomType),callback:function ($$v) {_vm.geomType=$$v},expression:"geomType"}},_vm._l((_vm.geomTypes),function(subitem){return _c('el-option',{key:subitem,attrs:{"label":subitem,"value":subitem}})}),1)],1)],1),_c('el-row',{staticClass:"ds-manager__component-row"},[_c('ds-manager-table',{attrs:{"attributes":_vm.attributes,"initial-attributes":_vm.initialAttributes,"changed-rows":_vm.changedRows,"fields":_vm.fields,"types":_vm.types,"datasources-list":_vm.datasourcesList},on:{"nameValidation":_vm.nameValidation,"addAttribute":_vm.addAttribute,"deleteAttribute":_vm.deleteAttribute,"deleteAllAttribute":_vm.deleteAllAttribute}})],1),_c('el-row',{staticClass:"ds-manager__component-row text-right"},[_c('r-button',{attrs:{"type":"success","disabled":!_vm.isCreateReady},on:{"click":_vm.createEmptyDs}},[_vm._v(" "+_vm._s(_vm.$t('ds-manager-empty-ds:' + 'ds-manager:creation:create-ds'))+" ")])],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }