<template>
  <div class="settings-reset">
    <div class="settings-reset__block">
      <div class="settings-reset__container">
        <el-row class="settings-reset__row">
          <r-text>{{ $t('settings-reset:' + 'settings-reset.user-config') }}</r-text>
        </el-row>
        <el-row class="settings-reset__row">
          <el-checkbox-group
            v-model="checkedConfigs"
            @change="handleCheckedConfigsChange"
          >
            <el-checkbox
              v-for="config in configs"
              :key="config"
              class="r-checkbox"
              :label="config"
            >
              <r-text>{{ $t('settings-reset:' + config.toLowerCase()) }}</r-text>
            </el-checkbox>
          </el-checkbox-group>
        </el-row>
        <el-row class="settings-reset__row">
          <el-checkbox
            v-model="checkAll"
            class="r-checkbox"
            :indeterminate="isIndeterminate"
            @change="handleCheckAllChange"
          >
            <r-text>{{ $t('settings-reset:' + 'settings-reset.reset-all') }}</r-text>
          </el-checkbox>
        </el-row>
        <el-row class="settings-reset__row text-right">
          <r-button
            :loading="isLoading"
            type="danger"
            :disabled="!checkedConfigs.length"
            @click="openModalBox"
          >
            {{ this.$t('settings-reset:' + 'settings-reset.reset-config') }}
          </r-button>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import { notifyFactory, errorParser } from '@/utils'

const userConfigs = ['Book', 'Map']

export default {
  data() {
    return {
      checkAll: false,
      configs: userConfigs,
      checkedConfigs: [],
      isIndeterminate: false,

      isLoading: false,
      token: this.$store.state.auth.token
    }
  },
  computed: {
    profileId() {
      return this.$store.state.profiles.currentProfile.id
    }
  },
  methods: {
    openModalBox() {
      const warningText = `${this.$t('settings-reset:' + 'modalWarningText')}`
      const warningTitle = `${this.$t('settings-reset:' + 'modalWarningTitle')}`
      const confirmButtonText = `${this.$t('settings-reset:' + 'modalConfirmButton')}`
      const cancelButtonText = `${this.$t('settings-reset:' + 'modalCancelButton')}`
      this.$confirm(warningText, warningTitle, {
        customClass: 'r-message-box',
        closeOnPressEscape: false,
        closeOnClickModal: false,
        type: 'warning',
        confirmButtonText,
        cancelButtonText
      })
        .then(async() => {
          await this.resetUserConfig()
        })
        .catch(() => {})
    },
    async resetUserConfig() {
      const message = `${this.$t('settings-reset:' + 'infoText')}`
      const messageTitle = `${this.$t('settings-reset:' + 'infoTitle')}`
      const errorMessage = `${this.$t('settings-reset:' + 'errorText')}`
      const errorTitle = `${this.$t('settings-reset:' + 'errorTitle')}`
      const modules = this.checkedConfigs.join(',').toLowerCase()

      try {
        this.isLoading = true
        this.$store.commit('RESET_USER_CONFIG', this.checkedConfigs)
        await this.$store.dispatch('SAVE_MAIN_USER_CONFIG')
        await this.$store.dispatch('POST_REQUEST', {
          url: `user/delete_source_config?module=${modules}&profile_id=${this.profileId}`
        })
        document.location.reload(true)
        this.$notify(notifyFactory('info', messageTitle, message))
        this.isLoading = false
      } catch (e) {
        errorParser.call(this, e, errorMessage, errorTitle)
        this.isLoading = false
      }
    },
    handleCheckAllChange(val) {
      this.checkedConfigs = val ? userConfigs : []
      this.isIndeterminate = false
    },
    handleCheckedConfigsChange(value) {
      const checkedCount = value.length
      this.checkAll = checkedCount === this.configs.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.configs.length
    }
  }
}
</script>

<style lang="scss">
.settings-reset {
  &__row {
    margin-bottom: 20px;
    &:last-child {
      margin-bottom: 0;
    }
    .el-checkbox {
      &-group {
        display: flex;
        justify-content: space-between;
        padding-right: 32px;
      }
    }
  }
  .text-right {
    text-align: right;
  }
}
</style>
