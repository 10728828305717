<template>
  <div class="ds-manager__table">
    <el-row
      type="flex"
      class="r-row ds-manager__component-row"
    >
      <div class="ds-manager__component-subtitle">
        <r-text type="caption">
          {{ $t('ds-manager-table:' + 'ds-manager:table:list-title') }}
        </r-text>
      </div>
      <div class="ds-manager__table-tools">
        <div class="ds-manager__table-tools__item">
          <el-tooltip
            :open-delay="1500"
            :content="`${$t('ds-manager-table:' + 'ds-manager:table:tools:delete-all')}`"
            placement="top"
          >
            <r-delete-button
              simple
              mini
              @delete="beforeDeleteAllAttributes"
            />
          </el-tooltip>
        </div>
        <div class="ds-manager__table-tools__item">
          <el-tooltip
            :open-delay="1500"
            :content="`${$t('ds-manager-table:' + 'ds-manager:table:tools:add')}`"
            placement="top"
          >
            <r-button
              type="primary"
              :disabled="!addAttributeEnable"
              @click="addAttribute"
            >
              {{ $t('ds-manager-table:' + 'ds-manager:table:tools:add') }}
            </r-button>
          </el-tooltip>
        </div>
      </div>
    </el-row>
    <el-row class="r-row ds-manager__component-row">
      <div class="ds-manager__table-wrapper">
        <el-table
          :data="attributes"
          class="r-table simple"
          border
          :cell-class-name="getCellClassName"
          :row-class-name="getRowClassName"
        >
          <el-table-column
            v-for="title in fields"
            :key="title.prop"
            :prop="title.prop"
            :label="title.label"
          >
            <template slot-scope="scope">
              <el-input
                v-if="title.type === 'string' || title.type === 'name'"
                v-model="scope.row[title.prop]"
                class="r-input"
                size="mini"
              />
              <r-text
                v-else-if="title.type === 'read_only'"
                style="opacity: 0.7"
              >
                {{ scope.row[title.prop] }}
              </r-text>
              <r-text v-else-if="scope.row.type === 'reflection'">
                -
              </r-text>
              <el-select
                v-else-if="title.type === 'belongs_to'"
                v-model="scope.row[title.prop]"
                class="r-select"
                size="mini"
                :no-match-text="$t('ds-manager-table:' + 'ds-manager:creation:no-match')"
                filterable
              >
                <el-option
                  v-for="subitem in types"
                  :key="subitem"
                  :label="subitem"
                  :value="subitem"
                />
              </el-select>
              <el-checkbox
                v-else-if="title.type === 'bool'"
                v-model="scope.row[title.prop]"
                class="r-checkbox"
                size="mini"
              />
              <span v-else-if="title.type === 'datasource'">
                {{ getDsName(scope.row[title.prop], scope.row) }}
              </span>
              <span v-else>{{ scope.row[title.prop] }}</span>
            </template>
          </el-table-column>
          <el-table-column width="70px">
            <template slot-scope="scope">
              <el-tooltip
                :open-delay="1500"
                :content="`${$t('ds-manager-table:' + 'ds-manager:table:tools:delete')}`"
                placement="top"
              >
                <r-delete-button
                  mini
                  simple
                  @delete="beforeDeleteAttribute(scope.row)"
                />
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-row>
  </div>
</template>

<script>
import isEqual from 'lodash.isequal'

export default {
  props: {
    attributes: {
      type: Array,
      default: () => []
    },
    fields: {
      type: Array,
      default: () => []
    },
    types: {
      type: Array,
      default: () => []
    },
    datasourcesList: {
      type: Array,
      default: () => []
    },
    initialAttributes: {
      type: Array,
      default: () => []
    },
    changedRows: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      isValid: false
    }
  },
  computed: {
    addAttributeEnable() {
      if (!this.attributes.length) return true
      const isTitleEmpty = this.attributes.find(
        e => !e.title || !e.title.trim().length
      )
      const isTypeEmpty = this.attributes.find(
        e => !e.type || !e.type.trim().length
      )

      return !(isTitleEmpty || isTypeEmpty)
    }
  },
  watch: {
    attributes: {
      handler: function(val) {
        this.isValid = true
        val
          .map(e => e.title)
          .forEach((x, i, arr) =>
            i !== arr.indexOf(x) || i !== arr.lastIndexOf(x)
              ? (this.isValid = false)
              : ''
          )

        this.$emit('nameValidation', this.isValid)
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    addAttribute() {
      this.$emit('addAttribute')
    },
    getDsName(source_id, row) {
      // get ds name by source_id for table
      if (!source_id) return '-'
      const ds = this.datasourcesList.find(ds => ds.id === source_id)
      if (!ds) return 'ИД не найден'
      return `${ds.name} (${row.primary_key})`
    },
    beforeDeleteAttribute(row) {
      const warningText = `${this.$t('ds-manager-table:' + 'modal.warning_text::delete')}`
      const warningTitle = `${this.$t('ds-manager-table:' + 'modal.warning_title')}`
      const confirmButtonText = `${this.$t('ds-manager-table:' + 'modal.confirm_button')}`
      const cancelButtonText = `${this.$t('ds-manager-table:' + 'modal.cancel_button')}`
      this.$confirm(warningText, warningTitle, {
        customClass: 'r-message-box',
        type: 'warning',
        closeOnPressEscape: false,
        closeOnClickModal: false,
        confirmButtonText,
        cancelButtonText
      })
        .then(() => {
          this.deleteAttribute(row)
        })
        .catch(() => {})
    },
    beforeDeleteAllAttributes() {
      const warningText = `${this.$t('ds-manager-table:' + 'modal.warning_text::delete-all')}`
      const warningTitle = `${this.$t('ds-manager-table:' + 'modal.warning_title')}`
      const confirmButtonText = `${this.$t('ds-manager-table:' + 'modal.confirm_button')}`
      const cancelButtonText = `${this.$t('ds-manager-table:' + 'modal.cancel_button')}`
      this.$confirm(warningText, warningTitle, {
        customClass: 'r-message-box',
        type: 'warning',
        closeOnPressEscape: false,
        closeOnClickModal: false,
        confirmButtonText,
        cancelButtonText
      })
        .then(() => {
          this.deleteAllAttribute()
        })
        .catch(() => {})
    },
    deleteAttribute(row) {
      if (row.title === 'id' || row.title === 'geom') return
      this.$emit('deleteAttribute', row)
    },
    deleteAllAttribute() {
      this.$emit('deleteAllAttribute')
    },
    nameValidate(currentName, newName) {
      let result = true
      this.initialAttributes.forEach(e => {
        if (
          (e.source_name === newName && e.source_name !== currentName) ||
          newName.replace(/\s+/g, '') === ''
        ) {
          result = false
        }
      })
      return result
    },
    validateName(name) {
      if (!name) return false
      let matchCount = 0

      this.attributes.forEach(e => {
        if (e.title) {
          if (e.title.trim() === name.trim()) ++matchCount
        }
      })

      return matchCount
    },
    getCellClassName({ row, columnIndex }) {
      if (columnIndex === 0) {
        let nameAvailable
        if (row.title) {
          nameAvailable = !!(this.validateName(row.title) < 2)
          if (!nameAvailable) return 'incorrect'
        } else {
          nameAvailable = false
        }
      }
    },
    getRowClassName(row) {
      const current = row.row
      const name = current.name
      const title = current.title
      const initial = this.initialAttributes.find(item => item.name === name)
      if (!initial) return 'row-new'
      for (const prop in initial) {
        const currentValue = current[prop] === '' ? null : current[prop]
        const initialValue = initial[prop] === '' ? null : initial[prop]
        if (
          current[prop] !== undefined &&
          !isEqual(currentValue, initialValue)
        ) {
          if (this.changedRows.findIndex(e => e.name === name) === -1) {
            this.$emit('addChangedRow', {
              name,
              title
            })
          }
          return 'row-edited'
        }
      }
      const changedIndex = this.changedRows.findIndex(e => e.name === name)

      if (changedIndex > -1) this.$emit('deleteChangedRow', changedIndex)
    }
  }
}
</script>

<style lang="scss">
.ds-manager__table {
  .el-row--flex {
    justify-content: space-between;
    align-items: center;
    .ds-manager__component-subtitle {
      padding: 0;
    }
  }
  &-tools {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    &__item {
      margin-right: 8px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .r-table {
    td {
      .cell {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .r-checkbox {
        width: 100%;
        justify-content: center;
      }
      &.incorrect {
        background-color: var(--accent_error_light) !important;
      }
    }
    tr {
      &.not-editable {
        background-color: var(--black_overlay) !important;
        label {
          cursor: not-allowed;
        }
        input {
          color: var(--text_secondary) !important;
        }
        &:hover {
          td {
            background-color: var(--black_overlay) !important;
          }
        }
      }
      &.row-edited,
      &.row-new {
        background-color: var(--accent_hover) !important;
      }
    }
  }
}
</style>
