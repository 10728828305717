<template>
  <div
    :class="[
      'ds-manager',
      { general: activeComponent === 'ds-manager-general' }
    ]"
  >
    <div
      v-if="activeComponent !== 'ds-manager-general'"
      class="ds-manager__header"
    >
      <ul class="ds-manager__navigation">
        <li
          class="ds-manager__navigation-item"
          @click="changeComponent(previousComponent)"
        >
          <span class="r-icon-arrow left" />
          <r-text color-type="accent-primary">
            {{ $t('ds-manager:' + `ds-manager:navigation:item-name:${previousComponent}`) }}
          </r-text>
        </li>
      </ul>
    </div>

    <component
      :is="activeComponent"
      v-if="
        activeComponent === 'ds-manager-menu' ||
          activeComponent === 'ds-manager-general' ||
          activeComponent === 'ds-manager-editor'
      "
      :menu-click-handler="changeComponent"
    />
    <component
      :is="activeComponent"
      v-else
    />
    <r-button
      v-if="activeComponent === 'ds-manager-general'"
      class="ds-manager__create-button"
      type="primary"
      icon="add-plus"
      @click="changeComponent('ds-manager-menu')"
    >
      {{ $t('ds-manager:' + 'ds-manager:create-button') }}
    </r-button>
    <ds-manager-file-import
      v-if="activeComponent === 'ds-manager-general'"
      @click-handler="changeComponent"
    />
  </div>
</template>

<script>
import dsManagerGeneral from './components/ds-manager-general'
import dsManagerMenu from './components/ds-manager-creation/ds-manager-menu'
import dsManagerEmptyDs from './components/ds-manager-creation/ds-manager-empty-ds'
import dsManagerEmptyModel from './components/ds-manager-creation/ds-manager-empty-model'
import dsManagerFromFile from './components/ds-manager-creation/ds-manager-from-file'
import dsManagerFromFileName from './components/ds-manager-creation/ds-manager-from-file-name'
import dsManagerRegList from './components/ds-manager-creation/ds-manager-registration-list'
import dsManagerEtranAghk from './components/ds-manager-creation/ds-manager-etran-aghk'
import dsManagerEtranAgpz from './components/ds-manager-creation/ds-manager-etran-agpz'
import dsManagerRetranslation from './components/ds-manager-creation/ds-manager-retranslation'
import dsManagerPermit from './components/ds-manager-creation/ds-manager-permit'
import dsManagerRzdAghk from './components/ds-manager-creation/ds-manager-rzd-aghk'
import dsManagerRzdAgpz from './components/ds-manager-creation/ds-manager-rzd-agpz'
import dsManagerEditor from './components/ds-manager-editor'
import dsManagerFileImport from './components/ds-manager-file-import.vue'
import dsManagerApplicationImport from './components/ds-manager-creation/ds-manager-application-import'

export default {
  components: {
    dsManagerGeneral,
    dsManagerMenu,
    dsManagerEmptyDs,
    dsManagerEmptyModel,
    dsManagerFromFile,
    dsManagerFromFileName,
    dsManagerRegList,
    dsManagerEditor,
    dsManagerEtranAghk,
    dsManagerEtranAgpz,
    dsManagerRetranslation,
    dsManagerFileImport,
    dsManagerRzdAghk,
    dsManagerRzdAgpz,
    dsManagerApplicationImport,
    dsManagerPermit
  },
  data() {
    return {
      activeComponent: '',
      previousComponent: ''
    }
  },
  created() {
    this.activeComponent = 'ds-manager-general'
  },
  methods: {
    changeComponent(i) {
      switch (i) {
        case 'ds-manger-general':
          this.previousComponent = ''
          break
        case 'ds-manager-menu':
        case 'ds-manager-editor':
          this.previousComponent = 'ds-manager-general'
          break
        case 'ds-manager-reg-list':
        case 'ds-manager-etran-aghk':
        case 'ds-manager-etran-agpz':
        case 'ds-manager-retranslation':
        case 'ds-manager-rzd-agpz':
        case 'ds-manager-rzd-aghk':
        case 'ds-manager-permit':
        case 'ds-manager-application-import':
          this.previousComponent = 'ds-manager-general'
          break
        default:
          this.previousComponent = 'ds-manager-menu'
          break
      }
      this.activeComponent = i
    }
  }
}
</script>

<style lang="scss">
.ds-manager {
  position: relative;
  .ds-manager__create-button {
    position: absolute;
    top: 36px;
    left: 0;
    padding: 5px 16px 7px !important;
    span {
      display: flex;
      align-items: center;
    }
  }
  .ds-manager__reg-list {
    position: absolute;
    top: 36px;
    right: 0;
  }
  .ds-manager__header {
    margin: 0;
  }
  .ds-manager__navigation {
    margin-bottom: 16px;
    width: 100%;
    &-item {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      cursor: pointer;
      &:hover {
        filter: brightness(1.15);
      }
      .r-icon-arrow {
        &:before {
          border-color: var(--accent_primary) !important;
        }
      }
    }
  }
}
.ds-manager__component {
  position: relative;
  &-title {
    height: 44px;
  }
  &-content {
    width: 500px;
    position: relative;
    height: 50vh;
    overflow: hidden;
    overflow-y: auto;
  }
  &-subtitle {
    padding-bottom: 8px;
  }
  &-row {
    margin-bottom: 12px;
    .r-col {
      padding: 0 8px;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
  .text-center {
    text-align: center;
  }
  .text-right {
    text-align: right;
  }
  &.general {
    .ds-manager__component {
      position: relative;
      &-title {
        height: 84px;
      }
    }
  }
}
</style>
