<template>
  <div class="account-security">
    <div class="account-security-password">
      <div class="user-info__title">
        <r-title type="title-2">
          {{ $t('account-security:' + 'account-security:password') }}
        </r-title>
      </div>
      <div class="user-info__block">
        <div class="user-info__block-row">
          <el-input
            v-model="oldPassword"
            type="password"
            placeholder="Старый пароль"
          />
        </div>
        <div class="user-info__block-row">
          <el-input
            v-model="newPassword"
            type="password"
            placeholder="Новый пароль"
          />
        </div>
        <div class="user-info__block-row">
          <el-input
            v-model="newPasswordRepeat"
            type="password"
            placeholder="Повторите новый пароль"
          />
        </div>
        <div class="user-info__block-row__controls">
          <el-tooltip
            :open-delay="1500"
            :content="`${$t('account-security:' + 'account-security:button:cancel')}`"
            placement="top"
          >
            <r-button
              simple
              :disabled="isCancelDisabled"
              @click="cancel"
            >
              {{ $t('account-security:' + 'account-security:button:cancel') }}
            </r-button>
          </el-tooltip>
          <el-tooltip
            :open-delay="1500"
            :content="`${$t('account-security:' + 'account-security:button:save')}`"
            placement="top"
          >
            <r-button
              type="primary"
              :disabled="isSaveDisabled"
              :loading="loading"
              @click="changePassword"
            >
              {{ $t('account-security:' + 'account-security:button:save') }}
            </r-button>
          </el-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { notifyFactory } from '@/utils'
export default {
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      newPasswordRepeat: '',
      loading: false
    }
  },
  computed: {
    isCancelDisabled() {
      return !(this.oldPassword || this.newPassword || this.newPasswordRepeat)
    },
    isSaveDisabled() {
      if (!this.oldPassword) return true
      if (!this.newPassword || !this.newPasswordRepeat) return true
      if (this.newPassword !== this.newPasswordRepeat) return true

      return false
    }
  },
  methods: {
    async changePassword() {
      const id = this.$store.state.auth.user.id

      try {
        this.loading = true
        const url = 'user'
        const data = { id, password: this.newPassword }
        await this.$store.dispatch('PUT_REQUEST', { url, data })
        this.cancel()
        const title = this.$t('account-security:' + 'account-security:edit-notify:title')
        const message = this.$t('account-security:' + 'account-security:edit-notify:text')
        this.$notify(notifyFactory('success', title, message))
        this.loading = false
      } catch (error) {
        console.warn(error)
        this.loading = false
        const title = this.$t('account-security:' + 'account-security:edit-notify:error-title')
        const message = this.$t('account-security:' + 'account-security:edit-notify:error-text')
        this.$notify(notifyFactory('error', title, message))
      }
    },
    cancel() {
      this.oldPassword = ''
      this.newPassword = ''
      this.newPasswordRepeat = ''
    }
  }
}
</script>

<style lang="scss">
.account-security {
  width: 520px;
}
</style>
