export const mappingConfigs = {
  default: {
    basic_widget: {
      items: {
        work: 'work',
        error: 'error'
      },
      total: 'count'
    }
  },
  'api/py/iss/stat': {
    basic_widget: {
      items: {
        work: 'work',
        error: 'error'
      },
      total: 'count'
    }
  },
  'api/py/iss/cameras': {
    object_card: {
      main: {
        name: 'name',
        server: 'server'
      },
      camera_id: 'id'
    }
  },
  'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=stat': {
    basic_widget: {
      items: {
        'Иванов И.И.': 'Иванов И.И.',
        'Какой-то подрядчик': 'Какой-то подрядчик',
        Неизвестные: 'Неизвестные'
      },
      total: 'count'
    }
  },
  'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=categories': {
    basic_widget: {
      items: {
        'Внутренние ТС': 'Внутренние ТС',
        'ТС с ТМЦ': 'ТС с ТМЦ',
        'Без категории': ''
      },
      childrens: {
        'Внутренние ТС': {
          Автобусы: 'Автобусы',
          Легковые: 'Легковые',
          Грузовые: 'Грузовые',
          'Дорожно-строительная техника': 'Дорожно-строительная техника',
          'Подъёмные сооружения': 'Подъёмные сооружения'
        }
      },
      total: 'count_unique'
    }
  },
  'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=status': {
    basic_widget: {
      items: {
        'На связи': 'online',
        'Нет связи (отметка более 5 мин назад)': 'with_coords_halfline',
        'На связи (нет координат более 30 мин)': 'with_coords_offline',
        'Нет данных': 'nodata',
        'Нет геометрии': '',
        'Есть фиксации, но нет данных': 'fix_no_data'
      },
      total: 'count'
    }
  },
  'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=project': {
    basic_widget: {
      items: {
      },
      total: 'count_unique'
    }
  },
  'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=cargo_types': {
    basic_widget: {
      items: {
        'Грузовые ТС': 'Грузовые',
        'ЖД-вагоны': 'ЖД',
        Баржи: 'Баржи'
      },
      total: 'count'
    }
  },
  'ZdCarriage/d771ab92-6787-4aa6-ae65-e64d687f5234?format=widget&type=carriages': {
    basic_widget: {
      items: {
        'На сортировке': 'На сортировке',
        'Прибыл на завод': 'Прибыл на завод',
        'На разгрузке': 'На разгрузке',
        Разгружен: 'Разгружен'
      },
      total: 'count'
    }
  },
  'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=pot_status': {
    basic_widget: {
      items: {
        'Перемещается с ТМЦ': 'Перемещается с ТМЦ',
        'Будет в течение 48 часов': 'Будет в течение 48 часов',
        'Прибыл на ПОТ': 'Прибыл на ПОТ',
        'Ожидает разрешение': 'Ожидает разрешение',
        'Разрешён въезд': 'Разрешён въезд',
        'Распечатал схему': 'Распечатал схему',
        'Выехал с ПОТ': 'Выехал с ПОТ',
        'На территории площадки': 'На территории площадки',
        'Покинул территорию площадки': 'Покинул территорию площадки'
      },
      total: 'count'
    }
  },
  'vehicle/f09e59fb-ee58-482c-8b0f-9ac520cf2099?format=widget&type=app_status': {
    basic_widget: {
      items: {
        'Новая заявка': 'Новая заявка',
        'Назначен осмотр': 'Назначен осмотр',
        'Проведён осмотр, нарушений нет': 'Проведён осмотр, нарушений нет',
        'Проведён осмотр, допущен с замечаниями': 'Проведён осмотр, допущен с замечаниями',
        'Заявка отклонена по формальным признакам': 'Заявка отклонена по формальным признакам',
        'Проведён осмотр, есть нарушения, назначен повторный осмотр': 'Проведён осмотр, есть нарушения, назначен повторный осмотр',
        'Проведён осмотр, есть нарушения, заявка отклонена': 'Проведён осмотр, есть нарушения, заявка отклонена'
      },
      total: 'count'
    }
  },
  event_stat_class: {
    basic_widget: {
      items: {
        'Ремонтные работы дорожной сети': 'Ремонтные работы',
        ДТП: 'ДТП',
        'Недостатки дорожной сети': 'Недостатки'
      },
      total: 'count'
    }
  },
  event_stat_speed: {
    basic_widget: {
      items: {
        'Без перекрытия': 'Без перекрытия',
        Частичное: 'Частичное',
        Полное: 'Полное'
      },
      total: 'count'
    }
  },
  event_stat_time: {
    basic_widget: {
      items: {
        Прошедшие: 'Прошедшие',
        Актуальные: 'Актуальные',
        Предстоящие: 'Предстоящие',
        Неопределенные: 'Неопределенные'
      },
      total: 'count'
    }
  },
  'bdd_violation/1b013139-c2d9-4641-a549-b162fce4eb57?format=widget&type=violations': {
    basic_widget: {
      items: {
        'Нарушение при управлении ТС задним ходом': 'Нарушение при управлении ТС задним ходом',
        'Неправильно оформлена документация': 'Неправильно оформлена документация (путевой лист и т.д.)',
        'Неиспользование ремня безопасности': 'Неиспользование ремня безопасности',
        'Отсутствие логотипа': 'Отсутствие логотипа',
        'Превышение скорости': 'Превышение скорости',
        'Превышение скорости по GPS': 'Превышение скорости по GPS',
        'Нарушение правил перевозок груза': 'Нарушение правил перевозки груза',
        'Техническая неисправность': 'Техническая неисправность',
        'Несоответствие системы видеофиксации': 'Несоответствие системы видеофиксации',
        'Нарушение правил пользования внешних световых приборов': 'Правила пользования внешних световых приборов',
        'Нарушение требований дорожных знаков/разметки': 'Нарушение требований дорожных знаков/разметки',
        'Нарушение пешеходами': 'Нарушение пешеходами',
        'Алкогольное опьянение': 'Алкогольное опьянение',
        'Прочие нарушения': 'Прочие нарушения',
        'Нарушение УДС': 'Нарушение УДС'
      },
      total: 'count'
    }
  },
  event_stat: {
    basic_widget: {
      items: {
        accident: 'ДТП',
        road_works: 'Ремонтные работы',
        events: 'Мероприятия'
      },
      total: 'count'
    }
  },
  vehicles: {
    object_card: {
      main: {
        'Рег. номер': 'reg_number',
        Модель: 'model',
        vrc_sn: 'vrc_sn',
        vrc_full_name: 'vrc_full_name',
        bsmts_model: 'bsmts_model'
      }
    }
  },
  'network.links': {
    object_card: {
      main: {
        'Разрешённая скорость': 'speed',
        Наименование: 'name',
        Номер: 'no',
        'Количество полос': 'numlanes'
      }
    }
  },
  events: {
    object_card: {
      main: {
        event_class: 'event_class_id',
        address: 'address',
        closed_line_count: 'closed_line_count',
        customer: 'customer',
        performer: 'performer',
        phone_number: 'phone_number',
        start_time: 'start_time',
        end_time: 'end_time'
      }
    }
  },
  default_status: {
    basic_widget: {
      items: {
        work: 'work',
        error: 'error'
      },
      total: 'count'
    }
  },
  default_category: {
    basic_widget: {
      items: {
        contractor_1: 'contractor_1',
        contractor_2: 'contractor_2',
        contractor_3: 'contractor_3',
        contractor_4: 'contractor_4'
      },
      total: 'count'
    }
  },
  monitoring: {
    basic_widget: {
      items: {
        zd: 'zd',
        ship: 'ship',
        avto: 'avto'
      },
      total: 'count'
    }
  },
  violations: {
    basic_widget: {
      items: {
        speed: 'speed',
        markup: 'markup'
      },
      total: 'count'
    }
  },
  video_camera_kpp: {
    object_card: {
      main: {
        'Название ': 'name'
      },
      id: 'cam_id'
    }
  },
  video_camera_road: {
    object_card: {
      main: {
        'Название ': 'name'
      },
      id: 'cam_id',
      stream_url: 'stream_url'
    }
  }
}

export const dataMapping = (data, layer_id, type) => {
  if (!data) return {}

  const mappingConfig = mappingConfigs[layer_id]
    ? mappingConfigs[layer_id][type]
    : mappingConfigs.default[type]
  const newData = {}

  Object.keys(mappingConfig).forEach(e => {
    if (e === 'childrens') {
      if (mappingConfig[e]) {
        Object.entries(mappingConfig[e]).forEach(([k, v]) => {
          newData.items.forEach(n => {
            if (n.type === k) {
              if (!n.childrens) {
                n.childrens = []
              }
              Object.entries(v).forEach(([vk, vv]) => {
                n.childrens.push({ type: vk, value: data[vv] || 0 })
              })
            }
          })
        })
      }
    } else if (e !== 'items' && e !== 'main' && e !== 'cameras') {
      newData[e] = data[mappingConfig[e]]
    } else {
      if (!newData[e]) newData[e] = []
      if (Object.keys(mappingConfig[e]).length === 0) {
        const { count_unique, count, ...keys } = data
        Object.keys(keys).forEach(n => {
          newData[e].push({ type: n, value: data[n] || 0 })
        })
      } else {
        Object.keys(mappingConfig[e]).forEach(n => {
          newData[e].push({ type: n, value: data[mappingConfig[e][n]] || 0 })
        })
      }
    }
  })

  return newData
}
