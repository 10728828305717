<template>
  <div class="tasks-modal-list-item-title">
    <el-tooltip
      :open-delay="500"
      effect="dark"
      :content="getTaskHint(task.worker_class)"
      placement="top"
    >
      <div :class="getTaskImage(task.worker_class)" />
    </el-tooltip>
    <div class="tasks-modal-list-item-block">
      <div class="tasks-modal-list-item-block__title">
        <r-text
          type="caption"
          style="margin:0 8px 0 0"
        >
          {{ $t('tasks-modal-list-item-title:' + 'task:type') }}
        </r-text>
        <r-text>
          {{ $t('tasks-modal-list-item-title:' + task.worker_class) }}
        </r-text>
      </div>
      <div class="tasks-modal-list-item-block__title">
        <r-text
          type="caption"
          style="margin:0 8px 0 0"
        >
          {{ $t('tasks-modal-list-item-title:' + 'task:start_time') }}
        </r-text>
        <r-text>{{ getDate(task.start_time) }}</r-text>
      </div>
    </div>
    <tasks-item-status :task="task" />
  </div>
</template>

<script>
import { getTaskImage, getTaskHint } from '../../configs'

import tasksItemStatus from '../tasks-list/tasks-item-status'

export default {
  components: { tasksItemStatus },
  props: {
    task: {
      type: Object,
      default: () => null,
      required: true
    }
  },
  methods: {
    getDate(date) {
      return this.$ritmDate.toFormat(date, 'HH:mm • DD.MM.YYYY')
    },
    getTaskImage,
    getTaskHint
  }
}
</script>

<style lang="scss" scoped>
.tasks-modal-list-item {
  &-title {
    display: flex;
    align-items: center;
  }
  &-block {
    width: 320px;
    overflow: hidden;
    white-space: nowrap;
    &__title {
      display: flex;
      align-items: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin-bottom: 0;
      line-height: 1.6;
      .r-text {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
</style>
