<template>
  <el-date-picker
    v-model="innerValue"
    v-mask="maskValue"
    v-bind="mergedProps"
    @change="handleChange"
  />
</template>

<script>
import { ritmDate } from '@/utils'

export default {
  props: {
    value: {
      type: [String, Date, Array],
      default: null
    },
    format: {
      type: String,
      default: 'yyyy-MM-dd'
    },
    placeholder: {
      type: String,
      default: 'Select date'
    },
    type: {
      type: String,
      default: 'date' // e.g., 'date', 'daterange', 'datetime'
    },
    pickerOptions: {
      type: Object,
      default: () => ({})
    },
    otherProps: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      innerValue: this.value ? ritmDate.date(this.value) : null
    }
  },
  computed: {
    maskValue() {
      return this.type === 'datetime' ? '##.##.#### ##:##' : '##.##.####'
    },
    mergedProps() {
      return {
        ...this.$props.otherProps,
        type: this.type,
        format: this.format,
        placeholder: this.placeholder,
        pickerOptions: this.pickerOptions
      }
    }
  },
  watch: {
    value(newVal) {
      this.innerValue = newVal ? ritmDate.date(newVal) : null
    }
  },
  methods: {
    handleChange(newValue) {
      newValue = newValue ? ritmDate.date(newValue) : null

      this.$emit('input', newValue)
      this.$emit('change', newValue)
    }
  }
}
</script>

<style lang="scss" scoped>
.date-picker-wrapper {
  display: grid;
  overflow: hidden
}
</style>
