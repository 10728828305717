<template>
  <div class="string-filter">
    <div class="popper-filter__content">
      <el-input
        v-model="initValue"
        class="r-input"
        type="string"
        clearable
      />
    </div>

    <div class="string-filter__controls popper-filter__controls">
      <r-button
        type="primary"
        @click="prepareData"
      >
        {{ $t('string-filter:' + `accept`) }}
      </r-button>
      <r-button
        simple
        :disabled="isResetDisabled"
        @click="resetFilter"
      >
        {{ $t('string-filter:' + `reset`) }}
      </r-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    filterValue: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      initValue: this.filterValue
    }
  },
  computed: {
    isResetDisabled() {
      return !this.initValue
    }
  },
  methods: {
    resetFilter(resetAll) {
      this.initValue = ''
      this.prepareData(resetAll)
    },
    prepareData(resetAll) {
      const { initValue, id } = this

      const data = {
        type: 'string-filter',
        prop: initValue,
        id: id,
        active: !!initValue
      }

      if (!resetAll) {
        this.$emit('load', data)
      } else {
        this.$emit('reset-filter', data)
      }
    }
  }
}
</script>

<style lang="scss">
.string-filter {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
  align-items: center;
  justify-content: center;

  &__controls {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.5rem;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
