<template>
  <sc-card
    v-if="type === `widget`"
    :title="title"
    second-footer
  >
    <div class="sc-chart-widget">
      <div class="sc-chart-widget__controls-title">
        <r-text type="caption">
          {{ $t('chart-widget:' + 'period') }}
        </r-text>
      </div>
      <ul class="sc-chart-widget__controls">
        <li
          v-for="item in controlsItem"
          :key="item"
          :class="[
            `sc-chart-widget__controls-item`,
            { active: dataStep === item }
          ]"
          @click="changeDataStep(item)"
        >
          <r-text type="caption">
            {{ $t('chart-widget:' + item) }}
          </r-text>
        </li>
      </ul>
    </div>
    <sc-charts-builder
      v-if="chartData"
      slot="second-footer"
      :chart-data="chartData"
      :title="title"
      :type="chartType"
      :change-chart-type="changeChartType"
    />
  </sc-card>
  <scChartModalWindow
    v-else
    v-bind="$props"
  />
</template>

<script>
import scCard from '../../layout/card'
import scChartsBuilder from './components/charts/charts'
import scChartModalWindow from './components/modal-window/modal-window'
import { prepareChartData, historyRequest } from './components/charts/helpers'

const controlsItem = ['hour', 'day', 'week', 'month']
const chartTypes = ['type-1', 'type-2', 'type-3']

export default {
  components: { scCard, scChartsBuilder, scChartModalWindow },
  props: {
    type: {
      type: String,
      default: 'widget'
    },
    url: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      dataStep: 'day',
      chartType: 'type-1',
      chartData: null,
      chartTypes,
      controlsItem
    }
  },
  computed: {
    currentHistoryConfig() {
      const to = this.$ritmDate.toIso(this.$ritmDate.date())
      const from =
        this.dataStep === 'hour'
          ? this.$rDate.calc(2, this.dataStep, this.$ritmDate.date()).format('iso')
          : this.$rDate.calc(1, this.dataStep, this.$ritmDate.date()).format('iso')
      let step
      switch (this.dataStep) {
        case 'hour':
          step = 15
          break
        case 'day':
          step = 120
          break
        case 'week':
          step = 540
          break
        case 'month':
          step = 1600
          break
        default:
          break
      }

      return { from, to, step }
    }
  },
  watch: {
    currentHistoryConfig() {
      this.getChartData()
    }
  },
  created() {
    this.getChartData()
  },
  methods: {
    async getChartData() {
      const data = await historyRequest(this, { url: this.url })
      this.chartData = prepareChartData(data, this.title)
    },
    changeChartType(e) {
      this.chartType = e
    },
    changeDataStep(val) {
      if (val === this.dataStep) return

      this.dataStep = val
    }
  }
}
</script>

<style lang="scss">
.sc-chart-widget {
  min-width: 604px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  &__controls-title {
    margin-bottom: 4px;
  }
  &__controls {
    width: 100%;
    display: flex;
    margin-top: -12px;
    margin-bottom: -12px;
    &-item {
      width: 64px;
      height: 32px;
      border: 1px solid;
      display: flex;
      align-items: center;
      justify-content: center;
      border-color: var(--dividers_low_contrast) !important;
      border-right: none;
      cursor: pointer;
      transition: all 0.15s ease;
      &:hover {
        background-color: var(--accent_hover);
        .r-text.caption-2 {
          color: var(--white_white) !important;
        }
      }
      &:active {
        background-color: var(--accent_active);
        .r-text.caption-2 {
          color: var(--white_white) !important;
        }
      }
      &:first-child {
        border-top-left-radius: var(--border-radius);
        border-bottom-left-radius: var(--border-radius);
      }
      &:last-child {
        border-right: 1px solid;
        border-top-right-radius: var(--border-radius);
        border-bottom-right-radius: var(--border-radius);
      }
      &.active {
        cursor: default;
        background-color: var(--accent_primary);
        border-color: var(--accent_primary) !important;
        .r-text.caption-2 {
          color: var(--white_white) !important;
        }
      }
    }
  }
}
</style>
