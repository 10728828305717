<template>
  <sc-modal-window
    :title="title"
    :footer="true"
  >
    <div class="sc-basic-modal-window">
      <el-tabs
        v-model="activeTab"
        class="r-tabs"
      >
        <el-tab-pane
          v-if="requestUrl"
          name="chart"
        >
          <r-text
            slot="label"
            type="caption"
          >
            {{ $t('basic-modal-window:' + "chart") }}
          </r-text>
          <div :class="[`sc-basic-widget sc-chart`]">
            <sc-chart-widget
              :url="requestUrl"
              :title="title"
              type="modal-widow"
            />
          </div>
        </el-tab-pane>
        <el-tab-pane name="statistic">
          <r-text
            slot="label"
            type="caption"
          >
            {{ $t('basic-modal-window:' + "statistic") }}
          </r-text>
          <default-list
            :item-list="widgetData.items"
            :colors="colors"
            :icons="icons"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
    <div
      slot="footer"
      class="sc-basic-modal-window__footer"
    >
      <div class="sc-basic-modal-window__footer-block">
        <r-text
          :size="16"
          type="caption"
        >
          {{ $t('basic-modal-window:' + "total") }}
        </r-text>
      </div>
      <div class="sc-basic-modal-window__footer-block">
        <r-text
          :size="22"
          style="font-weight:600"
        >
          {{
            widgetData.total
          }}
        </r-text>
      </div>
    </div>
  </sc-modal-window>
</template>

<script>
import scModalWindow from '../../layout/modal-window'
import defaultList from './default-list'
import scChartWidget from '../../widgets/chart-widget/chart-widget'

import { dataMapping, widgetStyleConfig } from '../../../configs'

export default {
  components: { scModalWindow, defaultList, scChartWidget },
  data() {
    return {
      activeTab: 'chart',
      chartType: 'type-1'
    }
  },
  computed: {
    title() {
      return this.$store.state.situationCenter.modalWindow.layer_id
    },
    instanceConfigs() {
      return this.$store.state.situationCenter.config
    },
    requestUrl() {
      const layerConfig = this.instanceConfigs.layout.bottom_block.find(
        e => e.props.layer_id === this.title
      )
      if (!layerConfig) return null
      const url = layerConfig.props.chartRequest
        ? layerConfig.props.chartRequest.url
        : null
      return url
    },
    widgetData() {
      const data = this.$store.state.situationCenter.layersData.widget[
        this.title
      ]

      return data ? dataMapping(data, this.title, 'basic_widget') : {}
    },
    chartData() {
      return this.widgetData.items.map(e => ({
        value: e.value,
        name: this.$t('basic-modal-window:' + e.type)
      }))
    },
    colors() {
      return widgetStyleConfig[this.title]
        ? widgetStyleConfig[this.title].colors
        : {}
    },
    icons() {
      return widgetStyleConfig[this.title]
        ? widgetStyleConfig[this.title].icons
        : {}
    },
    chartColors() {
      return Object.keys(this.colors).map(e => this.colors[e])
    }
  },
  methods: {
    changeChartType(e) {
      this.chartType = e
    }
  }
}
</script>

<style lang="scss">
.sc-basic-modal-window {
  width: 640px;
  height: 380px;
  display: flex;
  &__footer {
    width: calc(100% - 40px);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
  }
  .el-tabs {
    &__content {
      position: relative;
      height: 315px;
      overflow: hidden;
      overflow-y: auto;
      margin: 0 -12px;
      padding: 0 12px;
    }
    &__item {
      padding: 0;
      .r-text {
        padding: 0 20px;
      }
      &.is-active {
        .r-text.caption-2 {
          color: var(--accent_primary) !important;
        }
      }
    }
  }
  .sc-chart {
    width: 640px;
    height: 315px;
    &__controllers {
      top: 0;
      left: auto;
      right: 0;
    }
    &__legend {
      height: 48px;
    }
  }
}
</style>
