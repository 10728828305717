<template>
  <div class="comments__comment-item">
    <div class="comments__comment-header">
      <r-text
        type="caption"
        color-type="subhead"
      >
        {{ comment.username }}
      </r-text>
      <r-text type="caption">
        {{ $ritmDate.toFormat(comment.created_at, 'DD.MM.YYYY • HH:mm') }}
      </r-text>
    </div>
    <div class="comments__comment-subject">
      <r-text>
        {{ comment.body }}
      </r-text>
      <el-tooltip
        :open-delay="1000"
        :content="`${$t('comment-item:' + 'comments:tooltips:btn-delete')}`"
        placement="top"
      >
        <span
          class="files__file-controls-item"
          @click="deleteComment(comment, $event)"
        >
          <r-icon
            name="trash"
            :size="16"
          />
        </span>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
import { errorParser } from '@/utils'

export default {
  props: {
    comment: {
      type: Object,
      default: null
    },
    source_id: {
      type: String,
      default: null
    }
  },
  methods: {
    async deleteComment(comment, e) {
      this.$emit('loading', ['comments', true])
      e.stopPropagation()
      e.preventDefault()

      const { id } = comment
      const url = `objectComments/${this.source_id}/${id}`

      try {
        await this.$store.dispatch('DELETE_REQUEST', { url })
        this.$emit('load')
      } catch (error) {
        errorParser.call(this, error)
      }
    }
  }
}
</script>

<style lang="scss">
.comments {
  &__comment-item {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    width: 100%;
    border-radius: var(--border-radius);
    background-color: var(--bg_containers);
  }

  &__comment-header {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
  }

  &__comment-subject {
    margin-top: 0.25rem;
    display: flex;
    justify-content: space-between;
  }
}
</style>
