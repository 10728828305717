<template>
  <div
    v-loading="loading"
    :class="['notification-item', notification.status ? 'active' : '']"
  >
    <div class="notification-item__header">
      <span class="notification-item__creating-info">
        {{
          `Создано: ${notification.updated_user.name ||
            notification.created_user.name}, ${$ritmDate.toFormat(notification.updated_at, 'DD.MM.YYYY • HH:mm')}`
        }}
      </span>
      <div class="notification-item__controls">
        <r-delete-button
          mini
          @delete="deleteNotification"
        />

        <r-button
          icon="edit"
          @click="edit()"
        />

        <el-switch
          v-model="notification.status"
          :width="32"
          class="r-switch"
          active-text="Активно"
          :disabled="loading"
          @change="toggleStatus"
        />
      </div>
    </div>
    <div class="notification-item__body">
      <r-title
        class="notification-item__title"
        type="subtitle-1"
      >
        {{ notification.subject }}
      </r-title>
      <r-text
        v-if="notification.open_at"
        class="notification-item__start"
      >
        {{ `Начало: ${$ritmDate.toFormat(notification.open_at, 'DD.MM.YYYY • HH:mm')}` }}
      </r-text>
      <r-text
        v-if="notification.close_at"
        class="notification-item__finish"
      >
        {{ `Окончание: ${$ritmDate.toFormat(notification.close_at, 'DD.MM.YYYY • HH:mm')}` }}
      </r-text>
      <p
        lang="ru"
        class="notification-item__text"
      >
        {{ notification.text }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    notification: {
      type: Object,
      required: true
    },
    source_id: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: false
    }
  },
  methods: {
    async toggleStatus(v) {
      this.loading = true

      try {
        const { status } = await this.$store.dispatch('POST_REQUEST', {
          url: `objectInfo/${this.source_id}`,
          data: {
            id: this.notification.id,
            status: v
          }
        })
        if (status === 200) this.$emit('update')
      } catch (e) {
        console.warn(e)
      } finally {
        this.loading = false
      }
    },
    async deleteNotification() {
      this.loading = true
      try {
        await this.$store.dispatch('DELETE_REQUEST', {
          url: `objectInfo/${this.source_id}?id=${this.notification.id}`
        })

        this.$emit('update')
      } catch (e) {
        console.warn(e)
      } finally {
        this.loading = false
      }
    },
    edit() {
      this.$emit('edit', this.notification)
    }
  }
}
</script>

<style lang="scss">
.notification-item {
  border-radius: var(--border-radius);
  padding: 0.5rem;
  display: grid;
  align-items: center;
  grid-auto-flow: row;
  grid-gap: 0.25rem;
  transition: 0.15s ease;
  background-color: var(--bg_containers);

  &.active {
    background-color: var(--accent_selected);
  }

  &:hover {
    .el-switch__label.is-active,
    .r-delete-button,
    .r-button {
      opacity: 1;
    }
  }

  &__creating-info {
    color: var(--text_secondary);
  }

  &__text {
    hyphens: auto;
    margin: 0;
    color: var(--text_primary);
    white-space: pre-line;
  }

  &__controls {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 1rem;
    align-items: center;
    justify-content: end;

    .r-button,
    .r-delete-button {
      margin: 0 !important;
      opacity: 0;
    }

    .el-switch__label {
      color: var(--text_primary) !important;
      font-weight: 400;
    }
  }

  &__header {
    display: grid;
    grid-auto-flow: column;
    grid-gap: 0.25rem;
    border-bottom: 1px solid var(--dividers_low_contrast);
    padding-bottom: 0.5rem;
    justify-content: space-between;
    align-items: center;
  }

  &__body {
    display: grid;
    grid-auto-flow: row;
    grid-gap: 0.5rem;
  }
}
</style>
