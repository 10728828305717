<template>
  <div class="sc-status-widget__footer">
    <div class="sc-status-widget__footer-block">
      <r-text>
        {{ $t('footer:' + "sc-status-widget:total") }}
      </r-text>
    </div>
    <div class="sc-status-widget__footer-block">
      <r-title>{{ widgetData.total }}</r-title>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    widgetData: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.sc-status-widget__footer {
  width: calc(100% - 20px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;
}
</style>
