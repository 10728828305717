<template>
  <div
    v-loading="loading"
    class="preventive-measures"
  >
    <measures-header
      :title="$t('bdd-preventive-measures:' + 'preventive-measures:title')"
      :button-text="$t('bdd-preventive-measures:' + 'preventive-measures:add-button')"
      :uri="uri"
      @add="addMeasure"
    />
    <r-filter-set
      class="preventive-measures__filters"
      :filters="filters"
      @reset-all="resetFilters"
      @load="updateFilters"
    />
    <div class="preventive-measures__content">
      <r-table-list
        v-if="measures.length"
        ref="table"
        :sort-by="statuses"
        :include-columns="columns"
        :fields-names="fields"
        bordered
        :paginator="measures.length > 10"
        num-col
        :data="tableData"
        @click-handler="openModal"
      />
    </div>
  </div>
</template>

<script>
import measuresHeader from './components/header/header'
import { related, fields, columns, filters } from './configs'
import { buildUrl, buildExportUri, filtersEncoder } from './helpers'

export default {
  components: { measuresHeader },
  data() {
    return {
      filters: [],
      fields,
      columns,
      measures: [],
      loading: false,
      uri: `objectInfo/${this.source_id}`,
      source_id: 'b7ef7b46-8a25-44f0-93da-ee0657429738',
      related: { ...related }
    }
  },
  computed: {
    statuses() {
      return {
        propTitle: 'status_id',
        values: this.related.status.data.map((s, i) => ({
          value: s.name,
          order: i + 1,
          title: s.name
        }))
      }
    },
    tableData() {
      return this.measures.map(m => {
        const related = [
          'status_id',
          'type_id',
          'group_id',
          'subgroup_id',
          'contractor_id'
        ]
        return {
          ...m,
          created_at: this.$ritmDate.toFormat(m.created_at, 'DD.MM.YYYY • HH:mm'),
          date_until: this.$ritmDate.toFormat(m.date_until, 'DD.MM.YYYY • HH:mm'),
          ...this.formatRelated(related, m)
        }
      })
    },
    updatePreventive() {
      return this.$store.state.bdd.updatePreventive
    }
  },
  watch: {
    updatePreventive() {
      this.loadMeasures()
    }
  },
  mounted() {
    this.setFilters()
    this.loadMeasures()
    this.loadRelated()
  },
  methods: {
    setFilters() {
      const userFilters = this.$store.state.bdd.filters?.preventive
      const initFilters = filters()

      this.filters = initFilters?.map(f => {
        if (!userFilters?.length) return f

        const userItem = userFilters?.find(uf => uf.id === f.id)

        return userItem || f
      })
    },
    formatRelated(related, m) {
      const obj = {}
      related.forEach(r => {
        obj[r] = this.getValueByRelated(r, m)
      })
      return obj
    },
    async updateFilters(filter) {
      const target = this.filters.find(f => f.id === filter.id)
      const item = { ...target, ...filter }

      this.filters[this.filters.indexOf(target)] = item

      this.$store.commit('BDD_SET_FIELD', {
        field: 'filters.preventive',
        value: this.filters?.filter(f => f.active)
      })

      try {
        await this.$store.dispatch('SAVE_MAIN_USER_CONFIG')
      } catch (e) {
        throw new Error(e)
      }

      this.loadMeasures()
    },
    async resetFilters() {
      this.$store.commit('BDD_SET_FIELD', {
        field: 'filters.preventive',
        value: this.filters?.filter(f => f.active)
      })

      try {
        await this.$store.dispatch('SAVE_MAIN_USER_CONFIG')
      } catch (e) {
        throw new Error(e)
      }

      this.loadMeasures()
    },
    async loadMeasures() {
      this.loading = true
      try {
        const filters = filtersEncoder(this.filters)
        const url = buildUrl(this.source_id, filters)
        this.uri = buildExportUri(this.source_id, filters)

        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url
        })

        this.measures = Object.values(data)
      } catch (e) {
        console.warn(e)
      } finally {
        this.loading = false
        this.$store.commit('BDD_SET_FIELD', {
          field: 'updatePreventive',
          value: false
        })
      }
    },
    async loadRelated() {
      this.loading = true

      for (const r in this.related) {
        try {
          const config = JSON.stringify({ only: ['name', 'id'] })
          const url = `objectInfo/${this.related[r].id}?config=${config}`

          const { data } = await this.$store.dispatch('GET_REQUEST', {
            url
          })

          this.related[r].data = Object.values(data)

          this.filters = this.filters.map(f => {
            let filter = f
            if (f.id === `${r}_id`) {
              filter = {
                ...f,
                prop: this.related[r].data.map(d => {
                  const fValue = !!f?.prop?.find(fi => fi.id === d.id)?.value

                  return {
                    ...d,
                    value: fValue
                  }
                })
              }
            }
            return {
              ...filter
            }
          })
        } catch (e) {
          console.warn(e)
        } finally {
          this.loading = false
        }
      }
    },
    getValueByRelated(field, measure) {
      return (
        this.related[field.replace('_id', '')].data.find(
          d => d.id === measure[field]
        )?.name || '—'
      )
    },
    async addMeasure() {
      this.openModal()
    },
    openModal(row) {
      const { source_id, related, measures } = this
      const newMeasure = {
        assignment: null,
        contractor_id: null,
        date_until: null,
        description: null,
        group_id: null,
        status_id: null,
        subgroup_id: null,
        created_at: null,
        type_id: null
      }
      const current = row ? measures.find(e => e.id === row.id) : newMeasure

      const value = {
        ...current,
        source_id,
        related,
        new: !row
      }

      this.$store.commit('BDD_SET_FIELD', {
        field: 'preventive',
        value
      })
      this.$store.commit('OPEN_MODAL_WINDOW', 'bdd-preventive-modal')
    }
  }
}
</script>

<style lang="scss">
.preventive-measures {
  display: grid;
  grid-auto-flow: row;
  align-items: start;
  width: 100%;
  align-content: start;
  height: 100%;

  &__filters {
    border-bottom: 1px solid var(--dividers_low_contrast);
  }

  &-table {
    &__action {
      &-cell {
        display: grid;
        grid-auto-flow: column;
        grid-gap: 1rem;
        justify-content: start;
      }

      &-button {
        border-radius: var(--border-radius);
        border: none;
        background-color: transparent;
        display: grid;
        grid-gap: 0.25rem;
        grid-auto-flow: column;
        cursor: pointer;

        &:hover {
          background-color: var(--accent_hover);
        }
      }
    }
  }

  &__content {
    display: grid;
    grid-template-rows: 1fr;
    overflow: hidden;
    height: 100%;
    align-items: center;
    justify-content: stretch;

    .r-title {
      justify-content: center;
      width: 100%;
      padding: 1rem;
      text-align: center;
    }
  }
}
</style>
