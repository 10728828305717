<template>
  <div
    :class="[`railway-control__list-item ${getColorType()}`]"
    :draggable="isDraggable()"
    @click="openCard(car.id)"
    @mousedown="clickHandler(car.id)"
  >
    <div class="railway-control__list-item__col">
      <railway-control-list-item-status :car-status="car.status_id || ''" />
      <r-title
        type="title-2"
        color-type="primary"
      >
        {{ `№ ${car.number}` }}
      </r-title>
      <r-text>
        {{ car.cargo }}
      </r-text>
    </div>
    <div
      v-if="car.way_no"
      class="railway-control__list-item__col"
    >
      <r-title type="title-2">
        <span class="el-icon-right" />
        {{ `${car.way_no}` }}
      </r-title>
    </div>
    <div
      v-else
      class="railway-control__list-item__col railway-control__list-item__col--planned"
    >
      <el-dropdown
        class="r-dropdown "
        placement="bottom-start"
        trigger="click"
        @command="changePlannedWay"
      >
        <span
          class="el-dropdown-link"
          style="cursor: pointer"
        >
          <r-icon
            name="chevron-down"
            :size="24"
            type="additional"
          />
        </span>
        <el-dropdown-menu
          slot="dropdown"
          class="r-dropdown-menu railway-dropdown"
        >
          <r-title type="subtitle-2">
            {{ `Запланировать на путь` }}
          </r-title>
          <el-dropdown-item
            v-for="way in wayTypes"
            :key="way.id"
            :command="way.way_no"
            :disabled="isDisabledWay(way.way_no)"
          >
            <div
              :class="[
                'r-dropdown-menu__item-wrapper',
                { active: way.way_no === car.planned_way_no }
              ]"
            >
              <r-text>{{ `${$t('railway-control-list-item:' + 'way')} ${way.way_no}` }}</r-text>
            </div>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <r-title
        v-if="car.planned_way_no"
        type="title-2"
      >
        {{ `${car.planned_way_no}` }}
      </r-title>
    </div>
  </div>
</template>

<script>
import railwayControlListItemStatus from './railway-control-list-item-status'
import { getStatusNameById, statusDecryption } from '../../configs'
import { railwaysLayout } from '../railway-control-content/configs'

export default {
  components: { railwayControlListItemStatus },
  props: {
    car: {
      type: Object,
      default: () => null,
      required: true
    }
  },
  data() {
    return {
      wayTypes: [],
      railwaysLayout
    }
  },
  computed: {
    carsList() {
      return this.$store.state.railwayControl.carsList
    },
    dateFilter() {
      return this.$store.state.railwayControl.dateFilter || null
    }
  },
  created() {
    this.wayTypes = Object.values(this.railwaysLayout)
  },
  methods: {
    isDraggable() {
      const { $ritmDate, dateFilter } = this

      return (
        $ritmDate.toFormat(dateFilter, 'DD.MM.YY') ===
        $ritmDate.toFormat(null, 'DD.MM.YY')
      )
    },
    clickHandler(id) {
      this.$emit('click-handler', id)
    },
    getColorType() {
      const carStatusName =
        getStatusNameById(this, this.car.status_id) || 'default'

      return statusDecryption[carStatusName]?.color
    },
    changePlannedWay(e) {
      this.$store.commit('RAILWAY_CONTROL_UPDATE_CAR_BY_ID', {
        id: this.car.id,
        field: 'planned_way_no',
        value: e
      })
      this.$store.commit('RAILWAY_CONTROL_OBSERV_CHANGES_BY_ID', {
        id: this.car.id
      })
    },
    isDisabledWay() {
      return false
    },
    openCard(id) {
      this.$store.commit('RAILWAY_CONTROL_SET_CARD_ID', id)
    }
  }
}
</script>

<style lang="scss">
.railway-control__list-item {
  position: relative;
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: center;
  background-color: var(--button_secondary_bg);
  border-radius: var(--border-radius);
  margin-bottom: 8px;
  cursor: grab;
  &__col {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    &--planned {
      height: 100%;
      justify-content: center;
      align-items: center;
      border-left: 1px solid;
      border-color: var(--dividers_low_contrast);
    }
  }
  &:last-child {
    margin-bottom: 0;
  }
  &.accent-primary {
    background-color: var(--accent_hover);
  }
  &.accent-warning {
    background-color: var(--accent_warning_hover);
  }
  &.accent-success {
    background-color: var(--accent_success_hover);
  }
}
.railway-dropdown {
  .r-title {
    padding: 5px 8px 8px;
  }
  .r-dropdown-menu__item-wrapper {
    &.active {
      background-color: var(--accent_selected);
    }
  }
}
</style>
