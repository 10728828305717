<template>
  <sc-card
    v-if="type === `card`"
    v-loading="isLoading"
    :title="$t('layer-controller:' + 'layers')"
  >
    <div class="sc-layer-controller card">
      <ul
        v-if="allMapLayersList.length"
        class="sc-layer-controller__list"
      >
        <li
          v-for="layer in allMapLayersList"
          :key="layer"
          :class="[
            `sc-layer-controller__list-item`,
            { active: isLayerActive(layer) }
          ]"
          @click="toggleLayer(layer)"
        >
          <r-text>{{ $t('layer-controller:' + layer) }}</r-text>
        </li>
        <li
          v-for="(item, i) in mapPlugItems"
          :key="item + i"
          :class="[`sc-layer-controller__list-item disabled`]"
          @click="toggleLayer(layer)"
        >
          <r-text>{{ $t('layer-controller:' + layer) }}</r-text>
        </li>
        <li
          v-if="hasMatrixLayer"
          :class="[
            'sc-layer-controller__list-item',
            { active: isMatrixActive }
          ]"
          @click="toggleLayer('matrix')"
        >
          <r-text :size="16">
            {{ $t('layer-controller:' + layer) }}
          </r-text>
        </li>
      </ul>
    </div>
  </sc-card>
  <div
    v-else
    class="sc-layer-controller default"
  >
    <ul
      v-if="mapLayersList.length"
      class="sc-layer-controller__list"
    >
      <el-tooltip
        v-for="layer in mapLayersList"
        :key="layer"
        :open-delay="500"
        :content="$t('layer-controller:' + layer)"
        placement="bottom"
      >
        <li
          :class="[
            `sc-layer-controller__list-item`,
            { active: isLayerActive(layer) }
          ]"
          @click="toggleLayer(layer)"
        >
          <r-icon
            :name="getIconName(layer)"
            :size="24"
          />
        </li>
      </el-tooltip>
      <el-tooltip
        v-for="layer in mapPlugItems"
        :key="layer"
        :open-delay="500"
        :content="$t('layer-controller:' + layer)"
        placement="bottom"
      >
        <li
          :class="[`sc-layer-controller__list-item disabled`]"
          @click="toggleLayer(layer)"
        >
          <r-icon
            :name="getIconName(layer)"
            :size="24"
          />
        </li>
      </el-tooltip>
      <el-tooltip
        v-if="activeSC && hasMatrixLayer"
        :open-delay="500"
        :content="$t('layer-controller:' + 'matrix')"
        placement="bottom"
      >
        <li
          :class="[
            `sc-layer-controller__list-item`,
            { active: isMatrixActive }
          ]"
          @click="toggleLayer('matrix')"
        >
          <r-icon
            name="heatmap"
            :size="18"
          />
        </li>
      </el-tooltip>
    </ul>
    <el-dropdown
      class="r-dropdown sc-layer-controller__dropdown"
      size="mini"
      :hide-on-click="false"
      :show-timeout="100"
      placement="bottom-start"
      trigger="click"
      @visible-change="e => toggleHandler(e)"
    >
      <div :class="['r-dropdown__item', { active: isDropdownShow }]">
        <r-icon
          name="folder-layer"
          :size="24"
          style="cursor: pointer"
          arrow-down
        />
      </div>
      <el-dropdown-menu
        slot="dropdown"
        class="r-dropdown-menu"
      >
        <el-dropdown-item
          v-for="layer in mapTileLayersList"
          :key="layer"
        >
          <div
            class="r-dropdown-menu__item-wrapper"
            @click="toggleLayer(layer)"
          >
            <el-checkbox
              class="r-checkbox"
              size="mini"
              :value="isLayerActive(layer)"
              @change="toggleLayer(layer)"
            />
            <r-text style="margin-left: 12px">
              {{ $t('layer-controller:' + layer) }}
            </r-text>
          </div>
        </el-dropdown-item>
        <el-dropdown-item>
          <div
            class="r-dropdown-menu__item-wrapper"
            @click="toggleLayer('satellite')"
          >
            <el-checkbox
              class="r-checkbox"
              size="mini"
              :value="isLayerActive('satellite')"
              @change="toggleLayer('satellite')"
            />
            <r-text style="margin-left: 12px">
              Спутник
            </r-text>
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import scCard from '../../layout/card'

import { getWidgetStyleConfig } from '../../../configs'

export default {
  components: { scCard },
  props: {
    type: {
      type: String,
      default: 'default'
    }
  },
  data() {
    return {
      isDropdownShow: false,
      isLoading: false
    }
  },
  computed: {
    disabledLayers() {
      return this.$store.state.situationCenter.disabledLayers
    },
    allMapLayersList() {
      const mapLayers =
        Object.keys(this.$store.state.situationCenter.layersData.map).sort(
          (a, b) => {
            if (a > b) return 1
            if (a < b) return -1
            return 0
          }
        ) || []
      const mapTileLayers = this.$store.state.situationCenter.config
        .mapTileLayers
        ? this.$store.state.situationCenter.config.mapTileLayers.map(e => e.id)
        : []

      return mapLayers.concat(mapTileLayers)
    },
    mapPlugItems() {
      return Object.keys(this.$store.state.situationCenter.mapPlugItems)
    },
    mapLayersList() {
      const mapLayers =
        Object.keys(this.$store.state.situationCenter.layersData.map) || []

      return mapLayers
    },
    mapTileLayersList() {
      const mapTileLayers = this.$store.state.situationCenter.config
        .mapTileLayers
        ? this.$store.state.situationCenter.config.mapTileLayers.map(e => e.id)
        : []

      return mapTileLayers
    },
    activeSC() {
      return this.$store.state.situationCenter.activeSC || null
    },
    hasMatrixLayer() {
      return this.$store.state.situationCenter.config.hasMatrix
    },
    isMatrixActive() {
      return this.$store.state.situationCenter.matrix.enabled
    },
    isSatelliteActive() {
      return this.$store.state.situationCenter.satellite
    }
  },
  methods: {
    toggleHandler(val) {
      this.isDropdownShow = val
    },
    async toggleLayer(layer_id) {
      if (layer_id === 'matrix') {
        this.$store.commit('SET_SI_CENTER_MATRIX_PROP', {
          field: 'enabled',
          value: !this.isMatrixActive
        })
      } else if (layer_id === 'satellite') {
        this.$store.commit('SET_SI_CENTER_FIELD', {
          field: 'satellite',
          value: !this.isSatelliteActive
        })
      } else {
        this.$store.commit('TOGGLE_SI_CENTER_ACTIVE_LAYERS', layer_id)
      }
      try {
        await this.$store.dispatch('SAVE_MAIN_USER_CONFIG')
      } catch (e) {
        console.warn(e)
      }
    },
    isLayerActive(layer_id) {
      if (layer_id === 'satellite') return this.isSatelliteActive

      return this.disabledLayers.indexOf(layer_id) === -1
    },
    getIconName(layer_id) {
      const style = getWidgetStyleConfig(layer_id)

      return style?.icons?.default || 'layer'
    }
  }
}
</script>

<style lang="scss">
.sc-layer-controller {
  width: 300px;
  height: 240px;
  display: flex;
  overflow: hidden;
  position: relative;
  &__list {
    width: 100%;
    overflow: hidden;
    overflow-y: auto;
    padding-right: 4px;
    &-item {
      padding: 8px 16px;
      border-radius: var(--border-radius);
      margin-bottom: 2px;
      cursor: pointer;
      transition: all 0.15s ease;
      &:last-child {
        margin-bottom: 0;
      }
      &:hover {
        background-color: var(--accent_hover) !important;
      }
      &.active {
        background-color: var(--accent_primary) !important;
        .r-text {
          color: var(--white_white) !important;
        }
      }
      &:active {
        background-color: var(--accent_active) !important;
      }
      &.disabled {
        cursor: initial;
      }
    }
  }
  &.default {
    width: auto;
    height: auto;
    .sc-layer-controller__dropdown {
      margin-left: 8px;
      padding: 4px;
      display: flex;
      border-radius: var(--border-radius);
      background-color: var(--modal_bg) !important;
      box-shadow: 0px 3px 6px #00000029;
      .r-dropdown__item {
        padding: 0 4px;
      }
    }
    .sc-layer-controller__list {
      display: flex;
      border-radius: var(--border-radius);
      padding: 4px;
      background-color: var(--modal_bg) !important;
      box-shadow: 0px 3px 6px #00000029;
      &-item {
        display: flex;
        min-width: 36px;
        min-height: 36px;
        max-width: 36px;
        max-height: 36px;
        border-radius: var(--border-radius);
        justify-content: center;
        align-items: center;
        padding: 0;
        margin: 0 4px 0 0;
        outline: none !important;
        &:last-child {
          margin-right: 0;
        }
        &:hover {
          background-color: var(--accent_hover) !important;
        }
        &.active {
          background-color: var(--accent_selected) !important;
          .r-text {
            color: var(--white_white) !important;
          }
          .r-icon__svg {
            fill: var(--white_white) !important;
          }
        }
        &.disabled {
          cursor: initial;
        }
        &:active {
          background-color: var(--accent_active) !important;
        }
      }
    }
  }
}
</style>
