<template>
  <div class="rp-users-modules__item-wrapper--card">
    <div class="rp-users-modules__item-wrapper--card__title">
      <el-checkbox
        class="r-checkbox"
        size="mini"
        :value="isActive(component.id)"
        :disabled="toggleDisabled(component.id)"
        @change="beforeToggle(module.name, component.id)"
      >
        <r-text>{{ $t('rp-users-modules-item-card:' + `${module.name}:${component.value}`) }}</r-text>
      </el-checkbox>
      <div
        class="rp-users-modules__item-wrapper--card__toggler"
        @click="cardToggleHandler()"
      >
        <r-icon
          :name="isOpen ? 'chevron-up' : 'chevron-down'"
          :size="20"
        />
      </div>
    </div>
    <div
      v-if="isOpen"
      class="rp-users-modules__item-wrapper--card-wrapper"
    >
      <r-text class="rp-users-modules__item-wrapper--card-wrapper__title">
        Настройка компонента
      </r-text>
      <div
        v-for="instrument in component.instruments"
        :key="instrument.id"
        class="rp-users-modules__item instrument"
      >
        <el-checkbox
          class="r-checkbox"
          size="mini"
          :value="isActive(component.id, instrument.id)"
          :disabled="toggleDisabled(component.id, instrument.id)"
          @change="beforeToggle(module.name, component.id, instrument.id)"
        >
          <r-text>{{ $t('rp-users-modules-item-card:' + `${module.name}:${component.value}:${instrument.name}`) }}</r-text>
        </el-checkbox>
      </div>
    </div>
  </div>
</template>

<script>
import { isInstrumentActive, isComponentActive } from './helpers'

export default {
  props: {
    module: {
      type: Object,
      required: true
    },
    component: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  computed: {
    userModules() {
      return this.$store.state.rolePermission.userPerms?.modules || []
    },
    roleModules() {
      return this.$store.state.rolePermission.rolePerms?.modules || []
    }
  },
  watch: {
    userModules: {
      handler: function() {
        this.$forceUpdate()
      },
      deep: true
    }
  },
  methods: {
    cardToggleHandler() {
      this.isOpen = !this.isOpen
    },
    isActive(component, instrument) {
      if (instrument) {
        return isInstrumentActive(this.userModules, component, instrument)
      } else {
        return isComponentActive(this.userModules, component)
      }
    },
    beforeToggle(module, component, instrument) {
      this.$emit('before-toggle', {
        module,
        component,
        instrument
      })
    },
    toggleDisabled(component, instrument) {
      if (!component) return false
      if (instrument) {
        const initialComponent = this.roleModules.find(e => e.id === component)
        return !!initialComponent?.instruments.find(e => e.id === instrument)
      } else {
        return !!this.roleModules.find(e => e.id === component)
      }
    }
  }
}
</script>

<style lang="scss">
.rp-users-modules__item-wrapper--card {
  overflow: hidden;
  border-radius: var(--border-radius);
  background-color: var(--bg_containers) !important;
  padding: 8px;

  .el-checkbox__label {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__toggler {
    cursor: pointer;
    width: 40px;
    display: flex;
    justify-content: flex-end;
  }

  &__title {
    display: flex;
    justify-content: space-between;
  }

  &-wrapper {
    &__title {
      margin: 8px 0 4px;
    }
  }

  .rp-users-modules__item {
    &.instrument {
      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
