<template>
  <div class="profile-tree">
    <ritm-tree
      v-if="1 === 1"
      :loading="loading"
      :tree-data="treeData"
      :active-nodes="activeNodes"
      no-animations
      :node-click="nodeClick"
      :icon-by-item="iconByItem"
      edit-mode
      @updateNode="updateProfileTreeItem"
    />
  </div>
</template>

<script>
import { iconByItem, notifyFactory } from '@/utils'
import vue from 'vue'
import cloneDeep from 'lodash.clonedeep'

export default {
  components: {
    ritmTree: () => import('@/components/ritm-tree')
  },
  props: {
    editMode: {
      type: Boolean,
      default: false
    },
    module: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loading: false,
      iconByItem,
      currentProfileId: this.$store.state.profiles.currentProfile.id,
      treeData: [],
      activeNodes: []
    }
  },
  computed: {
    currentProfileTree() {
      return this.$store.state.profiles.currentProfileTree || []
    },
    constructorProfileId() {
      return this.$store.state.profiles.profileConstructor.profileId
    }
  },
  watch: {
    '$store.state.profiles.updatedTreeTS'(val) {
      if (val) {
        this.updateTree()
      }
    }
  },
  async created() {
    this.updateTree(true)
  },
  methods: {
    async updateProfileTreeItem(data) {
      if (!data) return

      const id =
        this.module === 'constructorTree'
          ? this.constructorProfileId
          : this.currentProfileId

      const options = {
        url: 'user/profile?',
        data: [{ id, profile_trees: data }]
      }

      try {
        this.loading = true
        await this.$store.dispatch('POST_REQUEST', options)
        await this.updateTree(true)

        const title = this.$t('profile-tree:' + 'profile:change-success-title')
        const message = this.$t('profile-tree:' + 'profile:change-success')
        this.$notify(notifyFactory('info', title, message))
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    nodeClick({ id }) {
      const { profiles } = this.$store.state
      const { checkedDataProfile } = profiles.profileConstructor

      const checkedData = checkedDataProfile.includes(id)
        ? checkedDataProfile.filter(p => p !== id)
        : [...checkedDataProfile, id]

      this.$store.commit('UPDATE_CHECKED_DATA', {
        checkedData,
        isProfile: 'constructorTree' // ?
      })
      this.activeNodes = checkedData
    },
    async updateTree(onload = false) {
      if (onload) {
        this.$store.commit('CLEAR_CHECKED_DATA', true)
      }

      let url

      if (this.module === 'constructorTree') {
        url = `profile_tree?profile_id=${this.constructorProfileId}`

        if (this.constructorProfileId === this.currentProfileId && this.currentProfileTree?.length) {
          vue.set(this, 'treeData', cloneDeep(this.currentProfileTree))
          return
        }
      } else {
        url = `profile_tree?profile_id=${this.currentProfileId}`

        if (this.currentProfileTree?.length) {
          vue.set(this, 'treeData', cloneDeep(this.currentProfileTree))
          return
        }
      }

      try {
        this.loading = true

        const { data } = await this.$store.dispatch('GET_REQUEST', { url })

        vue.set(this, 'treeData', data.profile_trees)

        if (this.module !== 'constructorTree') {
          this.$store.commit('SET_CURRENT_PROFILE_TREE', this.treeData)
        }
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss">
.profile-tree {
  position: relative;
  height: 100%;
  border-radius: var(--border-radius);
  overflow: hidden;
}
</style>
