<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div
    class="attachments"
    :class="{ 'has-files': hasFiles, disabled, 'drag-hover': dragHover, square }"
    @dragover.prevent.stop="onDragOver($event)"
    @dragleave.prevent.stop="onDragLeave($event)"
    @drop.stop="onDrop($event)"
    @click.stop="openUploader"
  >
    <input
      id="attachments-support"
      ref="upload"
      type="file"
      :accept="acceptedTypes.join(',')"
      :multiple="multiple"
      @change="handleFiles"
    >
    <r-icon
      name="add-plus"
      :size="24"
    />
    <r-button
      slot="tip"
      class="attachments-button"
      simple
      :disabled="disabled"
    >
      {{ label }}
      <r-text
        v-if="acceptedTypes.length"
        center
        type="caption"
      >
        {{ acceptedTypes.join(', ') }}
      </r-text>
    </r-button>
    <TransitionGroup
      v-if="!hideList"
      name="list"
      class="attachments__file-list file-list"
    >
      <div
        v-for="({ name }, i) in source[field]"
        :key="i"
        class="file-list__item"
      >
        <r-icon
          name="acts-document"
          :size="22"
        />
        <r-text>
          {{ name }}
        </r-text>
        <r-delete-button
          :disabled="disabled"
          tiny
          simple
          @click.native="remove($event, name)"
        />
      </div>
    </TransitionGroup>
  </div>
</template>

<script>
export default {
  props: {
    source: {
      type: Object,
      required: true
    },
    field: {
      type: String,
      default: 'file'
    },
    multiple: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hideList: {
      type: Boolean,
      default: false
    },
    square: {
      type: Boolean,
      default: false
    },
    acceptedTypes: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: 'Добавить файл'
    }
  },
  data() {
    return {
      dragHover: false,
      tempList: []
    }
  },
  computed: {
    hasFiles() {
      return this.source[this.field].length
    }
  },
  watch: {
    source: {
      handler: function(val) {
        if (!val?.[this.field]?.length) {
          this.tempList = []
        }
      },
      deep: true
    }
  },
  methods: {
    openUploader() {
      if (this.disabled) return
      this.$refs.upload.click()
    },
    onDragOver() {
      if (this.disabled) return
      this.dragHover = true
    },
    onDragLeave() {
      if (this.disabled) return
      this.dragHover = false
    },
    onDrop(e) {
      if (this.disabled) return
      e.preventDefault()
      this.dragHover = false
      const { files } = e.dataTransfer
      this.dropFiles(files)
    },
    dropFiles(files) {
      const fileList = [...files]
      const dt = new DataTransfer()
      const allFiles = this.multiple
        ? [...this.tempList, ...fileList]
        : [...fileList]

      this.tempList = allFiles.filter(
        (f, i, a) => a.findIndex(v => v.name === f.name) === i
      )
      this.tempList.forEach(file => dt.items.add(file))
      this.$refs.upload.files = dt.files
      this.source[this.field] = []
      this.tempList.forEach(file => {
        this.source[this.field].push(file)
      })
    },
    handleFiles(_, update = false) {
      const { files } = this.$refs.upload
      const fileList = [...files]

      if (!update) {
        const dt = new DataTransfer()

        const allFiles = this.multiple
          ? [...this.tempList, ...fileList]
          : [...fileList]

        this.tempList = allFiles.filter(
          (f, i, a) => a.findIndex(v => v.name === f.name) === i
        )
        this.tempList.forEach(file => dt.items.add(file))
        this.$refs.upload.files = dt.files
      } else {
        this.tempList = fileList
      }
      this.source[this.field] = []

      this.tempList.forEach(file => {
        this.source[this.field].push(file)
      })
    },
    remove(e, filename) {
      e.stopPropagation()
      e.preventDefault()
      const index = this.tempList.findIndex(f => f.name === filename)
      this.tempList.splice(index, 1)

      const dt = new DataTransfer()
      this.tempList.forEach(f => dt.items.add(f))
      this.$refs.upload.files = dt.files

      this.handleFiles('', true)
    }
  }
}
</script>

<style lang="scss" scoped>
.attachments {
  display: grid;
  transition: all 0.15s ease;
  grid-gap: 0.25rem;
  align-items: center;
  border-radius: 0.5rem;
  padding: 0.5rem;
  outline: 2px dashed var(--dividers_low_contrast);
  cursor: pointer;
  outline-offset: -2px;
  background-color: var(--bg_containers);
  align-content: center;

  &.square {
    width: 7.5rem;
    height: 7.5rem;
  }

  &:hover {
    outline: 2px dashed var(--accent_primary) !important;
  }

  &.drag-hover {
    outline: 2px dashed var(--accent_primary);
    outline-offset: -2px;
    animation: pulse 1s infinite cubic-bezier(0.5, 0, 0.5, 1);
  }

  &.disabled {
    opacity: 0.8;
    cursor: not-allowed;
  }

  &-button {
    display: grid;
    padding-right: 0.5rem;
    grid-gap: 0.5rem;
    grid-auto-flow: column;
    justify-content: space-between;
    align-items: center;
    height: auto !important;

    label {
      cursor: inherit;
      white-space: break-spaces;
      font-size: 12px;
      font-weight: 400;
      color: var(--text_secondary);
      line-height: 1.25;
    }
  }

  &.has-files {
    background-color: var(--field_bg);
    outline: 2px dashed transparent;
  }

  & > input {
    display: none;
  }

  .r-button {
    justify-self: start;
  }

  .file-list {
    border-top: 1px solid var(--dividers_low_contrast);
    padding: 0.5rem 0;
    display: grid;
    grid-gap: 0.25rem;
    transition: all 0.15s ease;
    overflow: hidden;

    &:empty {
      display: none;
    }

    &__item {
      display: grid;
      grid-template-columns: 2rem auto 2rem;
      justify-content: stretch;
      align-items: center;
      grid-gap: 0.25rem;
    }
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 0.4s ease;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateX(-30px);
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.44;
  }
  100% {
    opacity: 1;
  }
}
</style>
