export const ISS_ACTION_TYPE = {
  KPP_ENTRY: 1,
  KPP_EXIT: 2,
  POT_ENTRY: 4,
  POT_EXIT: 5,
  PASSING: 6
}

export const ASTRA_ACTION_TYPE = {
  CARFLOW: 9,
  OVER_SPEED: 10,
  WRONG_DIRECTION: 11
}

export const VIDEOFOR_ACTION_TYPE = {
  ZONE_VIOLATION: 3,
  TRANSPORT_DETECTION: 41,
  TRANSPORT_DIRECTION: 42,
  TRANSPORT_ZONE_VIOLATION: 43,
  TRANSPORT_JAM: 44,
  TRANSPORT_ACCIDENT: 45,
  TIME_VIOLATION: 61,
  DANG_MOVE: 8
}

export const types = [
  {
    id: 'car_event',
    name: 'Телематика',
    permission_object_id: '89e9ea59-359f-4cc8-8cb9-25828d2e5281'
  },
  {
    id: 'gazprom',
    name: 'Камеры периметра АГПЗ (КПП, ЖД)',
    permission_object_id: '99f74a2d-7e0d-4840-8516-12722ddf665c'
  },
  {
    id: 'videophoto',
    name: 'Камеры ФВФ',
    permission_object_id: 'de06644f-6b8d-4a15-8648-3a74569e991b'
  },
  {
    id: 'videoanalytics',
    name: 'Камеры видеоаналитики',
    permission_object_id: '0429cf74-7e90-48a4-853e-53a0241dfe48'
  },
  {
    id: 'nipigaz_pot',
    name: 'Камеры ПОТ',
    permission_object_id: '57b048e9-2015-476c-acae-386d199ffe6b'
  },
  {
    id: 'nipigaz',
    name: 'Камеры дорожные',
    permission_object_id: '114ad2a8-343e-4764-9251-af70ccfb1e0c'
  },
  {
    id: 'system_event',
    name: 'Системные',
    permission_object_id: '8ec4e42b-3fe4-47a0-9c3e-547b9c2d13b8'
  }
]

export const requestUrls = {
  car_event: {
    url: 'event_car_events',
    with_numeration: true,
    column_order: [
      'event_time',
      'event_type',
      'reg_number',
      'vehicle_model_name',
      'vehicle_type_name',
      'vehicle_type_group_name',
      'contractor_name',
      'sub_contractor_name',
      'speed_limit',
      'speed',
      'description'
    ],
    column_labels: [
      'Время события',
      'Тип события',
      'Гос. номер',
      'Модель',
      'Вид транспорта',
      'Тип транспорта',
      'Подрядчик',
      'Субподрядчик',
      'Разрешённая скорость',
      'Фактическая скорость',
      'Описание'
    ]
  },
  gazprom: {
    url: 'event_camera_traffic',
    with_numeration: true,
    column_order: [
      'reg_number',
      'vehicle.vehicle_type.vehicle_type_group.name',
      'vehicle.contractor.name',
      'vehicle.sub_contractor.name',
      'camera_name',
      'no_kpp',
      'time'
    ],
    column_labels: [
      'Гос. номер',
      'Тип транспорта',
      'Подрядчик',
      'Субподрядчик',
      'Камера',
      '№ КПП',
      'Время события'
    ]
  },
  videophoto: {
    url: 'event_camera_traffic',
    with_numeration: true,
    column_order: [
      'reg_number',
      'vehicle.model.name',
      'vehicle.vehicle_type.vehicle_type_group.name',
      'vehicle.contractor.name',
      'vehicle.sub_contractor.name',
      'vehicle.subprojects.name',
      'vehicle.project_label',
      'vehicle.applications.permits.number',
      'action_type',
      'camera_name',
      'time',
      'speed',
      'direction_id',
      'over_speed'
    ],
    column_labels: [
      'Гос. номер',
      'Модель',
      'Тип транспорта',
      'Подрядчик',
      'Субподрядчик',
      'Подпроект',
      'Проект',
      '№ пропуска',
      'Тип события',
      'Камера',
      'Время события',
      'Фактическая скорость, км/ч',
      'Направление',
      'Превышение, км/ч'
    ]
  },
  videoanalytics: {
    url: 'event_camera_traffic',
    with_numeration: true,
    column_order: [
      'action_type',
      'reg_number',
      'vehicle.vehicle_type.name',
      'vehicle.vehicle_type.vehicle_type_group.name',
      'vehicle.contractor.name',
      'vehicle.sub_contractor.name',
      'time'
    ],
    column_labels: [
      'Тип события',
      'Гос. номер',
      'Вид транспорта',
      'Тип транспорта',
      'Подрядчик',
      'Субподрядчик',
      'Время события'
    ]
  },
  nipigaz: {
    url: 'event_camera_traffic',
    with_numeration: true,
    column_order: [
      'reg_number',
      'vehicle.vehicle_type.vehicle_type_group.name',
      'vehicle.contractor.name',
      'vehicle.sub_contractor.name',
      'camera_name',
      'time'
    ],
    column_labels: [
      'Гос. номер',
      'Тип транспорта',
      'Подрядчик',
      'Субподрядчик',
      'Камера',
      'Время события'
    ]
  },
  nipigaz_pot: {
    url: 'event_camera_traffic',
    with_numeration: true,
    column_order: [
      'reg_number',
      'vehicle.vehicle_type.vehicle_type_group.name',
      'vehicle.contractor.name',
      'vehicle.sub_contractor.name',
      'camera_name',
      'time'
    ],
    column_labels: [
      'Гос. номер',
      'Тип транспорта',
      'Подрядчик',
      'Субподрядчик',
      'Камера',
      'Время события'
    ]
  },
  system_event: {
    url: 'event_system_events',
    with_numeration: true,
    column_order: [
      'end_time',
      'worker_class',
      'success'
    ],
    column_labels: [
      'Время события',
      'Тип события',
      'Статус'
    ]
  }
}

export const requestConfigs = {
  car_event: {
    only: ['id', 'event_time', 'event_type', 'event_geom', 'event_attrs', 'description'],
    order: [{ event_time: 'desc' }],
    include: {
      vehicle: {
        only: ['reg_number', 'id'],
        include: {
          model: {
            only: ['id', 'name']
          },
          vehicle_type: {
            only: ['id', 'name'],
            include: {
              vehicle_type_group: {
                only: ['name', 'id']
              }
            }
          },
          contractor: {
            only: ['id', 'name']
          },
          sub_contractor: {
            only: ['id', 'name']
          }
        }
      }
    }
  },
  gazprom: {
    where: [
      { field: 'camera_type', op: 'in', value: [2, 3] },
      {
        field: 'action_type',
        op: 'in',
        value: [
          ISS_ACTION_TYPE.KPP_ENTRY, ISS_ACTION_TYPE.KPP_EXIT]
      }
    ]
  },
  videophoto: {
    where: [
      { field: 'camera_type', op: '=', value: 1 }
    ]
  },
  videoanalytics: {
    where: [
      { field: 'camera_type', op: '=', value: 4 }
    ]
  },
  nipigaz: {
    where: [
      { field: 'camera_type', op: 'in', value: [2, 3] },
      { field: 'action_type', op: '=', value: ISS_ACTION_TYPE.PASSING }
    ]
  },
  nipigaz_pot: {
    where: [
      { field: 'camera_type', op: 'in', value: [2, 3] },
      {
        field: 'action_type',
        op: 'in',
        value: [
          ISS_ACTION_TYPE.POT_ENTRY, ISS_ACTION_TYPE.POT_EXIT
        ]
      }
    ]
  },
  system_event: {
    order: [{ end_time: 'desc' }]
  }
}
