<template>
  <div
    v-loading="iconsLoading"
    class="r-info-layers"
  >
    <component
      :is="`${layer}-button`"
      v-for="layer in filteredLayers"
      :ref="layer"
      :key="layer"
      :mapgl="mapgl"
      :module="module"
      :vehicle-category-id="vehicleCategoryId"
    />
  </div>
</template>

<script>
import TilelayersButton from './r-info-layers/layers/tilelayers'
import VehiclesButton from './r-info-layers/layers/vehicles'
import GeozonesButton from './r-info-layers/layers/geozones'
import SignsButton from './r-info-layers/layers/signs'
import EventsButton from './r-info-layers/layers/events'
import GraphButton from './r-info-layers/layers/graph'
import TransportSituationButton from './r-info-layers/layers/transport-situation'
import PassingStatisticButton from './r-info-layers/layers/passing-statistic/passing-statistic'
import KppAghkButton from './r-info-layers/layers/kpp-aghk'
import KppAgpzButton from './r-info-layers/layers/kpp-agpz'
import AbkAgpzButton from './r-info-layers/layers/abk-agpz'
import OverpassesAgpzButton from './r-info-layers/layers/overpasses-agpz'
import ShahmatkaButton from './r-info-layers/layers/shahmatka'
import WerehouseButton from './r-info-layers/layers/warehouse'

import { mapGetters } from 'vuex'

export default {
  components: {
    VehiclesButton,
    TilelayersButton,
    GeozonesButton,
    SignsButton,
    GraphButton,
    TransportSituationButton,
    EventsButton,
    PassingStatisticButton,
    KppAghkButton,
    KppAgpzButton,
    AbkAgpzButton,
    OverpassesAgpzButton,
    ShahmatkaButton,
    WerehouseButton
  },
  props: {
    mapgl: {
      type: Object,
      required: true
    },
    enabledLayers: {
      type: Array,
      default: () => [
        'vehicles',
        'graph',
        'transportSituation',
        'geozones',
        'signs',
        'events',
        'passingStatistic',
        'kppAghk',
        'kppAgpz',
        'abkAgpz',
        'overpassesAgpz',
        'shahmatka',
        'werehouse',
        'tilelayers'
      ]
    },
    lockedLayers: {
      type: Array,
      default: () => []
    },
    module: {
      type: String,
      default: 'default'
    },
    vehicleCategoryId: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      layers: [
        { name: 'vehicles', permission_object_id: 'ebefc2ff-020a-410d-ae1e-cb0405373f4d' },
        { name: 'graph', permission_object_id: '5eb58dad-c43b-4496-9fc1-049ad5614b9b' },
        { name: 'transportSituation', permission_object_id: '805905e4-cb28-47d4-a636-9435aa7e6948' },
        { name: 'geozones', permission_object_id: '5af397e8-b5a8-4e87-82d6-5e0823280863' },
        { name: 'signs', permission_object_id: '93ac25ac-19af-4325-9bc3-ac36799624a7' },
        { name: 'events', permission_object_id: 'df5d7fde-5716-430c-befb-b6f3f379390d' },
        { name: 'passingStatistic', permission_object_id: 'ad15b2d5-4e56-4074-8741-32f889e36311' },
        { name: 'kppAghk', permission_object_id: 'df8643d3-865f-4bac-a82c-13a1ecb5268b' },
        { name: 'kppAgpz', permission_object_id: '96428365-beda-4fab-bb19-785bf2997a7f' },
        { name: 'abkAgpz', permission_object_id: 'c56aa89c-c168-4e63-8d85-fb1a4d6ab080' },
        { name: 'overpassesAgpz', permission_object_id: 'e1ef1329-d928-45be-9d31-e98397803eef' },
        { name: 'shahmatka', permission_object_id: '401ca391-c1fe-455c-8c59-d9a30c5fc359' },
        { name: 'werehouse', permission_object_id: '95b52a2d-db73-4bde-8e81-e090feac73a8' },
        { name: 'tilelayers', permission_object_id: 'e8c676a2-3cb7-4786-a074-bc4677a0b5cc' }
      ],
      eventsSourceId: null
    }
  },
  computed: {
    ...mapGetters([
      'isLayerIdActive',
      'layersToRenderList',
      'activeLayersList',
      'lockedLayersList'
    ]),
    filteredLayers() {
      const allModulesIds = this.$store.state.auth.module_permission_ids
      return this.layers.filter(l => this.enabledLayers.includes(l.name) && allModulesIds.includes(l.permission_object_id)).map(l => l.name)
    },
    iconsLoading() {
      return this.$store.state.odd.iconsLoading
    }
  },
  watch: {
    '$store.state.theme'() {
      this.loadIcons()
    }
  },
  mounted() {
    this.$store.commit('MAP_CONFIGS_SET_CONFIG', {
      field: 'lockedLayers',
      module: this.module,
      value: this.lockedLayers.map(l => this.$refs[l][0].id)
    })
  },
  created() {
    this.loadIcons()
  },
  methods: {
    async loadIcons() {
      try {
        if (this.enabledLayers.includes('vehicles')) {
          await this.loadVehicleIcons()
        }

        if (this.enabledLayers.includes('events')) {
          await this.loadEventsIcons()
        }

        await this.loadSdfIcons()
      } catch (e) {
        throw new Error(e)
      }
    },
    async loadEventsIcons() {
      const iconsList = ['work-icon', 'accident-icon', 'event-icon', 'sign-icon']
      const icons = iconsList.map(e => ({
        name: e,
        imageUrl: require(`@/assets/images/odd/${e}.svg`)
      }))
      const MAP_ICON_SIZE = 44

      return Promise.all(
        icons.map(
          ({ name, imageUrl }) =>
            new Promise(resolve => {
              const image = new Image(MAP_ICON_SIZE, MAP_ICON_SIZE)
              image.crossOrigin = 'Anonymous'
              image.style.backgroundPosition = '50%, 50%'
              image.style.backgroundSize = '100%'
              image.addEventListener('load', () => {
                if (!this.mapgl.hasImage(name)) {
                  this.mapgl.addImage(name, image)
                }
                resolve()
              })
              image.src = imageUrl
            })
        )
      ).then(() => {
      })
    },
    async loadSdfIcons() {
      const iconsList = ['folder-layer']
      const icons = iconsList.map(e => ({
        name: e,
        imageUrl: require(`@/assets/icons/mono/layer/${e}.svg`)
      }))
      const general = ['storehouse', 'kpp-gate']
      general.forEach(e => {
        icons.push({
          name: e,
          imageUrl: require(`@/assets/icons/mono/general/${e}.svg`)
        })
      })
      const MAP_ICON_SIZE = 44

      return Promise.all(
        icons.map(
          ({ name, imageUrl }) =>
            new Promise(resolve => {
              const image = new Image(MAP_ICON_SIZE, MAP_ICON_SIZE)
              image.crossOrigin = 'Anonymous'
              image.style.backgroundPosition = '50%, 50%'
              image.style.backgroundSize = '100%'
              image.addEventListener('load', () => {
                if (!this.mapgl.hasImage(name)) {
                  this.mapgl.addImage(name, image, { sdf: true })
                }
                resolve()
              })
              image.src = imageUrl
            })
        )
      ).then(() => {
      })
    },
    loadVehicleIcons() {
      const iconsBank = [
        'aghk-bus-default',
        'aghk-bus-disabled',
        'agpz-bus-default',
        'agpz-bus-disabled'
      ]
      const icons = iconsBank.map(e => ({
        name: e,
        imageUrl: require(`@/assets/images/monitoring/${e}.svg`)
      }))
      const MAP_ICON_SIZE = 44

      return Promise.all(
        icons.map(
          ({ name, imageUrl }) =>
            new Promise(resolve => {
              const image = new Image(MAP_ICON_SIZE, MAP_ICON_SIZE)
              image.crossOrigin = 'Anonymous'
              image.style.backgroundPosition = '50%, 50%'
              image.style.backgroundSize = '100%'
              image.addEventListener('load', () => {
                if (!this.mapgl.hasImage(name)) {
                  this.mapgl.addImage(name, image)
                }
                resolve()
              })
              image.src = imageUrl
            })
        )
      ).then(() => {
      })
    },
    async rerenderComponent() {
      await this.loadIcons()
      const layers = this.layersToRenderList(this.module)

      Object.values(this.$refs).forEach(([component]) => {
        if (component.id === 'tile_layers') {
          component.updateLayers(layers)
        } else if (layers.includes(component.id)) {
          component.addHandler()
        }
      })
    }
  }
}
</script>

<style lang="scss">
.r-info-layers {
  background-color: var(--modal_bg) !important;
  padding: 4px;
  border-radius: var(--border-radius);
  box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.1);
  display: grid;
  grid-auto-flow: column;
  grid-gap: 0.25rem;
}
</style>
