<template>
  <div class="sc-events-log__list-item">
    <div class="sc-events-log__list-item__block">
      <div class="sc-events-log__list-item__block__icon">
        <r-cblock
          :size="32"
          :color="getColor(event.event_class_id)"
          :border-color="getColor(event.event_class_id)"
        >
          <r-icon
            :name="getIconName(event.event_class_id)"
            :size="18"
            :color="getColor(event.event_class_id)"
          />
        </r-cblock>
      </div>
      <div class="sc-events-log__list-item__block__info">
        <r-title type="subtitle-1">
          {{ event.event_class_id }}
        </r-title>
        <r-text type="caption">
          {{ event.address || '' }} {{ event.address ? '|' : '' }}
          {{ getDateTime(event.start_time) }}
        </r-text>
      </div>
      <div
        v-if="descriptionFields.length"
        class="sc-events-log__list-item__block__control"
        @click="showMore = !showMore"
      >
        <r-button
          simple
          type="primary"
        >
          {{ !showMore ? $t('events-log-list-item-type-1:' + 'show-more') : $t('events-log-list-item-type-1:' + 'hide') }}
        </r-button>
      </div>
    </div>
    <div
      v-if="showMore"
      class="sc-events-log__list-item__block"
    >
      <ul class="sc-events-log__list-item__block__description-list">
        <li
          v-for="(field, i) in descriptionFields"
          :key="field + i"
          class="sc-events-log__list-item__block__description-list-item"
        >
          <r-text
            type="caption"
            style="margin: 0 8px 0 0 !important"
          >
            {{ $t('events-log-list-item-type-1:' + field) + ':' }}
          </r-text>
          <r-text>{{ event[field] }}</r-text>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { widgetStyleConfig } from '../../../../configs'

const availableFields = [
  'customer',
  'performer',
  'phone_number',
  'closed_line_count'
]

export default {
  props: {
    event: {
      type: Object,
      required: true,
      default: () => null
    }
  },
  data() {
    return {
      showMore: false,
      availableFields,
      widgetStyleConfig
    }
  },
  computed: {
    locale() {
      return this.$store.state.locale
    },
    descriptionFields() {
      return this.availableFields.filter(e => this.event[e])
    }
  },
  methods: {
    getIconName(id) {
      return (
        this.widgetStyleConfig.events_log.icons[id] ||
        this.widgetStyleConfig.events_log.icons.default
      )
    },
    getColor(id) {
      return (
        this.widgetStyleConfig.events_log.colors[id] ||
        this.widgetStyleConfig.events_log.colors.default
      )
    },
    getDateTime(dateTime) {
      return this.$ritmDate.toFormat(dateTime, 'DD MMMM, h:mm')
    }
  }
}
</script>

<style lang="scss">
.sc-events-log__list-item {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-bottom: 12px;
  &__block {
    display: flex;
    width: calc(100% - 8px);
    position: relative;
    align-items: center;
    padding-right: 8px;
    &__icon {
      margin-right: 12px;
    }
    &__info {
      max-width: calc(100% - 126px);
      .r-text.caption-2 {
        margin-bottom: 4px !important;
        &:last-child {
          margin-bottom: 0 !important;
        }
      }
      > div {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    &__control {
      margin-left: auto;
      display: flex;
      align-items: center;
    }
    &__description-list {
      margin-top: 8px;
      margin-left: 48px;
      padding: 8px;
      background-color: var(--accent_hover);
      border-radius: var(--border-radius);
      width: calc(100% - 76px);
      &-item {
        overflow: hidden;
        display: flex;
        align-items: center;
        margin-bottom: 2px;
        > div {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
