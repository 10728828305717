<template>
  <r-block
    v-loading="loading"
    no-padding
  >
    <r-block
      start
      col
    >
      <r-button
        type="success"
        :disabled="disabled"
        @click="create"
      >
        Создать
      </r-button>
      <r-button
        simple
        @click="close"
      >
        Отменить
      </r-button>
    </r-block>
    <r-block>
      <r-text>
        {{ moduleTypes[activeModule].title }}
      </r-text>
      <el-select
        v-model="activeVehicle"
        class="r-select"
        placeholder="Выбрать"
        size="mini"
        filterable
        clearable
      >
        <el-option
          v-for="vehicle in vehicles"
          :key="vehicle.id"
          :label="vehicle.name"
          :value="vehicle.id"
        />
      </el-select>
      <r-block
        no-padding
        start
      >
        <r-button
          simple
          type="primary"
          icon="add-plus"
          @click="openModal('vehicle')"
        >
          {{ moduleTypes[activeModule].btnTitle }}
        </r-button>
      </r-block>
    </r-block>
    <r-block>
      <r-text color-type="secondary">
        Выберите грузы
      </r-text>
      <el-select
        v-model="activeCargo"
        class="r-select"
        placeholder="Выберите груз"
        filterable
        multiple
      >
        <el-option
          v-for="cargo in cargoes"
          :key="cargo.id"
          :label="`№ упак: ${cargo.packing_list_no || '—'} | ${cargo.name}`"
          :value="cargo.id"
        />
      </el-select>
      <r-block
        no-padding
        start
      >
        <r-button
          simple
          type="primary"
          icon="add-plus"
          @click="openModal('cargo')"
        >
          {{ 'Создать новый груз' }}
        </r-button>
      </r-block>
    </r-block>
    <el-dialog
      :title="modalTitle"
      :visible.sync="showModal"
      append-to-body
      width="640px"
      top="50px"
    >
      <component
        :is="modalComp"
        v-if="modalComp"
        @update-list="updateList"
        @close="showModal = false"
      />
    </el-dialog>
  </r-block>
</template>

<script>
import { notifyFactory } from '@/utils'
import { requestConfig } from './configs'

const moduleTypes = {
  vehicles: {
    title: 'ТС',
    btnTitle: 'Добавить новое ТС',
    modalComp: 'create-vehicle',
    firstStatusId: 'Перемещается с ТМЦ',
    statusAttrName: 'pot_status_id',
    cargoesAssigment: 'vehicle_id'
  },
  railway: {
    title: 'Вагон',
    btnTitle: 'Добавить новый вагон',
    modalComp: 'create-railway',
    firstStatusId: 'В пути',
    statusAttrName: 'status_pre_id',
    cargoesAssigment: 'last_dislocation_data_id'
  },
  barges: {
    title: 'Баржа',
    btnTitle: 'Добавить новую баржу',
    modalComp: 'create-barge',
    cargoesAssigment: 'barge_id'
  }
}

export default {
  components: {
    createVehicle: () => import('./pot-vehicle-create'),
    createRailway: () => import('./pot-railway-create'),
    createBarge: () => import('./pot-barge-create'),
    createCargo: () => import('./pot-cargo-create')
  },
  data() {
    return {
      loading: false,
      showModal: false,
      vehicles: null,
      activeVehicle: null,
      cargoes: null,
      activeCargo: [],
      modalItem: null,
      step: 0,
      moduleTypes
    }
  },
  computed: {
    activeModule() {
      return this.$store.state.pot1.activeModule
    },
    cargoesSouceId() {
      return this.$store.state.services.cargoes
    },
    firstStatusId() {
      const statusList = this.$store.state.pot1.statusList
      const statusName = this.moduleTypes[this.activeModule].firstStatusId

      return statusList.find(e => e.name === statusName)?.id || ''
    },
    vehicleListId() {
      return this.$store.state.services[this.activeModule] || ''
    },
    related() {
      return this.$store.state.pot1.related
    },
    disabled() {
      return !this.activeVehicle || !this.activeCargo?.length
    },
    modalActive() {
      return this.activeVehicle || this.activeCargo?.length
    },
    modalComp() {
      switch (this.modalItem) {
        case 'vehicle':
          return this.moduleTypes[this.activeModule].modalComp
        case 'cargo':
          return 'create-cargo'
        default:
          return null
      }
    },
    modalTitle() {
      return this.moduleTypes[this.activeModule]?.btnTitle || '-'
    }
  },
  watch: {
    modalActive(val) {
      this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', val)
    }
  },
  created() {
    this.loadVehicles()
    this.loadCargoes()
  },
  methods: {
    next() {
      if (this.step === 1) return

      this.step++
    },
    close() {
      const title = this.$t('index:' + 'before:title')
      const message = this.$t('index:' + 'before-cancel:text')
      const confirmButtonText = this.$t('index:' + 'button-confirm')
      const cancelButtonText = this.$t('index:' + 'button-cancel')

      if (this.modalActive) {
        this.$confirm(message, title, {
          customClass: 'r-message-box',
          closeOnPressEscape: true,
          closeOnClickModal: false,
          type: 'warning',
          confirmButtonText,
          cancelButtonText
        })
          .then(() => {
            this.$store.commit('CLOSE_MODAL_WINDOW', '')
          })
          .catch(() => {
          })
      } else {
        this.$store.commit('CLOSE_MODAL_WINDOW', '')
      }
    },
    async loadVehicles() {
      const source_id = this.$store.state.services[this.activeModule] || ''

      if (!source_id) return

      try {
        this.loading = true

        const config = requestConfig[this.activeModule] || ''
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `objectInfo/${source_id}?config=${JSON.stringify(config)}`
        })

        this.vehicles = Object.values(data)?.map(({ id, reg_number, carriage_no, name }) => {
          return {
            id,
            name: reg_number || carriage_no || name || '-'
          }
        })
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    async loadCargoes() {
      try {
        this.loading = true

        const cargoConfig = {
          only: ['id', 'packing_list_no', 'name'],
          where: [
            { field: 'receiving_date_fact', op: '=', value: 'null' }
          ]
        }
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `objectInfo/${this.cargoesSouceId}?array=true&config=${JSON.stringify(cargoConfig)}`
        })

        this.cargoes = Object.entries(data).reduce((a, c) => {
          const { id, packing_list_no, name } = c[1]
          a.push({ id, packing_list_no, name })
          return a
        }, [])
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    },
    updateList(type) {
      switch (type) {
        case 'cargo':
          this.loadCargoes()
          break
        case 'vehicle':
          this.loadVehicles()
          break
      }
    },
    openModal(item) {
      this.modalItem = item
      this.showModal = true
    },
    async create() {
      if (!this.activeVehicle || !this.vehicleListId) return

      try {
        this.loading = true

        const data = {
          id: this.activeVehicle,
          source: 'dispatcher'
        }
        const { statusAttrName, cargoesAssigment } = this.moduleTypes[this.activeModule]

        if (statusAttrName) {
          data[statusAttrName] = this.firstStatusId
        }

        await this.$store.dispatch('PUT_REQUEST', {
          url: `objectInfo/${this.vehicleListId}`,
          data
        })

        const cargoData = this.activeCargo?.map(e => {
          return {
            id: e,
            cargoes_assigment: [
              { [cargoesAssigment]: this.activeVehicle }
            ]
          }
        })

        await this.$store.dispatch('PUT_REQUEST', {
          url: `objectInfo/${this.cargoesSouceId}`,
          data: cargoData
        })

        const title = this.$t('index:' + 'edit-notify:title')
        const message = this.$t('index:' + 'edit-notify:text')

        this.$notify(notifyFactory('success', title, message))

        this.$store.commit('SET_POT_FIELD', {
          field: 'modalName',
          value: 'potMainModal'
        })
        this.$store.commit('SET_POT_FIELD', {
          field: 'cardId',
          value: this.activeVehicle
        })
        this.$store.commit('OPEN_MODAL_WINDOW', 'pot-modal-card')
        this.$store.commit('SET_POT_FIELD', {
          field: 'updateData',
          value: true
        })
      } catch (e) {
        throw new Error(e)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
