<template>
  <ul class="sc-object-card__list">
    <li
      v-for="(field, i) in fields"
      :key="field + i"
      class="sc-object-card__list-item"
    >
      <list-item-string :field="field" />
    </li>
  </ul>
</template>

<script>
import listItemString from './renderer/list-item-string'

export default {
  components: { listItemString },
  props: {
    fields: {
      type: Array,
      required: true,
      default: () => null
    }
  }
}
</script>

<style lang="scss">
.sc-object-card__list {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  overflow-y: auto;
  &-item {
    width: 100%;
    margin-bottom: 12px;
    &:last-child {
      margin-bottom: 0;
    }
  }
}
</style>
