<template>
  <div
    v-loading="isLoading"
    class="charts"
  >
    <div
      v-if="!noControl"
      class="charts__controllers"
    >
      <el-dropdown
        class="r-dropdown"
        :hide-on-click="false"
        placement="bottom-start"
        trigger="click"
        @command="changeChartType"
      >
        <span class="el-dropdown-link">
          <r-icon
            name="chart-bar"
            :size="24"
          />
          <r-icon
            name="drop-down"
            :size="16"
          />
        </span>
        <el-dropdown-menu
          slot="dropdown"
          class="r-dropdown-menu charts__dropdown"
        >
          <el-dropdown-item
            v-for="item in chartsTypes"
            :key="item"
            :command="item"
          >
            <span
              v-if="item === chartType"
              class="el-icon-check"
            />
            <r-text>{{ $t('charts:' + `chart-type:${item}`) }}</r-text>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <r-text>{{ $t('charts:' + 'chart') }}</r-text>
    </div>
    <v-chart
      v-if="chartOption && !isLoading"
      :ref="chartId"
      :option="chartOption"
      :autoresize="true"
      class="charts-builder"
      @datazoom="onDatazoom"
      @click="clickHandler"
    />
  </div>
</template>

<script>
import 'echarts'
import VChart from 'vue-echarts'

import throttle from 'lodash.throttle'

import { basic, bar, barX, pie } from './configs'
import {
  setChartStyle,
  setChartOptions,
  getDatazoomPercentByIndex,
  getDataZoomRangeByPercent,
  getDatazoomPercentByTime,
  getDatazoomValueByPercent
} from './helpers'

const chartsTypes = ['type-1', 'type-2', 'type-3', 'type-4', 'type-5']

export default {
  components: { VChart },
  props: {
    chartId: {
      type: String,
      default: null
    },
    data: {
      type: Object,
      default: () => null
    },
    noControl: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: null
    },
    dataZoom: {
      type: Boolean,
      default: false
    },
    dataZoomRange: {
      type: Object,
      default: () => null
    },
    timeAxis: {
      type: Boolean,
      default: false
    },
    colors: {
      type: Array,
      default: () => null
    },
    types: {
      type: Array,
      default: () => null
    }
  },
  data() {
    return {
      isLoading: false,
      chartsTypes: this.types || chartsTypes,
      chartType: this.type,
      chartOption: null,
      initialHighlightIndex: null,

      basic,
      bar,
      barX,
      pie
    }
  },
  computed: {
    theme() {
      return this.$store.state.theme
    },
    themeColors() {
      return this.$store.state.colors
    },
    chartState() {
      if (!this.chartId) return null
      return this.$store.state.charts.list[this.chartId] || null
    },
    chartHighlightIndex() {
      if (!this.chartState) return null
      return this.chartState.highlightIndex || null
    },
    chartHighlightByTime() {
      if (!this.chartState) return null
      return this.chartState.highlightByTime || null
    }
  },
  watch: {
    theme() {
      this.setChart()
    },
    chartType() {
      this.setChart()
    },
    data() {
      this.setChart()
      this.saveDataZoom()
    },
    chartHighlightIndex(val) {
      this.beforeHighlightByIndex(val)
    },
    chartHighlightByTime(val) {
      this.beforeHighlightByTime(val)
    }
  },
  created() {
    this.chartInit()
  },
  mounted() {
    this.chartRef = this.$refs[this.chartId]
    this.saveDataZoom()
  },
  methods: {
    clickHandler(value) {
      const { seriesName, dataIndex, data } = value

      if (dataIndex) {
        this.$store.commit('CHARTS_SET_FIELD_BY_ID', {
          id: this.chartId,
          field: 'clickHandler',
          value: {
            title: seriesName,
            index: dataIndex,
            data
          }
        })
      }
    },
    saveDataZoom() {
      if (this.chartId) {
        if (this.timeAxis) {
          this.$store.commit('CHARTS_SET_FIELD_BY_ID', {
            id: this.chartId,
            field: 'datazoom',
            value: getDatazoomValueByPercent(this.data.data[0].data, { start: 0, end: 100 })
          })
        } else {
          this.$store.commit('CHARTS_SET_FIELD_BY_ID', {
            id: this.chartId,
            field: 'datazoom',
            value: { start: 0, end: 100 }
          })
        }
      }
    },
    chartInit() {
      if (this.chartId) {
        this.$store.commit('CHARTS_INIT_CHART', this.chartId)
      }
      this.chartType = this.type || this.chartsTypes?.[0]
      this.setChart()
    },
    setChart() {
      setChartOptions(this)
      this.setColorTheme()
    },
    setColorTheme() {
      setChartStyle(this, this.themeColors)
    },
    changeChartType(val) {
      this.chartOption = null
      this.isLoading = true
      this.chartType = val

      setTimeout(() => {
        this.setChart()
        this.isLoading = false
      }, 200)
    },
    beforeHighlightByTime(value) {
      const { dataIndex, series, datetime } = value
      const dataZoomPercent = getDatazoomPercentByTime(this, datetime)

      this.checkDatazoomBeforeHighlight(dataZoomPercent)
      this.highlightByIndex(dataIndex, series)
    },
    beforeHighlightByIndex(value) {
      const { dataIndex, series } = value
      const dataZoomPercent = getDatazoomPercentByIndex(this, dataIndex)

      this.checkDatazoomBeforeHighlight(dataZoomPercent)
      this.highlightByIndex(dataIndex, series)
    },
    checkDatazoomBeforeHighlight(dataZoomPercent) {
      if (dataZoomPercent < this.chartState.datazoom.start ||
        dataZoomPercent > this.chartState.datazoom.end
      ) {
        const dataZoomRange = getDataZoomRangeByPercent(dataZoomPercent)
        this.setDataZoomRange(dataZoomRange)
      }
    },
    highlightByIndex(dataIndex, series = 0) {
      setTimeout(() => {
        if (this.initialHighlightIndex !== null) {
          this.chartRef.dispatchAction({
            type: 'downplay',
            seriesIndex: this.initialHighlightSeries,
            dataIndex: this.initialHighlightIndex
          })
        }
        this.chartRef.dispatchAction({
          type: 'highlight',
          seriesIndex: series,
          dataIndex
        })
        this.chartRef.dispatchAction({
          type: 'showTip',
          seriesIndex: series,
          dataIndex
        })
        this.initialHighlightIndex = dataIndex
        this.initialHighlightSeries = series
      }, 100)
    },
    setDataZoomRange(range) {
      const start = range.start || 0
      const end = range.end || (start + 30) <= 100 ? start + 30 : 100
      this.chartRef.dispatchAction({
        type: 'dataZoom',
        start: start,
        end: end
      })
      if (this.chartId) {
        if (this.timeAxis) {
          this.$store.commit('CHARTS_SET_FIELD_BY_ID', {
            id: this.chartId,
            field: 'datazoom',
            value: getDatazoomValueByPercent(this.data.data[0].data, { start, end })
          })
        } else {
          this.$store.commit('CHARTS_SET_FIELD_BY_ID', {
            id: this.chartId,
            field: 'datazoom',
            value: {
              start,
              end
            }
          })
        }
      }
    },
    onDatazoom: throttle(function(e) {
      if (!this.chartId) return
      if (this.timeAxis) {
        this.$store.commit('CHARTS_SET_FIELD_BY_ID', {
          id: this.chartId,
          field: 'datazoom',
          value: getDatazoomValueByPercent(this.data.data[0].data, e)
        })
      } else {
        this.$store.commit('CHARTS_SET_FIELD_BY_ID', {
          id: this.chartId,
          field: 'datazoom',
          value: { start: e.start, end: e.end }
        })
      }
    }, 300)
  }
}
</script>

<style lang="scss">
.charts {
  background-color: var(--bg_panel_primary);
  &__controllers {
    display: flex;
    align-items: center;
    background-color: var(--bg_containers);
    padding: 4px 8px;
    width: 100%;
    .el-dropdown-link {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    .r-text {
      margin: 8px;
    }
  }
  .echarts {
    height: 100%;
    width: calc(100%);
    top: 0;
    left: 0;
  }
}

.charts__dropdown {
  .el-dropdown-menu__item {
    display: flex;
    padding: 4px 16px !important;

    .r-text {
      margin-left: 8px !important;
    }
  }
}
</style>
