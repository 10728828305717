<template>
  <sc-card
    v-if="layer_id && id && data"
    :title="$t('object-card:' + layer_id)"
    :title-icon="titleIcon"
    bg-color="bg_panel_primary"
    footer
  >
    <div class="sc-object-card">
      <el-collapse
        v-model="activeTabs"
        class="r-collapse style-collapse"
      >
        <el-collapse-item
          v-if="cardData && cardData.main"
          name="main"
        >
          <template slot="title">
            <r-title type="subtitle-2">
              {{ $t('object-card:' + `main`) }}
            </r-title>
          </template>
          <sc-object-card-list :fields="cardData.main" />
        </el-collapse-item>

        <el-collapse-item
          v-if="cardData && cardData.camerasList"
          name="cameras"
        >
          <template slot="title">
            <r-title type="subtitle-2">
              {{ $t('object-card:' + `cameras`) }}
            </r-title>
          </template>
          <sc-object-card-list-cameras
            :fields="JSON.parse(cardData.camerasList)"
          />
        </el-collapse-item>

        <el-collapse-item
          v-if="cardData && cardData.macroCam"
          name="macrocam"
        >
          <template slot="title">
            <r-title type="subtitle-2">
              {{ $t('object-card:' + `camera`) }}
            </r-title>
          </template>
          <sc-object-card-macro-cam :id="cardData.macroCam" />
        </el-collapse-item>

        <el-collapse-item
          v-if="cardData && cardData.camera_id"
          name="camera"
        >
          <template slot="title">
            <r-title type="subtitle-2">
              {{ $t('object-card:' + `camera`) }}
            </r-title>
          </template>
          <sc-object-card-camera :id="cardData.camera_id" />
        </el-collapse-item>

        <el-collapse-item
          v-if="layer_id === 'vehicles'"
          name="tracks"
        >
          <template slot="title">
            <r-title type="subtitle-2">
              {{ $t('object-card:' + `tracks`) }}
            </r-title>
          </template>
          <sc-object-card-tracks-controller
            :id="id"
          />
        </el-collapse-item>

        <el-collapse-item
          v-if="rtsp && cardData.id"
          name="rtsp"
        >
          <template slot="title">
            <r-title type="subtitle-2">
              {{ $t('object-card:' + `rtsp`) }}
            </r-title>
          </template>
          <sc-object-card-rtsp
            :id="String(cardData.id)"
            :rtsp="rtsp"
            :card-data="cardData"
            :is-loading="isLoading"
          />
        </el-collapse-item>

        <el-collapse-item
          v-if="videoArchive && cardData.id"
          name="video-archive"
        >
          <template slot="title">
            <r-title type="subtitle-2">
              {{ $t('object-card:' + `video-archive`) }}
            </r-title>
          </template>
          <sc-object-card-video-archive
            :id="String(cardData.id)"
            :video-archive="videoArchive"
            :is-loading="isLoading"
          />
        </el-collapse-item>

        <el-collapse-item
          v-for="(item, i) in meteoDataList"
          :key="item.title + i"
          :name="item.title"
        >
          <template slot="title">
            <r-title type="subtitle-2">
              {{ $t('object-card:' + `${item.title}`) }}
            </r-title>
          </template>
          <sc-object-card-list :fields="item.list" />
        </el-collapse-item>
      </el-collapse>
    </div>
    <div
      class="sc-object-card__close-control"
      @click="closeObjectCard"
    >
      <r-icon
        name="close-delete"
        :size="24"
      />
    </div>
    <sc-object-card-actions
      slot="footer"
      :info="objectCard"
    />
  </sc-card>
</template>

<script>
import scCard from '../../layout/card'
import scObjectCardActions from './components/object-card-actions'
import scObjectCardList from './components/object-card-list'
import scObjectCardListCameras from './components/object-card-list-cameras'
import scObjectCardMacroCam from './components/object-card-macro-cam'
import scObjectCardCamera from './components/object-card-camera'
import scObjectCardTracksController from './components/object-card-track-controller'
import scObjectCardRtsp from './components/object-card-rtsp'
import scObjectCardVideoArchive from './components/object-card-video-archive'
import { dataMapping, getWidgetStyleConfig } from '../../../configs'

export default {
  components: {
    scCard,
    scObjectCardActions,
    scObjectCardList,
    scObjectCardListCameras,
    scObjectCardMacroCam,
    scObjectCardTracksController,
    scObjectCardRtsp,
    scObjectCardVideoArchive,
    scObjectCardCamera
  },
  props: {
    objectCard: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      isLoading: false,
      refreshData: null,
      optionalData: null,
      activeTabs: [
        'main',
        'cameras',
        'camera',
        'tracks',
        'camera',
        'road',
        'air',
        'macrocam',
        'rtsp'
      ]
    }
  },
  computed: {
    mapInstanceConfig() {
      return this.$store.state.situationCenter.config
        ? this.$store.state.situationCenter.config.mapLayers
        : []
    },
    trackLayer() {
      return this.$store.state.situationCenter.cardTrack || null
    },
    isEventLayer() {
      return this.mapInstanceConfig
        ? !!this.mapInstanceConfig.find(
          e => e.layer_id === this.layer_id && e.event_layer
        )
        : false
    },
    optionalRequest() {
      return this.mapInstanceConfig
        ? this.mapInstanceConfig.find(
          e => e.layer_id === this.layer_id && e.optionalRequest
        )?.optionalRequest
        : null
    },
    rtsp() {
      return this.mapInstanceConfig
        ? this.mapInstanceConfig.find(
          e => e.layer_id === this.layer_id && e.rtsp
        )?.rtsp
        : null
    },
    videoArchive() {
      return this.mapInstanceConfig
        ? this.mapInstanceConfig.find(
          e => e.layer_id === this.layer_id && e.videoArchive
        )?.videoArchive
        : null
    },
    layer_id() {
      return this.objectCard.layer_id
    },
    id() {
      return this.objectCard.id
    },
    objectId() {
      return this.id.split('id:')[1]
    },
    data() {
      return this.objectCard.data ? this.objectCard.data.properties : null
    },
    titleColor() {
      let type = ''

      switch (this.layer_id) {
        default:
          type = 'default'
          break
      }
      const style = getWidgetStyleConfig(this.layer_id)

      return style?.colors[type] || null
    },
    titleIcon() {
      const style = getWidgetStyleConfig(this.layer_id)

      return {
        name: style?.icons?.default || null,
        color: this.titleColor
      }
    },
    cardData() {
      const data = this.data
        ? dataMapping(
          this.data,
          this.isEventLayer ? 'events' : this.layer_id,
          'object_card'
        )
        : {}
      return data
    },
    meteoDataList() {
      if (!this.cardData || !this.cardData.meteoData) return []
      const meteoData = JSON.parse(this.cardData.meteoData)
      const roadDataObject = meteoData.road[0]
      const roadDataKeys = Object.keys(roadDataObject)
      const roadDataList = roadDataKeys
        .filter(e => e !== 'id' && e !== 'date')
        .map(e => {
          return {
            type: roadDataObject[e][1],
            value: `${roadDataObject[e][0]} ${roadDataObject[e][2]}`
          }
        })
      const airDataObject = meteoData.air[0]
      const airDataKeys = Object.keys(airDataObject)
      const airDataList = airDataKeys
        .filter(e => e !== 'id' && e !== 'date')
        .map(e => {
          return {
            type: airDataObject[e][1],
            value: `${airDataObject[e][0]} ${airDataObject[e][2]}`
          }
        })

      return [
        { title: 'road', list: roadDataList },
        { title: 'air', list: airDataList }
      ]
    }
  },
  watch: {
    id: {
      handler: function(val) {
        this.clearTrack()
        if (this.refreshData) { clearInterval(this.refreshData) }
        if (!val) clearInterval(this.refreshData)
        if (this.optionalRequest) {
          this.getOptionalRequest(this.optionalRequest)
        } else {
          this.optionalData = null
        }
      },
      immediate: true,
      deep: true
    }
  },
  beforeDestroy() {
    clearInterval(this.refreshData)
  },
  methods: {
    closeObjectCard() {
      this.$store.commit('SI_CENTER_TOGGLE_ITEM', ['objectCards', { id: this.id }])

      this.clearTrack()
    },
    clearTrack() {
      this.$store.commit('SET_SI_CENTER_TRACK_LAYER', {
        layer_id: null,
        data: null
      })
    },
    async getOptionalRequest({ url, filter }) {
      if (!url || !filter) return

      try {
        this.isLoading = true

        const { id } = this.cardData
        const { data } = await this.$store.dispatch('GET_REQUEST', {
          url: `${url}${filter}${id}`
        })

        this.optionalData = data

        setTimeout(() => {
          this.isLoading = false
        }, 500)
      } catch (e) {
        console.warn(e)
        setTimeout(() => {
          this.isLoading = false
        }, 500)
      }
    }
  }
}
</script>

<style lang="scss">
.sc-object-card {
  position: relative;
  max-height: calc(100vh - 500px);
  min-height: 320px;
  width: 240px;
  height: auto;
  overflow: hidden;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .el-collapse {
    &.r-collapse {
      height: 100%;
      overflow: auto;
      .el-collapse-item__header {
        padding: 8px;
      }
      .el-collapse-item__content {
        padding-bottom: 12px;
      }
    }
    &-item {
      border-radius: var(--border-radius);
      background-color: var(--bg_containers) !important;
      margin-bottom: 6px;
      &:last-child {
        margin-bottom: 0;
      }
      &__header {
        line-height: 1.5 !important;
      }
    }
  }
}
.sc-object-card__close-control {
  cursor: pointer;
  position: absolute;
  top: -32px;
  right: 8px;
  .r-icon__svg.primary {
    fill: var(--text_secondary) !important;
  }
}
</style>
