<template>
  <div class="pass-register-modal">
    <div class="pass-register-modal__controls">
      <r-button
        type="success"
        class="pass-register-modal__save-button"
        :disabled="!hasChanges"
        @click="save"
      >
        {{ $t('modal-edit:' + 'register_modal::save-button') }}
      </r-button>
      <r-button
        simple
        class="pass-register-modal__exit-button"
        @click="cancel"
      >
        {{ $t('modal-edit:' + 'register_modal::cancel-button') }}
      </r-button>
    </div>

    <div class="pass-register-modal__attribute-list">
      <div
        v-for="field in fields"
        :key="field.name"
        class="pass-register-modal__attribute"
      >
        <r-text color-type="secondary">
          {{ field.name }}
        </r-text>
        <el-input
          v-if="field.type === 'text' || field.type === 'number'"
          v-model="pass[field.model]"
          :type="field.type"
          clearable
          @change="setChanges"
        />
        <el-select
          v-else-if="field.type === 'select' && !field.parent"
          v-model="pass[`${field.model}_id`]"
          filterable
          class="r-select"
          size="mini"
          @change="setChanges"
        >
          <el-option
            v-for="item in pass.related[field.model].data"
            :key="item.id"
            :value="item.id"
            :label="getCustomLabel(field, item)"
          />
        </el-select>
        <el-switch
          v-else-if="field.type === 'boolean'"
          v-model="pass[field.model]"
          class="r-switch"
          @change="setChanges"
        />
        <r-date-picker
          v-else-if="field.type === 'datetime'"
          v-model="pass[field.model]"
          type="datetime"
          class="r-input"
          placeholder="Выберите дату"
          default-time="12:00:00"
          format="dd.MM.yyyy HH:mm"
          :picker-options="{ firstDayOfWeek: 1 }"
          @change="setChanges"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { notifyFactory } from '@/utils'
import { fields, sources } from './config/fields'
import cloneDeep from 'lodash.clonedeep'

export default {
  props: {
    source_id: {
      type: String,
      required: true
    },
    source: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      hasChanges: false,
      fields: fields.filter(f => !f.read_only),
      clone: {},
      pass: {},
      sources
    }
  },
  watch: {
    hasChanges(val) {
      this.$store.commit('MODAL_WINDOW_ACTIVE_TOGGLER', val)
    }
  },
  created() {
    this.pass = cloneDeep(this.source)
  },
  methods: {
    setChanges() {
      this.hasChanges = true
    },
    getCustomLabel(field, item) {
      return field.customField
        ? item[field.customField]
          ? item[field.customField][field.displayedField]
          : '—'
        : item.name
    },
    cancel() {
      this.$emit('mode', 'view')
    },
    async save() {
      const { pass, source_id } = this

      const url = `objectInfo/${source_id}`

      const data = {
        application_id: pass.application_id,
        is_valid: pass.is_valid,
        withdrawal: pass.withdrawal,
        number: pass.number,
        date_issue: pass.date_issue,
        date_from: pass.date_from,
        date_to: pass.date_to,
        permit_type_id: pass.permit_type_id,
        subproject_id: pass.subproject_id,
        status: pass.status,
        id: pass.id
      }

      try {
        await this.$store.dispatch('POST_REQUEST', { url, data })

        const message = this.$t('modal-edit:' + 'register_modal::save_text')
        this.$notify(notifyFactory('succcess', message))
      } catch (e) {
        console.warn(e)
      } finally {
        this.$store.commit('BDD_SET_FIELD', {
          field: this.sources.updateField,
          value: true
        })
        this.$store.commit('CLOSE_MODAL_WINDOW')
      }
    }
  }
}
</script>
