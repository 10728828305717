<template>
  <div class="sc-object-card__list-item__wrapper">
    <r-text type="caption">
      {{ $t('list-item-string:' + field.type) }}
    </r-text>
    <r-text v-if="field.type === 'phase' || field.type === 'closed_line_count'">
      {{ field.value !== 'null' ? field.value : '-' }}
    </r-text>
    <r-text
      v-else-if="field.type === 'start_time' || field.type === 'end_time'"
    >
      {{ getDateTime(field.value) }}
    </r-text>
    <r-text v-else>
      {{ $t('list-item-string:' + `${field.value}`) }}
    </r-text>
  </div>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      required: true,
      default: () => null
    }
  },
  methods: {
    getDateTime(val) {
      if (!val || val === 'null') return '-'

      return this.$ritmDate.toFormat(val, 'DD.MM.YYYY • HH:mm')
    }
  }
}
</script>

<style lang="scss">
.sc-object-card__list-item__wrapper {
  position: relative;
}
</style>
