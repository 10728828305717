<template>
  <div class="r-toggle-card">
    <div class="r-toggle-card__title-block">
      <r-title type="subtitle-2">
        {{ title || $t('r-toggle-card:' + 'title') }}
      </r-title>
      <div class="r-toggle-card__title-block__toggler">
        <el-switch
          v-model="value"
          :width="32"
          class="r-switch dark"
          @change="toggleHandler"
        />
      </div>
    </div>
    <transition
      name="fadeHeight"
      mode="out-in"
    >
      <div
        v-if="isOpen"
        class="r-toggle-card__content"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: null
    },
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      value: this.isOpen
    }
  },
  methods: {
    toggleHandler() {
      this.$emit('toggle', this.value)
    }
  }
}
</script>

<style lang="scss">
.r-toggle-card {
  position: relative;
  padding: 8px;
  background-color: var(--bg_containers) !important;
  border-radius: var(--border-radius);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: auto;

  &:last-child {
    margin-bottom: 0;
  }

  &__title-block {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    overflow: hidden;
    position: relative;
    cursor: pointer;

    .r-text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 24px);
      display: block !important;
    }
  }

  &__content {
    width: 100%;
    margin-top: 8px;
  }
}
</style>
