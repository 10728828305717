<template>
  <div
    :style="`display: ${settings.display};top: ${settings.top}px; left: ${settings.left}px`"
    class="map-popup-list"
  >
    <div class="map-popup-list__title">
      <r-text style="display: inline">
        {{ $t('map-popup-list:' + 'title') }}
      </r-text>
      <r-text type="caption">
        {{ `(${$t('map-popup-list:' + 'amount')} ${list.length})` }}
      </r-text>
    </div>
    <ul class="map-popup-list__list">
      <li
        v-for="item in list"
        :key="item.properties.id"
        :class="[
          'map-popup-list__item',
          { active: isActive(item.properties.id) }
        ]"
        @click="e => clickHandler(e, item)"
      >
        <div class="map-popup-list__layer-name">
          <r-text type="caption">
            {{ getLayerName(item.layer.id) }}
          </r-text>
        </div>
        <div class="map-popup-list__item-id">
          <r-text>
            {{ getLayerInfo(item) }}
          </r-text>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { findLayerNameById } from './helpers/'
import { openObjectInfo, changeObjectInfo } from '@/utils'

export default {
  props: {
    settings: {
      type: Object,
      default: () => null
    },
    styleConfig: {
      type: Object,
      default: () => null
    }
  },
  computed: {
    list() {
      const list = this.settings.values
      const idList = list.map(e => e.properties.id)
      const uniqueIdList = [...new Set(idList)]

      if (!uniqueIdList || !uniqueIdList.length) return []

      return list.filter(e => {
        if (uniqueIdList.indexOf(e.properties.id) > -1) {
          uniqueIdList.splice(uniqueIdList.indexOf(e.properties.id), 1)
          return true
        } else {
          return false
        }
      })
    },
    dsList() {
      return this.$store.state.profiles.currentProfileTree
    },
    activeCard() {
      return this.$store.state.commonData.activeCard.map
    }
  },
  methods: {
    clickHandler(e, item) {
      const { properties, layer } = item
      const layerId = layer.id.includes('_') ? layer.id.split('_')[0] : layer.id
      const source_id = this.$store.state.profiles.sourceIdById[layerId]

      if (e.ctrlKey || e.metaKey) {
        openObjectInfo(this, source_id, properties.id, 'map', item)
      } else changeObjectInfo(this, source_id, properties.id, 'map', item)
    },
    getLayerName(id) {
      return findLayerNameById(this.dsList, id)
    },
    getLayerInfo(item) {
      const config = this.styleConfig?.[item.source]?.popupClick

      if (!config?.fields?.length) {
        return item.properties.id
      } else {
        return config.fields?.map(f => {
          return f + ': ' + (
            !item?.properties?.[f] && item?.properties?.[f] !== 0
              ? '-'
              : item?.properties?.[f]
          )
        })?.join(', ')
      }
    },
    isActive(id) {
      return this.activeCard === id
    }
  }
}
</script>

<style lang="scss">
.map-popup-list {
  position: absolute;
  border-radius: var(--border-radius);
  border: 1px solid transparent;
  background-color: var(--bg_surface) !important;
  border-color: var(--dividers_low_contrast) !important;
  min-width: 120px;
  padding: 8px;
  font-size: 12px;
  z-index: 10;
  &__title {
    margin-bottom: 8px;
  }
  &__list {
    max-height: 40vh;
    overflow: auto;
    max-width: 300px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 8px;
    padding: 4px 8px;
    border-bottom: 1px solid;
    border-radius: var(--border-radius);
    border-color: var(--dividers_low_contrast) !important;
    cursor: pointer;
    &:last-child {
      border-bottom: none;
    }
    &.active {
      background-color: var(--accent_hover) !important;
      * {
        color: var(--accent_primary) !important;
      }
    }
    &:hover {
      background-color: var(--accent_hover) !important;
    }
    &:active {
      background-color: var(--accent_active)!important;
    }
  }
  &__key {
    margin-right: 16px;
  }
  &__value {
    font-weight: bold;
  }
}
</style>
