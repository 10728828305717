<template>
  <div
    v-if="filteredRoutesLinks.length"
    class="navbar-router-group"
  >
    <el-dropdown
      v-if="filteredRoutesLinks.length > 0"
      :class="[`r-dropdown`, { active }]"
      size="mini"
      :show-timeout="100"
      placement="bottom-start"
      trigger="click"
    >
      <navbar-dropdown-title
        :icon="mainIcon"
        :title="$t('navbar-router-group:' + getName($route.path))"
      />
      <el-dropdown-menu
        slot="dropdown"
        class="r-dropdown-menu navbar-dropdown"
      >
        <r-title type="subtitle-2">
          {{ $t('navbar-router-group:' + routesGroup.value) }}
        </r-title>
        <el-dropdown-item
          v-for="link in filteredRoutesLinks"
          :key="link.id"
          :class="{ active: isActive(link) }"
        >
          <a
            v-if="moduleUrl(link.name)"
            :href="moduleUrl(link.name)"
            target="_blank"
            rel="noopener"
            @click="beforeSetDefaultPath(link)"
          >
            <div class="r-dropdown-menu__item-wrapper">
              <navbar-dropdown-item
                :icon="link.icon"
                :title="$t('navbar-router-group:' + link.value)"
              />
            </div>
          </a>
          <router-link
            v-else-if="link.to"
            :to="link.to"
            @click.native="beforeSetDefaultPath(link)"
          >
            <div class="r-dropdown-menu__item-wrapper">
              <navbar-dropdown-item
                :icon="link.icon"
                :title="$t('navbar-router-group:' + link.value)"
              />
            </div>
          </router-link>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>

<script>
import navbarDropdownTitle from '../../renderer/navbar-dropdown-title'
import navbarDropdownItem from '../../renderer/navbar-dropdown-item'

export default {
  components: {
    navbarDropdownTitle,
    navbarDropdownItem
  },
  props: {
    routesGroup: {
      type: Object,
      required: true
    }
  },
  computed: {
    mainIcon() {
      return this.active ? this.active.icon : this.routesGroup.icon
    },
    filteredRoutesLinks() {
      const exception = ['Acts register']

      return this.routesGroup.routesLinks.filter(e => {
        if (exception.includes(e.name)) return true

        return this.hasModule(e.name)
      })
    },
    active() {
      return this.filteredRoutesLinks.find(e => this.isActive(e))
    },
    beforeClose() {
      return this.$store.state.navbar.beforeClose || null
    }
  },
  methods: {
    hasModule(name) {
      return this.$store.getters.hasModule(name)
    },
    moduleUrl(name) {
      return this.$store.getters.module(name)?.source_info?.url
    },
    isActive(link) {
      return this.$route.path.indexOf(link.to) > -1
    },
    beforeSetDefaultPath(link) {
      if (this.beforeClose) {
        const title = 'Предупреждение'
        const message = 'Все изменения будут отменены. Продолжить?'
        const confirm = 'Подтвердить'
        const cancel = 'Отмена'
        this.$confirm(message, title, {
          customClass: 'r-message-box',
          type: 'warning',
          closeOnPressEscape: false,
          closeOnClickModal: false,
          confirm,
          cancel
        })
          .then(() => {
            this.setDefaultPath(link)
            this.$store.commit('NAVBAR_SET_FIELD', {
              field: 'beforeClose',
              value: null
            })
          })
          .catch(() => {})
      } else {
        this.setDefaultPath(link)
      }
    },
    setDefaultPath(link) {
      if (this.$route.path === link.to) return
      this.$store.commit('SET_DEFAULT_PATH', link.to)
      this.$store.dispatch('SAVE_MAIN_USER_CONFIG')
    },
    getName(path) {
      const findLink = this.filteredRoutesLinks.find(e => path.includes(e.to))

      return findLink?.value || ''
    }
  }
}
</script>

<style lang="scss">
.navbar-router-group {
  height: 100%;
  display: flex;
  align-items: center;

  .r-dropdown {
    height: 32px;
    display: flex;
    align-items: center;
    border-radius: var(--border-radius);
    overflow: hidden;
    transition: all 0.15s ease;

    &:hover {
      cursor: pointer;
      background-color: var(--accent_selected) !important;

      svg {
        fill: var(--accent_primary) !important;
      }
    }

    &.active {
      background-color: var(--accent_selected) !important;
      .r-icon {
        width: auto;
        svg {
          fill: var(--accent_primary) !important;
        }
      }

      .navbar-dropdown-title {
        padding: 6px;
        .r-text {
          padding: 0 6px;
          width: auto;
          opacity: 1;
          color: var(--accent_primary) !important;
          svg {
            color: var(--accent_primary) !important;
          }
        }
      }
    }
  }
}

.navbar-dropdown {
  top: 47px !important;

  .el-dropdown-menu__item {
    a {
      width: 100%;
    }

    &.active {
      background-color: var(--accent_selected) !important;
      a {
        cursor: initial !important;
      }
    }
  }

  .r-title {
    padding: 5px 8px 8px;

    &:before {
      content: none !important;
    }
  }
}
</style>
